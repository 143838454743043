import React, { useState, FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import Icon from "../../FormElements/Icons";
import Button from "../../FormElements/Button";

const Navigation: FunctionComponent = () => {
  const [isOpen, setOpen] = useState(false);
  const active = isOpen ? "active" : "";
  const [currentLang, setCurrentLang] = useState("PL");
  const [showLangs, setShowLangs] = useState(false);

  return (
    <nav className={`nav ${active}`}>
      <button
        aria-label="Otwórz lub zamknij menu"
        onClick={() => setOpen(!isOpen)}
        className={`hamburger ${active}`}
      >
        <Icon name={active ? "hide_menu" : "menu"} />
      </button>

      <div className={`navigation row ${active}`}>
        <ul className="ul">
          <li onClick={() => setOpen(false)} className="li">
            <NavLink to="/events">
              <Icon name="events" />
              <span>Wydarzenia</span>
            </NavLink>
          </li>
          <li onClick={() => setOpen(false)} className="li">
            <NavLink to="/favourite">
              <Icon name="fav_points" />
              <span>Zapisane punkty</span>
            </NavLink>
          </li>
          <li className="li navigation__language">
            <Button
              onClick={() => setShowLangs(!showLangs)}
              className={`button-transparent button-with-icon ${
                showLangs ? "active" : ""
              }`}
            >
              <>
                {currentLang}
                <Icon name="arrow" />
              </>
            </Button>

            {showLangs && (
              <ul>
                <li>
                  <Button
                    onClick={() => {
                      setCurrentLang("PL");
                      setShowLangs(false);
                    }}
                    className={`button-transparent ${
                      currentLang === "PL" ? "active" : ""
                    }`}
                  >
                    PL
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={() => {
                      setCurrentLang("EN");
                      setShowLangs(false);
                    }}
                    className={`button-transparent ${
                      currentLang === "EN" ? "active" : ""
                    }`}
                  >
                    EN
                  </Button>
                </li>
              </ul>
            )}
          </li>
          <li className="li li--ue">
            <Icon name="ue" />
          </li>

          {/* LOGIN/LOGOUT BUTTONS */}
          {/* {false ? (
            <li onClick={() => setOpen(false)} className="li li--with-border">
              <div className="current-user-wrapper">
                <div className="current-user row margin-0">
                  <Icon name="log_in" />
                  <div>
                    <div>Zalogowano</div>
                    <div>Joanna Kowalska</div>
                  </div>
                </div>

                <Button
                  className={`logout-btn col-24 ${active}`}
                  onClick={() => console.log("logout btn click")}
                >
                  <Icon name="log_out" />
                  <span>Wyloguj</span>
                </Button>
              </div>
            </li>
          ) : (
            <li onClick={() => setOpen(false)} className="li li--with-border">
              <NavLink to="/login">
                <Icon name="log_in" />
                <span>Zaloguj</span>
              </NavLink>
            </li>
          )} */}
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
