export const fetchEvents = () => {
  return {
    type: "FETCH_OCCASIONAL_EVENTS",
  };
};

export const getEvent = (id: string | number | null) => {
  return {
    type: "GET_OCCASIONAL_EVENTS",
    id,
  };
};
