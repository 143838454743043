import React, { FunctionComponent } from "react";
import Loader from "react-loader-spinner";
import { ICampus } from "../../../interfaces";
import ListItem from "./listItem";

const DataList: FunctionComponent<{
  data: any;
  loading: boolean;
  container: string;
  msg?: string;
  selectedCampus?: ICampus;
  removable?: boolean;
  link?: string;
}> = ({
  data,
  loading,
  container,
  msg,
  selectedCampus,
  removable = false,
  link,
}) => {
  return (
    <div className={`data-list ${container}`}>
      {loading && (
        <div className="loader">
          <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
        </div>
      )}

      {!loading && !selectedCampus && msg && (!data || data?.length < 1) && (
        <span className="box__no-result text-center">{msg}</span>
      )}

      {!loading && (
        <ul className="list row">
          {selectedCampus && (
            <>
              <span className="list__lablel list__lablel-top uppercase">
                Wybrany aktualnie
              </span>
              <ListItem data={{ ...selectedCampus, link, removable }} />{" "}
              {data?.length > 0 && (
                <span className="list__lablel uppercase">Pozostałe</span>
              )}
            </>
          )}

          {data?.map((row: any, index: number) => (
            <ListItem key={index} data={{ ...row, link, removable }} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default DataList;
