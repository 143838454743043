import { createContext, SetStateAction, Dispatch } from "react";

export interface IMapContext {
  mapInstance: L.Map | null;
  setMapInstance: Dispatch<SetStateAction<L.Map | null>>;
}

export const MapContext = createContext<IMapContext>({
  mapInstance: null,
  setMapInstance: (map) => {},
});
