import { createContext, SetStateAction, Dispatch } from "react";
export interface IStairs {
  start?: Array<number | undefined>;
  destination?: Array<number | undefined>;
}

export interface IStairsContext {
  stairsPoints: IStairs | null;
  setStairsPoints: Dispatch<SetStateAction<IStairs | null>>;
}

export const StairsContext = createContext<IStairsContext>({
  stairsPoints: {
    start: [],
    destination: [],
  },
  setStairsPoints: (point) => {},
});
