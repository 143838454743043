export const fetchMaps = () => {
  return {
    type: "FETCH_MAPS",
  };
};

export const getMap = (id: string | number | null) => {
  return {
    type: "GET_MAPS",
    id,
  };
};
