import React, { FunctionComponent, useCallback } from "react";
import { URL } from "../../../services/api";
import L from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { IPoint } from "../../../interfaces";
import { useHistory } from "react-router-dom";

interface IMarkersList {
  data: Array<IPoint> | null;
  selected?: IPoint;
  selectedColor?: String;
}

const MarkersList: FunctionComponent<IMarkersList> = ({
  data,
  selected,
  selectedColor,
}) => {
  const history = useHistory();
  const getCurrentIcon = useCallback(
    (point: IPoint) => {
      const img = `<img src="${URL}doc/${point.emblem}" alt="${point.name}"/>`;
      const color = selected?.id === point?.id ? selectedColor : point.color;
      const html = `  <span
          id="point-${point.id}"
          class="marker__icon round ${
            selected?.id === point?.id ? "marker__icon-selected" : ""
          }"
          style="background: ${color}; color: ${color}"
        >
          ${img}
        </span>`;

      if (html) {
        return L.divIcon({
          iconSize: [40, 40],
          iconAnchor: [0, 20],
          popupAnchor: [2, -40],
          html: html,
        });
      }
    },
    [selected, selectedColor]
  );

  return (
    <>
      {data?.map((point: IPoint, row: number) => {
        return (
          <div key={row}>
            {point?.emblem && point?.name && (
              <Marker
                key={row}
                draggable={false}
                position={{
                  lat: point.coordinates_x,
                  lng: point.coordinates_y,
                }}
                onClick={() =>
                  history.push(
                    `/point/${point.id}${
                      point?.floor_id ? `?floor=${point.floor_id}` : ""
                    }`
                  )
                }
                icon={getCurrentIcon(point)}
                onMouseOver={(e: any) => e.target.openPopup()}
                onMouseOut={(e: any) => e.target.closePopup()}
              >
                <Popup>{point.name}</Popup>
              </Marker>
            )}
          </div>
        );
      })}
    </>
  );
};

export default MarkersList;
