import React, { FunctionComponent, useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import {
  RootState,
  UserInterface,
  DefaultApiResult,
} from "../../redux/reducers";
import Navigation from "./Navigation";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/splash_login/logo_blue.png";
import { useLocation, useHistory } from "react-router-dom";
import { EventContext, IEventContext } from "../../contexts/EventContext";
import Popup from "../../components/Popup";

const Header: FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();

  const { selectedEvent, setSelectedEvent } = useContext<IEventContext>(
    EventContext
  );

  const campuses: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Campuses
  );

  const user: UserInterface = useSelector<RootState, UserInterface>(
    (state) => state.User
  );

  const campus = useMemo(
    () =>
      user?.selectedCampus &&
      campuses?.data &&
      campuses.byId[user?.selectedCampus],
    [user, campuses]
  );

  const onCloseEvent = () => {
    setSelectedEvent(null);
    if (location.pathname.split("/").includes("event")) {
      history.push("/events");
    }
  };

  return (
    <header
      data-html2canvas-ignore="true"
      className={location.pathname === "/login" ? `header hide` : `header`}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-20">
            <div className="header__left">
              <NavLink to="/" className="header__logo">
                <img className="img" src={logo} alt="Politechnika Śląska" />
              </NavLink>
              {selectedEvent && (
                <Popup
                  title="Wybrane wydarzenie:"
                  desc={selectedEvent?.name}
                  classList=" popup--top"
                  onHidePopup={() => onCloseEvent()}
                />
              )}
              <div className="header__toggle-wrapper">
                <h1>Wybrany kampus:</h1>
                <NavLink
                  to={location.pathname === "/campuses" ? "/" : "/campuses"}
                >
                  {campus?.data?.name || "wybierz kampus"}
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-4">
            <div className="header__right">
              <Navigation />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
