import { IEntrance } from "../../interfaces";

import { getCenter } from "../../services/positions";

const geoutil = require("leaflet-geometryutil");

interface IConvertedEntrance {
  data: Array<any>;
  loading: boolean;
}

export const INIT_STATE = {
  data: [],
  loading: false,
};

export default function reducer(
  state: IConvertedEntrance = INIT_STATE,
  action: { type: string; map: any; route: any; entrances: any }
): IConvertedEntrance {
  if (state === undefined) {
    return INIT_STATE;
  }
  switch (action.type) {
    case "CONVERT_ENTRANCES":
      return {
        ...state,
        data: action.entrances.map((entrance: IEntrance) => {
          return {
            ...entrance,
            closest: geoutil.closestLayer(
              action.map,
              action.route,
              getCenter(entrance.coordinates.coordinates.flat(2))
            ),
          };
        }),
      };
    default:
      return state;
  }
}
