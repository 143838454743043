export const fetchPoints = () => {
  return {
    type: "FETCH_POINTS",
  };
};

export const getPoint = (id: string | number | null) => {
  return {
    type: "GET_POINTS",
    id,
  };
};
