import React, { FC, useEffect } from "react";
import * as serviceWorker from "../../serviceWorker";
import Popup from "./../Popup/index";

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [
    waitingWorker,
    setWaitingWorker,
  ] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <div className="service-worker-wrapper">
      {showReload && (
        <Popup
          title="Aktualizacja"
          desc={"kliknij przycisk aby zaktualizować"}
          onHidePopup={() => setShowReload(false)}
          onSubmit={() => reloadPage()}
        />
      )}
    </div>
  );
};

export default ServiceWorkerWrapper;
