import { useEffect, useReducer } from "react";

export interface IState {
  status: string;
  position: Array<string | number> | null | undefined;
  error: string | null;
}

interface IAction {
  type: string;
  position?: Array<string | number> | null;
  error?: any;
}

const geoPositionReducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case "error": {
      return {
        ...state,
        status: "rejected",
        error: action.error,
      };
    }
    case "success": {
      return {
        ...state,
        status: "resolved",
        position: action.position,
      };
    }
    case "started": {
      return {
        ...state,
        status: "pending",
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const useGeolocation = () => {
  const initialState: IState = {
    status: "idle",
    position: null,
    error: null,
  };

  const [state, dispatch] = useReducer(geoPositionReducer, initialState);

  useEffect(() => {
    if (!navigator.geolocation) {
      dispatch({
        type: "error",
        error: "Geolokoalizacja nie jest wspierana.",
      });
      return;
    }
    dispatch({ type: "started" });
    let timeout = setTimeout(() => {
      const geoWatch = navigator.geolocation.watchPosition(
        (position) =>
          dispatch({
            type: "success",
            position: [position.coords.latitude, position.coords.longitude],
          }),
        (error) =>
          dispatch({
            type: "error",
            error: "Aktualnie nie możemy znaleźć Twojej lokalizacji.",
          }),
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
      return () => {
        navigator.geolocation.clearWatch(geoWatch);
        clearTimeout(timeout);
      };
    }, 3000);
  }, []);

  return state;
};

export default useGeolocation;
