import React, { useCallback, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { setMapPosition } from "../../../redux/map/actions";
import { RootState, MapInterface } from "../../../redux/reducers";
import { Link } from "react-router-dom";
import Icon from "../../FormElements/Icons";
import Button from "../../FormElements/Button";
import { GeolocationContext } from "../../../contexts/GeolocationContext";
import { checkIfContainsPoint } from "../../../services/positions";
import { MapContext, IMapContext } from "../../../contexts/MapContext";
import Popup from "./../../Popup";

const MapButtons = ({ inside = false, building = "" }) => {
  const dispatch: Dispatch = useDispatch();
  const userLocation = useContext(GeolocationContext);
  const isResolved = userLocation?.status === "resolved";
  const isRejected = userLocation?.status === "rejected";
  const [isPopup, setPopup] = useState("");
  const { mapInstance } = useContext<IMapContext>(MapContext);
  const mapData: MapInterface = useSelector<RootState, MapInterface>(
    (state) => state.Map
  );

  const onShowUserLocation = useCallback(() => {
    if (isResolved) {
      if (checkIfContainsPoint(mapData.maxBounds, userLocation.position)) {
        dispatch(setMapPosition({ zoom: 19, center: userLocation.position }));
        inside &&
          setPopup(
            `Aktualnie znajdujesz się w ${building ? building : "budynku"}`
          );
      } else {
        setPopup("Aktualnie nie znajdujesz się w obrębie Politechniki");
      }
    } else if (isRejected) {
      setPopup("Nie udało się odnaleźć Twojej lokalizacji");
    }
  }, [
    userLocation,
    isResolved,
    isRejected,
    dispatch,
    mapData.maxBounds,
    inside,
    building,
  ]);

  return (
    <>
      <div data-html2canvas-ignore="true" className="map-wrapper__buttons">
        <Link
          to="/point"
          className="button button-with-icon button-radius-small "
          data-tooltip="Wyszukaj obiekt"
          aria-label="Wyszukaj objekt"
        >
          <Icon name="search" />
        </Link>
        <Link
          to="/qrscanner"
          className="button button-with-icon button-radius-small  mobile-only"
          data-tooltip="Zeskanuj kod QR"
          aria-label="Zaskanuj kod qr"
        >
          <Icon name="qr" />
        </Link>
        <Button
          onClick={onShowUserLocation}
          className="button-with-icon button-radius-small "
          tooltip="Pokaż moją lokalizację"
        >
          <Icon name="position" />
        </Button>
        <Button
          onClick={() =>
            mapInstance && mapInstance.setZoom(mapInstance.getZoom() + 1)
          }
          className="button-with-icon button-radius-small  desktop-only"
          tooltip="Przybliż"
        >
          <Icon name="plus" />
        </Button>
        <Button
          onClick={() => mapInstance?.setZoom(mapInstance?.getZoom() - 1)}
          className="button-with-icon button-radius-small  desktop-only"
          tooltip="Oddal"
        >
          <Icon name="minus" />
        </Button>
      </div>
      {isPopup?.length > 0 && (
        <Popup desc={isPopup} onHidePopup={() => setPopup("")} />
      )}
    </>
  );
};

export default MapButtons;
