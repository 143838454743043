import { UserInterface, DefaultApiAction } from "../reducers";

export const INIT_STATE = {
  selectedCampus: null,
  error: false,
  loading: false,
  favouritePoints: [],
};

export default function reducer(
  state: UserInterface = INIT_STATE,
  action: DefaultApiAction
): UserInterface {
  if (state === undefined) {
    return INIT_STATE;
  }
  switch (action.type) {
    case "SELECT_CAMPUS":
      return {
        ...state,
        selectedCampus: action.id,
      };

    case "ADD_FAVOURITE_POINT":
      return {
        ...state,
        favouritePoints: [
          ...state.favouritePoints.filter(
            (point) => point.num !== action?.data?.num
          ),
          action.data,
        ],
      };

    case "REMOVE_FAVOURITE_POINT":
      return {
        ...state,
        favouritePoints: state.favouritePoints.filter(
          (e) => e.num !== action.id
        ),
      };

    default:
      return state;
  }
}
