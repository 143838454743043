import React, { useState, FunctionComponent, useEffect, useMemo } from "react";
import { GeolocationContext } from "./../../contexts/GeolocationContext";
import { MapContext, IMapContext } from "./../../contexts/MapContext";
import { IEvent } from "./../../interfaces";
import { EventContext, IEventContext } from "./../../contexts/EventContext";
import {
  PreviousUrlContext,
  IPreviousUrlContext,
} from "./../../contexts/PreviousUrl";
import { PathContext, IPathContext, IPath } from "./../../contexts/PathContext";
import {
  HandicappedContext,
  IHandicappedContext,
} from "./../../contexts/HandicappedContext";
import {
  StairsContext,
  IStairsContext,
  IStairs,
} from "./../../contexts/StairsContext";
import { useHistory } from "react-router-dom";
import useGeolocation, { IState } from "./../../hooks/useGeolocation";
import {
  EntrancesContext,
  IEntrancesContext,
  IEntrances,
} from "../../contexts/EntrancesContext";

interface IContextsWrapper {
  children?: React.ReactElement[] | React.ReactElement;
}

const ContextsWrapper: FunctionComponent<IContextsWrapper> = ({ children }) => {
  const history = useHistory();

  const handicap = useMemo(() => {
    return history.location.search.split("/")?.includes("handicapped");
  }, [history]);

  const [mapInstance, setMapInstance] = useState<L.Map | null>(null);
  const [pathPoints, setPathPoints] = useState<IPath | null>(null);
  const [stairsPoints, setStairsPoints] = useState<IStairs | null>({
    start: [],
    destination: [],
  });
  const [entrancesPoints, setEntrancesPoints] = useState<IEntrances | null>({
    start: null,
    destination: null,
  });

  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);
  const [previousUrl, setPreviousUrl] = useState<string>("");
  const [handicappedPath, setHandicappedPath] = useState<boolean>(false);

  const map: IMapContext = { mapInstance, setMapInstance };
  const path: IPathContext = { pathPoints, setPathPoints };
  const stairs: IStairsContext = { stairsPoints, setStairsPoints };
  const entrances: IEntrancesContext = { entrancesPoints, setEntrancesPoints };

  const event: IEventContext = { selectedEvent, setSelectedEvent };
  const prevUrl: IPreviousUrlContext = { previousUrl, setPreviousUrl };
  const handicapped: IHandicappedContext = {
    handicappedPath,
    setHandicappedPath,
  };
  const { status, position, error }: IState = useGeolocation();

  useEffect(() => {
    if (pathPoints?.start && pathPoints?.destination) {
      history.push(
        `/paths/${pathPoints?.destination?.type}-${
          pathPoints?.destination?.id
        }/${pathPoints?.start?.type}-${pathPoints?.start?.id}/${
          handicap ? "handicapped" : ""
        }`
      );
    }
  }, [pathPoints, history, handicap]);

  return (
    <section>
      <PreviousUrlContext.Provider value={prevUrl}>
        <EventContext.Provider value={event}>
          <GeolocationContext.Provider value={{ status, position, error }}>
            <MapContext.Provider value={map}>
              <HandicappedContext.Provider value={handicapped}>
                <PathContext.Provider value={path}>
                  <StairsContext.Provider value={stairs}>
                    <EntrancesContext.Provider value={entrances}>
                      {children}
                    </EntrancesContext.Provider>
                  </StairsContext.Provider>
                </PathContext.Provider>
              </HandicappedContext.Provider>
            </MapContext.Provider>
          </GeolocationContext.Provider>
        </EventContext.Provider>
      </PreviousUrlContext.Provider>
    </section>
  );
};

export default ContextsWrapper;
