import { createContext, SetStateAction, Dispatch } from "react";

export interface IHandicappedContext {
  handicappedPath: boolean;
  setHandicappedPath: Dispatch<SetStateAction<boolean>>;
}

export const HandicappedContext = createContext<IHandicappedContext>({
  handicappedPath: false,
  setHandicappedPath: (isHandicapped) => {},
});
