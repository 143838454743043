import React, { FunctionComponent, useState, useMemo, useContext } from "react";
import { URL } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import { IFloor, IPoint, IFile, IOccupant } from "../../interfaces";
import { Link, useHistory } from "react-router-dom";
import { addFavouritePoint } from "../../redux/user/actions";
import Button from "../../components/FormElements/Button";
import Slider from "react-slick";
import Icon from "../../components/FormElements/Icons";
import { GeolocationContext } from "../../contexts/GeolocationContext";
import { getDistance } from "../../services/positions";
import { onCopyUrl } from "../../services/copyUrl";
import { PathContext, IPathContext } from "../../contexts/PathContext";
import { toast } from "react-toastify";

interface IPointDescription {
  data: any;
  type: string;
}

const PointDescription: FunctionComponent<IPointDescription> = ({
  data,
  type,
}) => {
  const history = useHistory();

  const userLocation = useContext(GeolocationContext);
  const { pathPoints, setPathPoints }: IPathContext = useContext(PathContext);
  const isResolved = userLocation?.status === "resolved";
  const dispatch: Dispatch = useDispatch();

  const [showOptions, setShowOptions] = useState(false);

  const allFloors: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Floors
  );

  const onAddToFavourite = (data: IPoint) => {
    data && dispatch(addFavouritePoint({ ...data, type: type }));
    setShowOptions(false);
    toast.success("Punkt dodany do ulubionych.");
  };

  const onCopyClick = () => {
    onCopyUrl();
    setShowOptions(false);
    toast.success("Link skopiowany do schowka.");
  };

  const onSetStartingPoint = () => {
    const newData = { ...data, type: type };

    if (pathPoints?.start) {
      setPathPoints({ start: newData });
    } else {
      setPathPoints(
        pathPoints?.destination?.id === data?.id
          ? { start: newData }
          : { ...pathPoints, start: newData }
      );
    }

    toast.success("Ustawiono jako punkt startowy.");
    setShowOptions(false);
  };

  const onSetDestinationPoint = () => {
    const newData = { ...data, type: type };
    if (pathPoints?.destination) {
      setPathPoints({ destination: newData });
    } else {
      setPathPoints(
        pathPoints?.start?.id === data?.id
          ? { destination: newData }
          : { ...pathPoints, destination: newData }
      );
    }

    toast.success("Ustawiono jako punkt końcowy.");
    setShowOptions(false);
  };

  const distance = useMemo(
    () =>
      isResolved && data
        ? getDistance(
            data?.coordinates
              ? [data?.coordinates?.coordinates?.flat(2)]
              : [[data?.coordinates_y, data?.coordinates_x]],
            userLocation,
            "Znajdujesz się przy punkcie"
          )
        : "Brak danych",
    [data, isResolved, userLocation]
  );

  const floors = useMemo(
    () =>
      allFloors?.data?.data
        ?.filter((floor: IFloor) => floor?.building_id === data?.building_id)
        ?.sort((a: IFloor, b: IFloor) => a.level - b.level),
    [allFloors, data]
  );

  const currentImage = useMemo(() => {
    return data?.symbol || data?.emblem;
  }, [data]);

  const occupants = useMemo(() => {
    if (data?.occupants?.length > 0) {
      return data?.occupants?.map((occupant: IOccupant) => occupant.name);
    }
  }, [data]);

  return (
    <div className="point__content">
      <div className="row">
        <div className="col-15">
          <span className="point__title">
            {type !== "building" && data.name}
          </span>
          <span
            className="point__description"
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          ></span>
        </div>
        <div className="col-9">
          <div className="point__distance">
            <Icon name="pin" />
            <span> {distance}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-24">
          <div className="point__buttons">
            <Link
              aria-label="Wyznacz trasę od punktu"
              className="button"
              to={`/paths/${type}-${data.id}`}
            >
              <Icon name="navigate" />
              Nawiguj
            </Link>

            <Button
              onClick={() => setShowOptions(!showOptions)}
              className="button-white"
            >
              <>
                <Icon name="options" /> Opcje
              </>
            </Button>
            {showOptions && (
              <div
                onClick={() => setShowOptions(false)}
                className="point__options-wrapper"
              >
                {type !== "building" && (
                  <>
                    <Button
                      onClick={() => onSetStartingPoint()}
                      className="button-white"
                    >
                      <>
                        <Icon name="start" /> Ustaw jako punkt startowy
                      </>
                    </Button>
                    <Button
                      onClick={() => onSetDestinationPoint()}
                      className="button-white"
                    >
                      <>
                        <Icon name="finish" /> Ustaw jako punkt końcowy
                      </>
                    </Button>
                  </>
                )}

                <Button onClick={() => onCopyClick()} className="button-white">
                  <>
                    <Icon name="copy_url" /> Kopiuj adres URL punktu
                  </>
                </Button>
                <Button
                  onClick={() => onAddToFavourite(data)}
                  className="button-white"
                >
                  <>
                    <Icon name="fav_points" /> Zapisz punkt w ulubionych
                  </>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-24">
          {data?.images?.length > 0 && (
            <div className="gallery">
              <Gallery data={data.images} />
            </div>
          )}

          <div className="point__img-wrapper">
            {currentImage && (data?.images?.length < 1 || !data?.images) && (
              <img src={`${URL}doc/${currentImage}`} alt={`${data.name}`} />
            )}
            <span
              dangerouslySetInnerHTML={{
                __html: data?.content,
              }}
            ></span>
          </div>
        </div>
      </div>

      {occupants?.length > 0 && (
        <div className="row">
          <div className="col-24">
            <span className="point__section-title">Pracownicy:</span>
            <p>{occupants?.join(", ")}</p>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-24">
          <div className="point__floors">
            {floors?.length > 0 && (
              <>
                <span className="point__section-title">Mapy budynku</span>

                <ul className="point__list row ">
                  {floors?.map((floor: IFloor) => {
                    return (
                      <li className="col-md-6 col-sm-12 col-12" key={floor?.id}>
                        <Link
                          aria-label="Wyświetl informacje o punkcie i pokaż go na mapie"
                          to={`/${type}/${data.id}?floor=${floor.id}`}
                        >
                          <Button
                            className={[
                              data?.floor_id === floor.id
                                ? "button-blue"
                                : "button-white",
                              Number(
                                history?.location?.search
                                  ?.split("=")[1]
                                  ?.split("/")[0]
                              ) === floor.id && "selected",
                            ].join(" ")}
                          >
                            <span>Piętro {floor.level}</span>
                          </Button>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
      {data?.files?.length > 0 && (
        <div className="row">
          <div className="col-24">
            <div className="point__files">
              <span className="point__section-title">Do pobrania</span>
              <ul className="point__list">
                {data.files.map((file: IFile, index: number) => {
                  return (
                    <li key={index}>
                      <a
                        download={`file.name`}
                        href={`${URL}doc/${file.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Pobierz plik"
                      >
                        <Icon name="download" />
                        {file?.name ? file.name : `Plik ${index + 1}`}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PointDescription;

const Gallery = ({ data }: { data: Array<IFile> }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Slider {...settings}>
        {data.map((e) => {
          return (
            <div className="point__img-wrapper">
              <img
                src={`${URL}doc/${e.doc_id}`}
                alt={e?.name || "zdjęcie punktu"}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
