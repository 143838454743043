import React, { FunctionComponent } from "react";
import L from "leaflet";
import { Marker } from "react-leaflet";

interface IUserMarker {
  userPosition?: any;
}

const UserMarker: FunctionComponent<IUserMarker> = ({ userPosition }) => {
  const icon = L.icon({
    iconUrl: "/images/my_position.svg",
    iconSize: [34, 34],
    iconAnchor: [17, 34],
  });

  return <Marker icon={icon} position={userPosition} />;
};

export default UserMarker;
