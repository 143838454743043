import React, {
  FunctionComponent,
  useRef,
  useEffect,
  useState,
  useContext,
  useMemo,
} from "react";
import { Map, TileLayer } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { setMapPosition } from "../../../redux/map/actions";
import {
  RootState,
  MapInterface,
  DefaultApiResult,
} from "../../../redux/reducers";
import { useLocation } from "react-router-dom";
import UserMarker from "../UserMarker";
import GeojsonWrapper from "./../GeojsonWrapper/index";
import MarkersList from "./../MarkersList";
import EntrancesMarkers from "./../EntrancesMarkers";
import {
  IPoint,
  IRoute,
  IRoom,
  IBuilding,
  IEntrance,
  ISetting,
  IExcludedArea,
  IEvent,
} from "../../../interfaces";
import { GeolocationContext } from "../../../contexts/GeolocationContext";
import { MapContext } from "../../../contexts/MapContext";
import { EventContext } from "../../../contexts/EventContext";

import {
  getBounds,
  convertXY,
  stringToLatLng,
  checkIfContainsPoint,
} from "../../../services/positions";
import Routes from "../Routes";
import MapButtons from "../MapButtons";
import Loader from "react-loader-spinner";
import ExcludedAreas from "./../ExludedAreas";
import { getCurrentDate } from "./../../../services/date";
import Popup from "./../../Popup";

const MapWrapper: FunctionComponent = () => {
  const location = useLocation();
  const dispatch: Dispatch = useDispatch();
  const mapRef: any = useRef(null);
  const userLocation = useContext(GeolocationContext);
  const isResolved = userLocation?.status === "resolved";
  const { setMapInstance } = useContext(MapContext);
  const { selectedEvent } = useContext(EventContext);
  const [floorId, setFloorId] = useState("");
  const [buildingId, setBuildingId] = useState("");
  const [roomId, setRoomId] = useState("");
  const [pointId, setPointId] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  // CHECK IF SHOULD SHOW PATHS
  const isPath = useMemo(
    () =>
      location.pathname.split("/").includes("paths") ||
      location.pathname.split("/").includes("navigate"),
    [location]
  );

  const isNavigate = useMemo(
    () => location.pathname.split("/").includes("navigate"),
    [location]
  );

  const handicapped = useMemo(() => {
    return location.search.split("/")?.includes("handicapped");
  }, [location]);

  const start = useMemo(() => {
    const startUrl = location.pathname?.split("/")[3]?.split("-");

    if (startUrl?.length > 0) {
      if (startUrl?.length > 3) {
        return {
          type: "floor",
          id: Number(startUrl[1]),
          lat: Number(startUrl[2]),
          lng: Number(startUrl[3]),
        };
      }
      if (isNaN(Number(startUrl[0]))) {
        return {
          type: startUrl[0],
          id: Number(startUrl[1]),
        };
      } else if (startUrl?.length > 1) {
        return {
          lat: Number(startUrl[0]),
          lng: Number(startUrl[1]),
        };
      }
    }
    return null;
  }, [location.pathname]);

  const destination = useMemo(() => {
    let path = location.pathname;
    let url = path.split("/");

    return {
      type: isPath ? url[2].split("-")[0] : url[1],
      id: isPath ? url[2].split("-")[1] : url[2],
    };
  }, [location, isPath]);

  const mapData: MapInterface = useSelector<RootState, MapInterface>(
    (state) => state.Map
  );

  const buildings: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Buildings
  );

  const rooms: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Rooms
  );

  const allPoints: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Points
  );

  const routes: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Routes
  );

  const settings: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Settings
  );

  const events: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Events
  );

  const excludedAreas: DefaultApiResult = useSelector<
    RootState,
    DefaultApiResult
  >((state) => state.ExcludedAreas);

  // STYLES AND ICONS FROM CMS

  const selectedColor = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "selected_element_color"
      )?.value || "#ffaf00",

    [settings]
  );

  const routeColor = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "inside_path_color"
      )?.value || "#ffaf00",
    [settings]
  );

  const startPointColor = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "start_point_color"
      )?.value || "#ffaf00",
    [settings]
  );

  const endPointColor = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "end_point_color"
      )?.value || "#ffaf00",
    [settings]
  );

  const startPointImage = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "start_point_image"
      )?.value || null,
    [settings]
  );

  const endPointImage = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "end_point_image"
      )?.value || null,
    [settings]
  );

  const allEntrances: DefaultApiResult = useSelector<
    RootState,
    DefaultApiResult
  >((state) => state.Entrances);

  const loading = useMemo(() => {
    return (
      routes?.loading ||
      allEntrances?.loading ||
      excludedAreas?.loading ||
      settings?.loading
    );
  }, [routes, allEntrances, excludedAreas, settings]);

  const building = useMemo(
    () =>
      buildingId &&
      (buildings?.byId[buildingId]?.data ||
        buildings?.data?.data?.find(
          (building: IBuilding) => building.id === Number(buildingId)
        )),
    [buildingId, buildings]
  );

  const destinationRoom = useMemo(
    () =>
      roomId &&
      (rooms?.byId[roomId]?.data ||
        rooms?.data?.data?.find((room: IRoom) => room.id === Number(roomId))),
    [roomId, rooms]
  );

  const destinationPoint = useMemo(
    () =>
      pointId &&
      (allPoints?.byId[pointId]?.data ||
        allPoints?.data?.data?.find(
          (point: IPoint) => point.id === Number(pointId)
        )),
    [pointId, allPoints]
  );

  const startRoom = useMemo(() => {
    if (start?.id && start?.type === "room") {
      return (
        rooms?.byId[start?.id]?.data ||
        rooms?.data?.data?.find((room: IRoom) => room.id === start?.id)
      );
    }
  }, [start, rooms]);

  const startPoint = useMemo(() => {
    if (start?.id && start?.type === "point") {
      return (
        allPoints?.byId[start?.id]?.data ||
        allPoints?.data?.data?.find((point: IPoint) => point.id === start?.id)
      );
    }
  }, [start, allPoints]);

  const points = useMemo(
    () => (allPoints?.data?.data?.length > 0 && allPoints?.data?.data) || [],
    [allPoints]
  );

  // FILTER ENTRANCES IF HANDICAPPED

  // TODO uncomment
  const entrances = useMemo(() => {
    if (allEntrances?.data?.data?.length > 0) {
      if (handicapped) {
        return allEntrances?.data?.data.filter((entrance: IEntrance) => {
          if (entrance.type === "stairs" || entrance.type === "elevator") {
            return !!entrance.handicap_enabled;
          } else {
            return entrance;
          }
        });
      } else {
        return allEntrances?.data?.data;
      }
    } else {
      return [];
    }
  }, [allEntrances, handicapped]);

  const destinationFloorId = useMemo(() => {
    if (location?.search?.length > 0) {
      return location.search.split("=")[1]?.split("/")[0] || "";
    }
    return false;
  }, [location]);

  const startFloorId = useMemo(() => {
    if (location?.search?.length > 0) {
      return location.search.split("=")[1]?.split("/")[1] || "";
    }
    return false;
  }, [location]);

  const startData = useMemo(() => {
    if (!isNavigate) {
      if (start?.type === "floor" && start?.lat) {
        return {
          ...start,
          building_id: Number(buildingId),
          coordinates: convertXY(start.lng, start.lat),
          floor_id: start.id,
        };
      }
      if (start && (startRoom || startPoint)) {
        if (start?.type === "point" && startPoint?.coordinates_y) {
          return {
            ...startPoint,
            type: start?.type,
            coordinates: convertXY(
              startPoint.coordinates_y,
              startPoint.coordinates_x
            ),
          };
        } else if (startRoom?.coordinates) {
          return { ...startRoom, type: start?.type };
        }
      } else {
        return start || null;
      }
    } else if (
      userLocation.position &&
      userLocation.position?.length > 0 &&
      isResolved &&
      start?.type === "navigate"
    ) {
      return {
        lat: Number(userLocation.position[0]),
        lng: Number(userLocation.position[1]),
      };
    }
  }, [
    start,
    startPoint,
    startRoom,
    buildingId,
    userLocation,
    isResolved,
    isNavigate,
  ]);

  const destinationData = useMemo(() => {
    if ((destinationPoint || destinationRoom) && destination?.type) {
      if (destination.type === "point" && destinationPoint?.coordinates_y) {
        return {
          ...destinationPoint,
          type: destination.type,
          coordinates: convertXY(
            destinationPoint.coordinates_y,
            destinationPoint.coordinates_x
          ),
        };
      } else if (destinationRoom?.coordinates && destination.type === "room") {
        return { ...destinationRoom, type: destination.type };
      }
    }
    return destination || null;
  }, [destinationPoint, destinationRoom, destination]);

  const startBuilding = useMemo(
    () => buildings?.byId[startData?.building_id]?.data,
    [buildings, startData]
  );

  const sameBuilding = useMemo(() => {
    if (startData?.building_id && destinationData?.building_id) {
      return startData.building_id === destinationData.building_id;
    }
    return false;
  }, [startData, destinationData]);

  const alonePoints = useMemo(
    () =>
      points?.length > 0
        ? points.filter(
            (point: IPoint) => !point.building_id && !point.floor_id
          )
        : [],
    [points]
  );

  const destinationPoints = useMemo(
    () =>
      points?.length > 0 && buildingId && floorId
        ? points.filter(
            (point: IPoint) =>
              point.building_id === Number(buildingId) &&
              point.floor_id === Number(floorId)
          )
        : [],
    [buildingId, floorId, points]
  );

  const startPoints = useMemo(
    () =>
      points?.length > 0 && buildingId && floorId
        ? points.filter(
            (point: IPoint) =>
              point.building_id === startData?.building_id &&
              point.floor_id === (Number(startFloorId) || startData?.floor_id)
          )
        : [],
    [buildingId, floorId, points, startData, startFloorId]
  );

  const destinationEntrances = useMemo(() => {
    if (entrances?.length > 0 && floorId && buildingId) {
      return entrances.filter((point: IPoint) => {
        if (point.type === "outside") {
          return point.building_id === Number(buildingId);
        } else {
          return point.building_id === Number(buildingId);
        }
      });
    }
  }, [entrances, buildingId, floorId]);

  const startEntrances = useMemo(() => {
    if (entrances?.length > 0 && startFloorId && !sameBuilding)
      return entrances.filter((point: IPoint) => {
        if (point.type === "outside") {
          return (
            point.building_id === (startData?.building_id || Number(start?.id))
          );
        } else {
          return (
            point.building_id === (startData?.building_id || Number(start?.id))
          );
        }
      });
  }, [entrances, startData, startFloorId, start, sameBuilding]);

  const destinationRoute = useMemo(() => {
    if (buildingId && floorId && routes?.data?.data?.length > 0)
      return routes.data.data.filter(
        (route: IRoute) => route?.building_id === Number(buildingId)
      );
  }, [buildingId, floorId, routes]);

  const startRoute = useMemo(() => {
    if (startFloorId && routes?.data?.data?.length > 0) {
      return routes.data.data.filter(
        (route: IRoute) => route?.building_id === Number(startData?.building_id)
      );
    }
  }, [startData, startFloorId, routes]);

  const activeEvents = useMemo(() => {
    if (events?.data?.data?.length > 0) {
      return events?.data?.data.filter(
        (event: IEvent) =>
          event.date_from <= getCurrentDate() &&
          event.date_to >= getCurrentDate()
      );
    }
  }, [events]);

  const destinationExcludedAreas = useMemo(() => {
    if (excludedAreas?.data?.data?.length > 0) {
      const points = excludedAreas?.data?.data
        ?.filter(
          (area: IExcludedArea) =>
            activeEvents
              ?.map((event: IEvent) => event?.id)
              ?.includes(area?.occasional_event_id) ||
            (handicapped && !area?.occasional_event_id)
        )
        ?.map((e: IExcludedArea) => {
          return { ...e, coordinates: stringToLatLng(e.coordinates) };
        });

      return points;
    }
  }, [excludedAreas, activeEvents, handicapped]);

  const startExcludedAreas = useMemo(() => {
    if (excludedAreas?.data?.data?.length > 0 && !sameBuilding) {
      const points = excludedAreas?.data?.data
        ?.filter(
          (area: IExcludedArea) =>
            activeEvents
              ?.map((event: IEvent) => event?.id)
              ?.includes(area?.occasional_event_id) ||
            (handicapped && !area?.occasional_event_id)
        )
        ?.map((e: IExcludedArea) => {
          return { ...e, coordinates: stringToLatLng(e.coordinates) };
        });

      return points;
    }
  }, [excludedAreas, activeEvents, handicapped, sameBuilding]);

  const disabledExcludedAreas = useMemo(() => {
    if (excludedAreas?.data?.data?.length > 0) {
      const points = excludedAreas?.data?.data
        ?.filter(
          (area: IExcludedArea) => handicapped && !area?.occasional_event_id
        )
        ?.map((e: IExcludedArea) => {
          return { ...e, coordinates: stringToLatLng(e.coordinates) };
        });

      return points;
    }
    return [];
  }, [excludedAreas, handicapped]);

  const showDetails = useMemo(
    () => mapData?.position?.zoom && mapData.position.zoom > 18,
    [mapData]
  );

  // SET DATA FOR ELEMENTS FROM URL
  useEffect(() => {
    if (destination?.type && destination?.id) {
      if (destination.type === "building") {
        setBuildingId(destination.id || "");
        setFloorId("");
        setRoomId("");
        setPointId("");
      } else if (destination.type === "room") {
        setRoomId(destination.id || "");
        setPointId("");
      } else if (destination.type === "point") {
        setPointId(destination.id || "");
        setRoomId("");
      } else {
        setFloorId("");
        setRoomId("");
        setBuildingId("");
      }
    } else {
      setBuildingId("");
      setFloorId("");
      setRoomId("");
    }
  }, [destination, destinationFloorId]);

  useEffect(() => {
    if (destinationFloorId && floorId !== destinationFloorId) {
      setFloorId(destinationFloorId);
    }
  }, [destinationFloorId, floorId]);

  useEffect(() => {
    if (
      buildingId !== String(destinationData?.building_id) &&
      destinationData?.building_id
    ) {
      setBuildingId(String(destinationData.building_id));
    }
  }, [destinationData, buildingId]);

  // POSITION MAP ON BUILDING SELECT
  useEffect(() => {
    if (building) {
      if (building?.coordinates?.coordinates) {
        let bounds = getBounds(building?.coordinates.coordinates?.flat(2));
        if (startBuilding?.coordinates?.coordinates) {
          bounds = getBounds(startBuilding?.coordinates.coordinates?.flat(2));
        }
        const correctBounds = [
          [bounds.getEast(), bounds.getNorth()],
          [bounds.getWest(), bounds.getSouth()],
        ];
        mapRef.current.leafletElement.fitBounds(correctBounds);
      }
    } else {
      mapRef.current.leafletElement.setMaxBounds(mapData.maxBounds);
    }
  }, [buildings, building, mapData.maxBounds, startBuilding]);

  // SET MAP CONTEXT FOR OTHER VIEWS
  useEffect(() => {
    if (mapRef?.current?.leafletElement) {
      setMapInstance(mapRef.current.leafletElement);
    }
  }, [mapRef, setMapInstance]);

  const userInsideBuilding = useMemo(() => {
    if (
      isResolved &&
      userLocation?.position &&
      building?.coordinates?.coordinates
    ) {
      return !!checkIfContainsPoint(
        building?.coordinates?.coordinates?.flat(1),
        [userLocation?.position[1], userLocation?.position[0]]
      );
    }
    return false;
  }, [building, userLocation, isResolved]);

  return (
    <div className="map-wrapper">
      <MapButtons inside={userInsideBuilding} building={building?.name} />
      {showPopup && (
        <Popup
          desc={"Nie odnaleziono ścieżki"}
          onHidePopup={() => setShowPopup(false)}
        />
      )}
      {mapData && (
        <Map
          tap={false}
          minZoom={11}
          maxZoom={23}
          maxNativeZoom={19}
          center={mapData?.position?.center}
          zoom={mapData?.position?.zoom}
          ref={mapRef}
          useFly={true}
          zoomControl={false}
          routeWhileDragging={true}
          onZoomEnd={(e: any) =>
            dispatch(
              setMapPosition({
                zoom: e.target.getZoom(),
                center: e.target.getCenter(),
              })
            )
          }
        >
          {!isPath && buildingId && showDetails ? (
            <></>
          ) : (
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            />
          )}
          {isResolved && !userInsideBuilding && (
            <UserMarker userPosition={userLocation?.position} />
          )}

          <GeojsonWrapper
            buildingId={buildingId}
            floorId={Number(floorId)}
            roomId={roomId}
            pointId={pointId}
            showDetails={showDetails}
            loading={loading}
            start={!sameBuilding ? startData : start}
            startFloor={
              !sameBuilding ? Number(startFloorId) || startData?.floor_id : null
            }
            startColor={startPointColor}
            endColor={endPointColor}
            startIcon={startPointImage}
            endIcon={endPointImage}
            selectedColor={selectedColor}
            isPath={isPath}
          />
          {!loading ? (
            <>
              {!showDetails && selectedEvent && selectedEvent?.points && (
                <MarkersList data={selectedEvent?.points} />
              )}

              {alonePoints?.length > 0 && !selectedEvent && (
                <MarkersList data={alonePoints} />
              )}

              {showDetails && (
                <>
                  {destinationPoints && destinationPoints?.length > 0 && (
                    <MarkersList
                      data={destinationPoints}
                      selected={destinationPoint}
                      selectedColor={selectedColor}
                    />
                  )}
                  {startPoints && startPoints?.length > 0 && (
                    <MarkersList
                      data={startPoints}
                      selected={startPoint}
                      selectedColor={selectedColor}
                    />
                  )}
                  {destinationEntrances && destinationEntrances?.length > 0 && (
                    <EntrancesMarkers
                      data={destinationEntrances?.filter(
                        (point: IPoint) => point.floor_id === Number(floorId)
                      )}
                      selectedColor={selectedColor}
                    />
                  )}
                  {startEntrances && startEntrances?.length > 0 && (
                    <EntrancesMarkers
                      data={startEntrances?.filter(
                        (point: IPoint) =>
                          point.floor_id === Number(startFloorId)
                      )}
                      selectedColor={selectedColor}
                    />
                  )}
                  {mapRef?.current?.leafletElement &&
                    destination?.type !== "building" &&
                    isPath &&
                    destinationData &&
                    destinationFloorId &&
                    startData && (
                      <>
                        {destinationRoute && destinationEntrances && (
                          <Routes
                            map={mapRef.current.leafletElement}
                            floor={destinationFloorId}
                            routes={destinationRoute}
                            entrances={destinationEntrances}
                            destination={destinationData}
                            start={startData}
                            sameBuilding={sameBuilding}
                            type="destination"
                            color={routeColor}
                            excludedAreas={destinationExcludedAreas || null}
                            handicapped={handicapped}
                            onShowPopup={(e) => setShowPopup(e)}
                          />
                        )}
                        {startRoute &&
                          startFloorId &&
                          startFloorId !== "handicapped" &&
                          startEntrances &&
                          !sameBuilding && (
                            <Routes
                              map={mapRef.current.leafletElement}
                              floor={startFloorId}
                              routes={startRoute}
                              entrances={startEntrances}
                              destination={startData}
                              start={destinationData}
                              sameBuilding={sameBuilding}
                              type="start"
                              color={routeColor}
                              excludedAreas={startExcludedAreas || null}
                              handicapped={handicapped}
                              onShowPopup={(e) => setShowPopup(e)}
                            />
                          )}
                      </>
                    )}

                  {destinationExcludedAreas?.length > 0 && (
                    <ExcludedAreas
                      data={destinationExcludedAreas
                        ?.filter(
                          (e: IEntrance) =>
                            e.floor_id === Number(destinationFloorId)
                        )
                        ?.map((e: IEntrance) => e.coordinates)}
                    />
                  )}
                  {startExcludedAreas?.length > 0 && (
                    <ExcludedAreas
                      data={startExcludedAreas
                        ?.filter(
                          (e: IEntrance) => e.floor_id === Number(startFloorId)
                        )
                        ?.map((e: IEntrance) => e.coordinates)}
                    />
                  )}

                  {disabledExcludedAreas?.length > 0 && (
                    <ExcludedAreas
                      data={disabledExcludedAreas
                        ?.filter(
                          (e: IEntrance) =>
                            e.floor_id === Number(destinationFloorId) ||
                            (e.floor_id === Number(startFloorId) &&
                              !sameBuilding)
                        )
                        ?.map((e: IEntrance) => e.coordinates)}
                      handicapped={handicapped}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <div className="loader loader--map__loader">
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </div>
          )}
        </Map>
      )}
    </div>
  );
};

export default MapWrapper;
