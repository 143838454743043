import React, { FunctionComponent, useCallback } from "react";
import L from "leaflet";
import { Polygon, Marker } from "react-leaflet";
import { getCenter } from "../../../services/positions";
interface IExcludedAreas {
  data: Array<Array<{ lat: number; lng: number }>> | null;
  handicapped?: boolean;
  isEvent?: boolean;
}

const ExcludedAreas: FunctionComponent<IExcludedAreas> = ({
  data,
  handicapped = false,
}) => {
  const getCurrentIcon = useCallback(() => {
    return L.divIcon({
      html: !handicapped
        ? ` <span class="geojson__name">
      Obszar wykluczony
      </span>`
        : `<span class="marker__icon">
      <svg
      height="512"
      viewBox="0 0 512.513 512.513"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M292.844 210.259c.337-1.656-.111.257 10.864-38.698l-.825 37.443c-.199 9.044 7.044 16.505 16.083 16.582l62.13.529h.141c8.895 0 16.146-7.174 16.221-16.086.076-8.96-7.126-16.286-16.086-16.362l-45.685-.389.463-21.004 2.669 11.335 42.636.363a25.8 25.8 0 0116.175 5.85l25.05-18.784c7.169-5.375 8.623-15.545 3.247-22.714-5.375-7.169-15.545-8.623-22.714-3.247l-27.846 20.88-4.997-23.188c-3.22-14.943-17.945-24.446-32.888-21.226l-33.87 7.3c-14.943 3.22-24.446 17.945-21.226 32.888zM296.367 226.61l3.068 14.234a88.79 88.79 0 018.852-6.217 20.775 20.775 0 01-11.92-8.017zM324.183 114.197c19.703-2.805 33.402-21.051 30.597-40.754s-21.051-33.402-40.754-30.597-33.402 21.051-30.597 40.754c2.804 19.704 21.05 33.403 40.754 30.597zM419.441 216.728l-14.257 3.073a25.994 25.994 0 01-9.022 11.273 91.956 91.956 0 018.101 4.653c20.209 13.042 34.128 33.174 39.195 56.685a90.46 90.46 0 011.873 24.35l2.66 4.905a19.385 19.385 0 0010.214 8.912l-15.578 3.357a89.912 89.912 0 01-9.724 23.307l64.517-13.904c5.598-1.206 9.157-6.722 7.951-12.319s-6.718-9.158-12.32-7.951l-14.155 3.051c5.919-5.939 7.525-15.271 3.323-23.019l-41.563-76.624a19.47 19.47 0 00-21.215-9.749zM211.422 139.953l20.898-5.036-8.518-15.474c-2.762-5.016-9.067-6.844-14.082-4.083-5.016 2.761-6.845 9.066-4.083 14.082zM253.798 190.821l-12.691 3.058 38.215 69.424c.157-.248.299-.502.458-.749a90.625 90.625 0 0113.697-16.538l-32.04-58.205a29.63 29.63 0 01-7.639 3.01z" />
      <circle cx="154.37" cy="64.939" r="38.374" />
      <path d="M355.452 293.633c-11.321 0-19.746 10.441-17.365 21.488 2.066 9.586 11.512 15.674 21.086 13.609 9.581-2.065 15.674-11.505 13.609-21.086-1.792-8.322-9.149-14.012-17.33-14.011z" />
      <path d="M383.582 384.821c15.533-5.916 28.744-16.567 37.927-30.795 11.39-17.649 15.226-38.677 10.801-59.211-4.426-20.533-16.583-38.115-34.231-49.505a78.9 78.9 0 00-20.892-9.532l-42.093-.358c-18.91 5.041-35.046 16.763-45.732 33.321-11.39 17.649-15.226 38.677-10.801 59.211 7.925 36.767 40.511 61.992 76.676 62.077l-210.679 38.697c3.569-3.433 5.979-8.103 6.463-13.422l9.189-100.985a21.332 21.332 0 00-3.256-13.4l-30.668-48.11 20.468-61.924-28.594-30.006 47.895 32.383a17.066 17.066 0 0013.557 2.453l71.231-17.164c9.163-2.208 14.801-11.426 12.593-20.589-2.207-9.163-11.427-14.802-20.589-12.594l-63.911 15.4-41.658-28.166 23.618 9.421c-.686-10.21-7.416-19.475-17.73-22.884L99.272 104.63c-13.593-4.493-28.255 2.884-32.748 16.478L24.784 247.39.198 429.987c-1.363 9.993 4.432 19.306 13.45 22.781L0 455.303v30.645h512.487V360.853l-128.905 23.968zM35.571 448.742a21.233 21.233 0 006.901-12.988L66.295 261.11l18.605 6.15 32.158 50.447-8.529 93.729c-.859 9.444 4.577 18.006 12.849 21.545zm331.272-113.954a26.016 26.016 0 01-11.425 2.638c-.249 0-.493-.025-.741-.032l6.804 31.572c-16.597 1.754-32.643-3.812-44.519-14.353l23.931-21.671a26.293 26.293 0 01-8.353-9.219l-23.94 21.679a57.552 57.552 0 01-9.769-21.82 57.731 57.731 0 01-.116-23.917l30.749 9.895a26.035 26.035 0 013.826-11.839l-30.755-9.897a58.7 58.7 0 014.25-7.841c7.252-11.236 17.916-19.441 30.442-23.564l6.801 31.558a26.01 26.01 0 015.922-2.051c2.373-.511 4.246-.613 6.243-.555l-6.806-31.582c2.058-.22 4.115-.33 6.165-.33 11.014 0 21.81 3.158 31.284 9.272a58.64 58.64 0 017.102 5.394l-23.96 21.697a26.293 26.293 0 018.353 9.219l23.963-21.7a57.751 57.751 0 019.746 21.841 57.731 57.731 0 01.116 23.917l-30.749-9.895a26.042 26.042 0 01-3.825 11.839l30.755 9.897a58.7 58.7 0 01-4.25 7.841c-7.252 11.236-17.916 19.44-30.442 23.564z" />
    </svg>
</span>`,
    });
  }, [handicapped]);

  return (
    <>
      {data &&
        data?.map((area: any) => {
          return handicapped ? (
            <Marker
              key={Math.random()}
              draggable={false}
              icon={getCurrentIcon()}
              position={{
                lat: getCenter(area).lng,
                lng: getCenter(area).lat,
              }}
            ></Marker>
          ) : (
            <Polygon
              key={Math.random()}
              positions={area.map((el: Array<number>) => [el[1], el[0]])}
              color="black"
              fillColor="black"
              onclick={(e) => L.DomEvent.stopPropagation(e)}
              opacity={1}
              fillOpacity={0.6}
            ></Polygon>
          );
        })}
    </>
  );
};

export default ExcludedAreas;
