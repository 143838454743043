import L from "leaflet";
import "leaflet-control-geocoder";

const LF: any = L;

export const geocoder = new LF.Control.Geocoder.Nominatim({
  geocodingQueryParams: {
    countrycodes: "pl",
    bounded: 1,
    viewbox:
      "18.582837581634525, 50.31864122745701,19.071911573410038, 50.2222660917139",
  },
});
