import React, { FunctionComponent, useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState, UserInterface } from "../../redux/reducers";
import { IPoint } from "../../interfaces";
import DataList from "../../components/FormElements/DataList";
import Box from "../../components/Box";
import { GeolocationContext } from "../../contexts/GeolocationContext";
import { getDistance } from "../../services/positions";

const FavPoints: FunctionComponent = () => {
  const userLocation = useContext(GeolocationContext);
  const isResolved = userLocation?.status === "resolved";

  const user: UserInterface = useSelector<RootState, UserInterface>(
    (state) => state.User
  );
  const data = useMemo(
    () =>
      isResolved
        ? user.favouritePoints?.map((point: IPoint) => {
            return {
              ...point,
              distance:
                point?.coordinates_y || point?.coordinates?.coordinates
                  ? getDistance(
                      [
                        point.coordinates_y
                          ? [point.coordinates_y, point.coordinates_x]
                          : [point.coordinates.coordinates.flat(2)],
                      ],
                      userLocation,
                      "Znajdujesz się przy punkcie"
                    )
                  : "Brak danych",
            };
          })
        : user.favouritePoints,
    [user, userLocation, isResolved]
  );

  return (
    <section className="container element">
      <div className="scrollable">
        <Box loading={user.loading} title="Zapisane punkty" link="/favourite">
          <div className="search">
            {data && (
              <DataList
                data={data}
                loading={false}
                container={"point"}
                link={"point"}
                msg={"Brak zapisanych punktów"}
                removable
              />
            )}
          </div>
        </Box>
      </div>
    </section>
  );
};

export default FavPoints;
