import React, { FunctionComponent } from "react";

const ICONS = {
  position: (
    <svg
      data-name="Group 9"
      xmlns="http://www.w3.org/2000/svg"
      width="32.729"
      height="32.729"
      viewBox="0 0 32.729 32.729"
    >
      <path data-name="Path 2036" d="M0 0h32.729v32.729H0z" fill="none" />
      <path
        data-name="Path 2037"
        d="M28.557 15.001A12.266 12.266 0 0017.729 4.173V1.364h-2.728v2.809A12.266 12.266 0 004.173 15.001H1.364v2.727h2.809a12.266 12.266 0 0010.828 10.829v2.807h2.727v-2.807a12.266 12.266 0 0010.829-10.828h2.807v-2.728zm-12.193 10.91a9.546 9.546 0 119.547-9.547 9.539 9.539 0 01-9.547 9.547z"
        fill="#202326"
      />
      <path
        data-name="Path 2038"
        d="M16.365 8.756a3.442 3.442 0 11-3.442 3.442 3.438 3.438 0 013.442-3.442zm0 15.212a8.262 8.262 0 01-6.884-3.694c.034-2.284 4.589-3.535 6.884-3.535s6.85 1.251 6.884 3.535a8.262 8.262 0 01-6.884 3.694z"
        fill="#202326"
      />
      <path
        data-name="Path 2039"
        d="M7.078 6.932h18.574v18.572H7.078z"
        fill="none"
      />
    </svg>
  ),
  checked: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="352.62"
      height="352.62"
      viewBox="0 0 352.62 352.62"
    >
      <path d="M337.222 22.952c-15.912-8.568-33.66 7.956-44.064 17.748-23.867 23.256-44.063 50.184-66.708 74.664-25.092 26.928-48.348 53.856-74.052 80.173-14.688 14.688-30.6 30.6-40.392 48.96-22.032-21.421-41.004-44.677-65.484-63.648C28.774 167.385-.602 157.593.01 190.029c1.224 42.229 38.556 87.517 66.096 116.28 11.628 12.24 26.928 25.092 44.676 25.704 21.42 1.224 43.452-24.48 56.304-38.556 22.645-24.48 41.005-52.021 61.812-77.112 26.928-33.048 54.468-65.485 80.784-99.145 16.524-20.808 68.544-72.217 27.54-94.248zM26.937 187.581c-.612 0-1.224 0-2.448.611-2.448-.611-4.284-1.224-6.732-2.448 1.836-1.224 4.896-.612 9.18 1.837z" />
    </svg>
  ),
  search: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.7 32.7"
      width="30"
      height="30"
      fill="#fff"
    >
      <path
        id="Path_2020"
        d="M21.1 19.2h-1l-.4-.3c2.9-3.4 2.5-8.6-.9-11.5s-8.5-2.6-11.4.8-2.5 8.6.9 11.5c3.1 2.6 7.6 2.6 10.6 0l.3.4v1l6.3 6.3 1.9-1.9-6.3-6.3zm-7.5 0c-3.1 0-5.6-2.5-5.6-5.6S10.5 8 13.6 8s5.6 2.5 5.6 5.6c0 3.1-2.5 5.6-5.6 5.6z"
      />
    </svg>
  ),
  arrow: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 451.846 451.847"
    >
      <path
        fill="#fff"
        d="M345.441 248.292L151.154 442.573c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744L278.318 225.92 106.409 54.017c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.287 194.284c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373z"
      />
    </svg>
  ),
  print: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M472.178 133.907h-54.303V35.132c0-9.425-7.641-17.067-17.067-17.067H111.192c-9.425 0-17.067 7.641-17.067 17.067v98.775H39.822C17.864 133.907 0 151.772 0 173.73v171.702c0 21.958 17.864 39.822 39.822 39.822h54.306v91.614c0 9.425 7.641 17.067 17.067 17.067h289.61c9.425 0 17.067-7.641 17.067-17.067v-91.614h54.306c21.958 0 39.822-17.864 39.822-39.822V173.73c0-21.957-17.864-39.823-39.822-39.823zm-343.92-81.708h255.483v81.708H128.258V52.199zm255.48 407.602H128.262V320.173h255.477c-.001 4.229-.001 136.421-.001 139.628zm94.129-114.368c0 3.137-2.552 5.689-5.689 5.689h-54.306v-48.014c0-9.425-7.641-17.067-17.067-17.067h-289.61c-9.425 0-17.067 7.641-17.067 17.067v48.014H39.822c-3.137 0-5.689-2.552-5.689-5.689V173.731c0-3.137 2.552-5.689 5.689-5.689h432.356c3.137 0 5.689 2.552 5.689 5.689v171.702z" />
      <path d="M400.808 199.988h-43.443c-9.425 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h43.443c9.425 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067zM329.956 399.834H182.044c-9.425 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h147.911c9.425 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.066-17.067zM329.956 346.006H182.044c-9.425 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h147.911c9.425 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.066-17.067z" />
    </svg>
  ),
  download: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.398"
      height="14.259"
      viewBox="0 0 14.398 14.259"
    >
      <g data-name="Group 22">
        <path
          data-name="Path 2065"
          d="M7.213 10.299l3.169-3.169H8.005V0H6.421v7.13H4.044z"
        />
        <path
          data-name="Path 2066"
          d="M12.813 0H9.59v1.575h3.224v11.116H1.584V1.575h3.253V0H1.584A1.589 1.589 0 000 1.584v11.091a1.589 1.589 0 001.584 1.584h11.229a1.589 1.589 0 001.585-1.584V1.584A1.589 1.589 0 0012.813 0z"
        />
      </g>
    </svg>
  ),
  save: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.333 469.333">
      <path d="M466.208 88.458L380.875 3.125c-2-2-4.708-3.125-7.542-3.125H42.667C19.146 0 0 19.135 0 42.667v384c0 23.531 19.146 42.667 42.667 42.667h384c23.521 0 42.667-19.135 42.667-42.667V96a10.6655 10.6655 0 00-3.126-7.542zM106.667 21.333h234.667v128c0 11.76-9.563 21.333-21.333 21.333H128c-11.771 0-21.333-9.573-21.333-21.333v-128zM384 448H85.333V256H384v192zm64-21.333c0 11.76-9.563 21.333-21.333 21.333h-21.333V245.333c0-5.896-4.771-10.667-10.667-10.667h-320c-5.896 0-10.667 4.771-10.667 10.667V448H42.667c-11.771 0-21.333-9.573-21.333-21.333v-384c0-11.76 9.563-21.333 21.333-21.333h42.667v128C85.333 172.865 104.479 192 128 192h192c23.521 0 42.667-19.135 42.667-42.667v-128h6.25L448 100.417v326.25z" />
      <path d="M266.667 149.333h42.667c5.896 0 10.667-4.771 10.667-10.667V53.333c0-5.896-4.771-10.667-10.667-10.667h-42.667c-5.896 0-10.667 4.771-10.667 10.667v85.333c0 5.896 4.771 10.667 10.667 10.667zM277.333 64h21.333v64h-21.333V64z" />
    </svg>
  ),
  qr: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 32.7 32.7"
    >
      <g id="Group_6" transform="translate(-3.2 -3.2)">
        <path
          id="Path_2023"
          d="M24.4 32.9v-2.4h6v-6h2.4v5.7c0 1.5-1.2 2.7-2.7 2.7h-5.7zM9 32.9c-1.5 0-2.7-1.2-2.7-2.7v-5.7h2.4v6h6v2.4H9zm21.4-18.2v-6h-6V6.3h5.7c1.5 0 2.7 1.2 2.7 2.7v5.7h-2.4zm-24.1 0V9c0-1.5 1.2-2.7 2.7-2.7h5.7v2.4h-6v6H6.3z"
        />
        <path id="Path_2024" d="M14.3 14.3h2.1v-2.1h-4.2v4.2h2.1v-2.1z" />
        <path id="Path_2025" d="M23.4 12.2h3.5v2.1h-3.5v-2.1z" />
        <path id="Path_2026" d="M26.9 22.7h-2.1v2.1h-1.7v2.1h3.8v-4.2z" />
        <path id="Path_2027" d="M16.4 24.8h-2.1v-2.1h-2.1v4.2h4.2v-2.1z" />
        <path id="Path_2028" d="M18.5 12.2h2.8v2.1h-2.8v-2.1z" />
        <path id="Path_2029" d="M26.9 16.4h-2.1v2.1h-1.7v2.1h3.8v-4.2z" />
        <path
          id="Path_2030"
          d="M16.4 22.7h2.4v4.2H21v-6.3h-2.4v-2.1H21v-2.1h-4.5v2.1h-4.2v2.1h4.2l-.1 2.1z"
        />
      </g>
    </svg>
  ),
  show: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.168"
      height="13.069"
      viewBox="0 0 19.168 13.069"
    >
      <path
        data-name="Path 989"
        d="M9.584 0A10.3 10.3 0 000 6.535a10.3 10.3 0 009.584 6.535 10.3 10.3 0 009.584-6.535A10.3 10.3 0 009.584 0zm0 10.891a4.358 4.358 0 01-4.356-4.356 4.358 4.358 0 014.356-4.356 4.358 4.358 0 014.356 4.356 4.358 4.358 0 01-4.356 4.356zm0-6.97A2.61 2.61 0 006.97 6.535a2.61 2.61 0 002.614 2.614A2.61 2.61 0 0012.2 6.535a2.61 2.61 0 00-2.616-2.614z"
        fill="#b2b2b2"
      />
    </svg>
  ),
  hide: (
    <svg
      data-name="Group 20"
      xmlns="http://www.w3.org/2000/svg"
      width="19.168"
      height="13.07"
      viewBox="0 0 19.168 13.07"
    >
      <path
        data-name="Path 2063"
        d="M17.885 4.217l-3.979 2.655a4.279 4.279 0 01-5.671 3.784l-2.5 1.666a10.271 10.271 0 003.846.748 10.3 10.3 0 009.584-6.535 10.321 10.321 0 00-1.28-2.318z"
        fill="#b2b2b2"
      />
      <path
        data-name="Path 2064"
        d="M16.962.024L14.824 1.45A10.266 10.266 0 000 6.535 10.234 10.234 0 002.162 9.9L0 11.341l1.137 1.7L18.1 1.728zM5.228 6.535a4.358 4.358 0 014.356-4.356 4.289 4.289 0 012.7.964l-1.56 1.043a2.56 2.56 0 00-1.13-.265h-.01A2.611 2.611 0 006.97 6.527v.008c0 .05.016.094.019.143L5.407 7.734a4.166 4.166 0 01-.179-1.199z"
        fill="#b2b2b2"
      />
    </svg>
  ),
  pin: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.647"
      height="16.5"
      viewBox="0 0 11.647 16.5"
    >
      <path
        data-name="Path 2042"
        d="M11.65 5.824a5.824 5.824 0 10-11.647 0c0 4.368 5.824 10.676 5.824 10.676s5.823-6.309 5.823-10.676zm-7.765 0a1.941 1.941 0 111.941 1.941 1.947 1.947 0 01-1.944-1.941z"
        fill="#0091ff"
      />
    </svg>
  ),
  search_bar: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.445"
      height="23.445"
      viewBox="0 0 23.445 23.445"
    >
      <path
        data-name="Path 2020"
        d="M15.142 13.677h-.772l-.274-.264a6.359 6.359 0 10-.684.684l.264.274v.772l4.884 4.875 1.456-1.456zm-5.861 0a4.4 4.4 0 114.4-4.4 4.39 4.39 0 01-4.4 4.4z"
        fill="#373740"
      />
      <path data-name="Path 2021" d="M0 0h23.445v23.445H0z" fill="none" />
    </svg>
  ),
  cam_frame: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="234.679"
      height="234.68"
      viewBox="0 0 234.679 234.68"
    >
      <path
        data-name="Union 3"
        d="M175.714 234.18h0a2.219 2.219 0 01-2.214-2.219 2.219 2.219 0 012.214-2.219h44.084a9.953 9.953 0 009.941-9.941v-44.164h.008a2.216 2.216 0 012.213-2.137 2.215 2.215 0 012.211 2.137h.008V219.8a14.423 14.423 0 01-14.379 14.38zm-160.833 0A14.423 14.423 0 01.5 219.8v-44.086h0a2.219 2.219 0 012.22-2.214 2.219 2.219 0 012.219 2.214h0V219.8a9.953 9.953 0 009.941 9.941h44.162v.008a2.215 2.215 0 012.136 2.211 2.215 2.215 0 01-2.136 2.212v.008zm214.86-175.214h0V14.881A9.953 9.953 0 00219.8 4.94h-44.161v-.008A2.214 2.214 0 01173.5 2.72a2.214 2.214 0 012.137-2.212V.5h44.161a14.423 14.423 0 0114.381 14.381v44.085a2.22 2.22 0 01-2.219 2.214 2.219 2.219 0 01-2.22-2.214zM.508 59.042H.5V14.881A14.423 14.423 0 0114.881.5h44.084a2.219 2.219 0 012.214 2.219 2.219 2.219 0 01-2.214 2.219H14.881a9.953 9.953 0 00-9.941 9.941v44.163h-.008A2.216 2.216 0 012.72 61.18a2.216 2.216 0 01-2.212-2.138z"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
      />
    </svg>
  ),
  close_background: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
      <circle cx="17.4" cy="17.2" r="15.5" fill="#ccc" />
      <path
        d="M22.1 23.7L17.4 19l-4.6 4.6c-.5.5-1.3.5-1.9 0-.5-.5-.5-1.3 0-1.9l4.6-4.6-4.5-4.5c-.5-.5-.5-1.3 0-1.9.5-.5 1.3-.5 1.9 0l4.6 4.6 4.6-4.6c.5-.5 1.3-.5 1.8 0s.5 1.3 0 1.9l-4.6 4.6 4.6 4.6c.5.5.5 1.3 0 1.8-.2.3-.6.4-.9.4-.4 0-.7-.1-.9-.3z"
        fill="#fff"
      />
    </svg>
  ),
  close: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.678"
      height="17.678"
      viewBox="0 0 17.678 17.678"
    >
      <path
        data-name="Union 2"
        d="M8.839 10.253l-7.424 7.425L0 16.264l7.425-7.425L0 1.414 1.415 0l7.424 7.425L16.264 0l1.414 1.414-7.425 7.425 7.425 7.425-1.414 1.414z"
        fill="#fff"
      />
    </svg>
  ),
  flash_light: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <g data-name="Group 13">
        <path data-name="Rectangle 156" fill="none" d="M0 0h41v41H0z" />
      </g>
      <g data-name="Group 16">
        <g data-name="Group 15">
          <g data-name="Group 14">
            <path
              data-name="Path 2060"
              d="M10.114 23.783l5.057 5.057v8.428h10.114V28.84l5.057-5.057v-8.428H10.114zm8.428-20.228h3.371v5.057h-3.371zM5.9 10.095l2.377-2.376 3.573 3.573-2.376 2.377zm22.689 1.2l3.574-3.574 2.376 2.374-3.557 3.574z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  my_position: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <g data-name="Group 21" transform="translate(-1916 59)">
        <circle
          data-name="Ellipse 4"
          cx="22.5"
          cy="22.5"
          r="22.5"
          transform="translate(1916 -59)"
          fill="#ce1034"
          opacity=".173"
        />
        <g data-name="Warstwa 1">
          <g data-name="Group 10">
            <path
              data-name="Path 2046"
              d="M1938.311-48.709a12.129 12.129 0 00-3.115 23.852l3.115 3.115 3.115-3.115a12.129 12.129 0 00-3.115-23.852z"
              fill="#ce1034"
            />
            <path
              data-name="Path 2047"
              d="M1938.311-48.709a12.129 12.129 0 013.115 23.852l-3.115 3.113-3.115-3.115a12.129 12.129 0 013.115-23.85m0-1.685a13.814 13.814 0 00-4 27.04l2.8 2.8 1.192 1.192 1.192-1.192 2.8-2.8a13.814 13.814 0 00-4-27.04z"
              fill="#fff"
            />
          </g>
          <path
            data-name="Path 2048"
            d="M1922.131-51.405h32.36v32.36h-32.36z"
            fill="none"
          />
        </g>
        <g data-name="Warstwa 2">
          <path
            data-name="Path 2049"
            d="M1938.311-46.378a4.567 4.567 0 11-4.567 4.567 4.561 4.561 0 014.567-4.567zm0 20.181a10.964 10.964 0 01-9.134-4.9c.046-3.03 6.089-4.688 9.134-4.688s9.088 1.658 9.134 4.688a10.964 10.964 0 01-9.134 4.9z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  ),
  menu: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="30"
      height="30"
    >
      <path d="M0 0h30v30H0V0z" fill="none" />
      <path
        d="M3.6 22.6h22.8v-2.5H3.6v2.5zm0-6.3h22.8v-2.5H3.6v2.5zm0-8.9v2.5h22.8V7.4H3.6z"
        fill="#fff"
      />
    </svg>
  ),
  select_campus: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 30 30"
    >
      <path
        data-name="Path 2042"
        d="M22.5 10a7.5 7.5 0 00-15 0c0 5.625 7.5 13.75 7.5 13.75s7.5-8.125 7.5-13.75zm-10 0a2.5 2.5 0 112.5 2.5 2.507 2.507 0 01-2.5-2.5zM6.25 25v2.5h17.5V25z"
        fill="#fff"
      />
      <path data-name="Path 2043" d="M0 0h30v30H0z" fill="none" />
    </svg>
  ),
  add_to_fav: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.398"
      height="19.398"
      viewBox="0 0 19.398 19.398"
    >
      <g data-name="Group 31">
        <g data-name="Group 28">
          <g data-name="Group 27">
            <path
              data-name="Path 2073"
              d="M15.934 0H3.464A3.468 3.468 0 000 3.464v12.47A3.468 3.468 0 003.464 19.4h12.47a3.468 3.468 0 003.464-3.464V3.466A3.468 3.468 0 0015.934 0zm2.078 15.934a2.078 2.078 0 01-2.078 2.078H3.464a2.078 2.078 0 01-2.078-2.078V3.464a2.078 2.078 0 012.078-2.078h12.47a2.078 2.078 0 012.078 2.078z"
              fill="#0091ff"
            />
          </g>
        </g>
        <g data-name="Group 30">
          <g data-name="Group 29">
            <path
              data-name="Path 2074"
              d="M13.163 9.006h-2.768V6.235a.693.693 0 10-1.386 0v2.771H6.235a.693.693 0 100 1.386h2.771v2.771a.693.693 0 001.386 0v-2.768h2.771a.693.693 0 100-1.386z"
              fill="#0091ff"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  copy_url: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <g data-name="Group 26">
        <g data-name="Group 25">
          <g data-name="Group 24" fill="#0091ff">
            <path
              data-name="Path 2071"
              d="M7.876 14.482l-2.238 2.239a2.375 2.375 0 11-3.359-3.358l4.478-4.478a2.374 2.374 0 013.359 0 .792.792 0 001.119-1.12 3.957 3.957 0 00-5.6 0l-4.479 4.478a3.96 3.96 0 005.6 5.6l2.237-2.242a.792.792 0 00-1.12-1.12z"
            />
            <path
              data-name="Path 2072"
              d="M17.841 1.159a3.96 3.96 0 00-5.6 0L9.555 3.845a.792.792 0 001.12 1.12l2.686-2.686a2.375 2.375 0 013.359 3.358l-4.926 4.926a2.375 2.375 0 01-3.358 0 .792.792 0 00-1.12 1.12 3.958 3.958 0 005.6 0l4.926-4.926a3.958 3.958 0 00-.001-5.598z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  distance: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.6 16.5">
      <path
        d="M11.5 5.9C11.5 2.8 9 .2 5.9.1S.2 2.6.1 5.8v.1c0 4.3 5.7 10.4 5.7 10.4s5.7-6.1 5.7-10.4zm-7.6 0c0-1 .8-1.9 1.9-1.9 1 0 1.9.8 1.9 1.9s-.8 1.9-1.9 1.9c-1 0-1.9-.8-1.9-1.9z"
        fill="#0091ff"
      />
    </svg>
  ),
  options: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3"
      height="14"
      viewBox="0 0 3 14"
    >
      <defs>
        <clipPath>
          <path fill="none" d="M0 0h3v14H0z" />
        </clipPath>
      </defs>
      <g data-name="Repeat Grid 7" clipPath="url(#a)">
        <circle
          data-name="Ellipse 10"
          cx="1.5"
          cy="1.5"
          r="1.5"
          fill="#202326"
        />
        <circle
          data-name="Ellipse 10"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(0 5)"
          fill="#202326"
        />
        <circle
          data-name="Ellipse 10"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(0 10)"
          fill="#202326"
        />
      </g>
    </svg>
  ),
  events: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 23.1 24.9"
      width="20"
      height="20"
      fill="#fff"
    >
      <g id="Group_36" transform="translate(-164.036 -235.506)">
        <path
          id="Path_2083"
          d="M183.8 237.4v-.8c0-.6-.5-1.1-1.1-1.1s-1.1.5-1.1 1.1v.7h-11.1v-.7c0-.6-.5-1.1-1.1-1.1s-1.1.5-1.1 1.1v.7h-.1c-2.3 0-4.1 1.8-4.1 4.1v14.8c0 2.3 1.8 4.1 4.1 4.1H183c2.3 0 4.1-1.8 4.1-4.1v-14.8c0-1.9-1.4-3.6-3.3-4zm-.8 21.4h-14.8c-1.4 0-2.5-1.1-2.5-2.5v-13h19.8v13c0 1.4-1.2 2.5-2.5 2.5z"
        />
        <path
          id="Path_2084"
          d="M180.4 246.8h-9.6c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h9.6c.7 0 1.2-.5 1.2-1.2s-.5-1.2-1.2-1.2z"
        />
        <path
          id="Path_2085"
          d="M176.8 251.6h-6c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h6c.7 0 1.2-.5 1.2-1.2 0-.6-.6-1.2-1.2-1.2z"
        />
      </g>
    </svg>
  ),
  fav_points: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 23.1 24.9"
      width="20"
      height="20"
      fill="#fff"
    >
      <g id="Group_34" transform="translate(-164.036 -238.934)">
        <g id="Group_33" transform="translate(164.036 238.934)">
          <g id="Group_32">
            <path
              id="Path_2077"
              d="M18.9.9H4.1C1.8.9 0 2.8 0 5v14.8C0 22.1 1.8 24 4.1 24h14.8c2.3 0 4.1-1.8 4.1-4.1V5c.1-2.2-1.8-4.1-4.1-4.1zm2.5 19c0 1.4-1.1 2.5-2.5 2.5H4.1c-1.4 0-2.5-1.1-2.5-2.5V5c0-1.4 1.1-2.5 2.5-2.5h14.8c1.4 0 2.5 1.1 2.5 2.5v14.9z"
            />
          </g>
        </g>
        <path
          id="Path_2078"
          d="M175.6 243.1c-3.2 0-5.8 2.6-5.8 5.8 0 4.4 5.8 10.7 5.8 10.7s5.8-6.3 5.8-10.7c0-3.2-2.6-5.8-5.8-5.8zm0 8.3c-1.4 0-2.6-1.2-2.6-2.6 0-1.4 1.2-2.6 2.6-2.6 1.4 0 2.6 1.2 2.6 2.6-.1 1.4-1.2 2.6-2.6 2.6z"
        />
      </g>
    </svg>
  ),
  hide_menu: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 22.903 22.903"
    >
      <path
        data-name="Path 2075"
        d="M11.452 0L9.433 2.018l7.988 8H0v2.863h17.421l-7.988 8 2.019 2.019L22.9 11.452z"
        fill="#fff"
      />
    </svg>
  ),
  log_in: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 23.1 24.9"
    >
      <path d="M30.4-.6h25v25h-25v-25z" fill="none" />
      <path
        d="M11.5 2C5.8 2 1.1 6.7 1.1 12.4c0 5.8 4.7 10.4 10.4 10.4 5.8 0 10.4-4.7 10.4-10.4C22 6.7 17.3 2 11.5 2zm0 2.9c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5S8 10.3 8 8.4s1.6-3.5 3.5-3.5zm0 16.3c-2.9 0-5.5-1.5-7-3.8 0-2.3 4.7-3.6 7-3.6s7 1.3 7 3.6c-1.4 2.3-4 3.8-7 3.8z"
        fill="#fff"
      />
    </svg>
  ),
  log_out: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 23.1 24.9"
    >
      <path
        d="M11.3 1.9c-.6 0-1.2.5-1.2 1.2v9.2c0 .6.5 1.2 1.2 1.2.6 0 1.2-.5 1.2-1.2V3.1c-.1-.7-.6-1.2-1.2-1.2zm5.9 3.3c-.4.4-.4 1.2 0 1.6 1.3 1.4 2.1 3.3 2.2 5.3.1 4.5-3.5 8.2-8 8.3-4.5.1-8.1-3.5-8.2-8 0-2.1.7-4.1 2.2-5.6.4-.5.4-1.2 0-1.6-.5-.4-1.2-.4-1.6 0C1.9 7.1.9 9.5.9 12 .8 17.7 5.3 22.4 11 22.7c5.7.2 10.5-4.3 10.7-10.1v-.3c0-2.6-1-5.1-2.8-7.1-.5-.4-1.2-.4-1.7 0z"
        fill="#fff"
      />
    </svg>
  ),
  finish: (
    <svg viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero">
        <path d="M14.678 57.95l1.068-.298a1.931 1.931 0 001.34-2.38L4.878 11.585a2.414 2.414 0 00-2.975-1.675l-.138.038A2.414 2.414 0 00.09 12.922L12.299 56.61a1.931 1.931 0 002.379 1.34zM57.67 27.42a46.256 46.256 0 01-10.64-7.32.95.95 0 01-.27-.97A136.854 136.854 0 0050.27.95c.12-1.02-.43-1.32-1.01-.62-11.38 13.61-31.07-2.49-42.79 9.88.14.263.251.542.33.83l7.92 28.36c11.74-12.22 31.36 3.78 42.72-9.8.58-.7.69-1.98.23-2.18z" />
      </g>
    </svg>
  ),
  minus: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M492 236H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h472c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
    </svg>
  ),
  plus: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M492 236H276V20c0-11.046-8.954-20-20-20s-20 8.954-20 20v216H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h216v216c0 11.046 8.954 20 20 20s20-8.954 20-20V276h216c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
    </svg>
  ),
  door: (
    <svg
      width="20"
      height="20"
      viewBox="-80 0 480 480"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M312 0H8a8 8 0 00-8 8v464a8 8 0 008 8h304a8 8 0 008-8V8a8 8 0 00-8-8zm-40 464H48V48h224zm32 0h-16V40a8 8 0 00-8-8H40a8 8 0 00-8 8v424H16V16h288zm0 0" />
      <path d="M72 288h32a8 8 0 008-8v-16h24v-16h-24v-48a8 8 0 00-8-8H72a8 8 0 00-8 8v80a8 8 0 008 8zm8-80h16v40h-8v16h8v8H80zm0 0M112 144h96a8 8 0 008-8v-32a8 8 0 00-8-8h-96a8 8 0 00-8 8v32a8 8 0 008 8zm8-32h80v16h-80zm0 0" />
    </svg>
  ),
  elevator: (
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490.667 490.667"
    >
      <path d="M480 0H10.667C4.779 0 0 4.779 0 10.667V480c0 5.888 4.779 10.667 10.667 10.667H96c5.888 0 10.667-4.779 10.667-10.667v-10.667H384V480c0 5.888 4.779 10.667 10.667 10.667H480c5.888 0 10.667-4.779 10.667-10.667V10.667C490.667 4.779 485.888 0 480 0zm-10.667 469.333h-64v-10.667c0-5.888-4.779-10.667-10.667-10.667H96c-5.888 0-10.667 4.779-10.667 10.667v10.667h-64v-448h448v448z" />
      <path d="M394.667 106.667H96c-5.888 0-10.667 4.779-10.667 10.667v341.333c0 5.888 4.779 10.667 10.667 10.667h298.667c5.888 0 10.667-4.779 10.667-10.667V117.333c-.001-5.888-4.779-10.666-10.667-10.666zM384 448H106.667V128H384v320z" />
      <path d="M245.333 106.667c-5.888 0-10.667 4.779-10.667 10.667v341.333c0 5.888 4.779 10.667 10.667 10.667S256 464.555 256 458.667V117.333c0-5.888-4.779-10.666-10.667-10.666z" />
      <circle cx="437.333" cy="224" r="10.667" />
      <circle cx="437.333" cy="266.667" r="10.667" />
      <path d="M210.219 67.136l-21.333-21.333c-4.16-4.16-10.923-4.16-15.083 0L152.47 67.136c-4.16 4.16-4.16 10.923 0 15.083s10.923 4.16 15.083 0l13.781-13.803 13.781 13.803a10.716 10.716 0 0015.104 0c4.16-4.16 4.16-10.923 0-15.083zM338.197 45.803c-4.16-4.16-10.923-4.16-15.083 0l-13.781 13.781-13.803-13.781c-4.16-4.16-10.923-4.16-15.083 0s-4.16 10.923 0 15.083l21.333 21.333c2.091 2.069 4.821 3.115 7.552 3.115s5.461-1.045 7.531-3.115l21.333-21.333c4.161-4.161 4.161-10.923.001-15.083z" />
    </svg>
  ),
  stairs: (
    <svg
      width="50"
      height="50"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M501.333 426.667H64V384h74.667a10.66 10.66 0 0010.667-10.667V320H224a10.66 10.66 0 0010.667-10.667V256h74.667a10.66 10.66 0 0010.667-10.667V192h74.667a10.66 10.66 0 0010.667-10.667V128H480a10.66 10.66 0 0010.667-10.667A10.66 10.66 0 00480 106.666h-85.333A10.66 10.66 0 00384 117.333v53.333h-74.667a10.66 10.66 0 00-10.667 10.667v53.333H224a10.66 10.66 0 00-10.667 10.667v53.333h-74.667a10.66 10.66 0 00-10.667 10.667v53.333H53.333a10.66 10.66 0 00-10.667 10.667v53.333h-32A10.662 10.662 0 000 437.333 10.66 10.66 0 0010.667 448h490.667a10.66 10.66 0 0010.667-10.667 10.662 10.662 0 00-10.668-10.666zM74.667 234.638c2.5 0 5-.865 7.021-2.635l141.094-123.457-8.906 26.717c-1.854 5.594 1.167 11.635 6.75 13.49 1.125.375 2.25.552 3.375.552 4.458 0 8.625-2.823 10.125-7.292l21.333-64a10.637 10.637 0 00-2.021-10.302c-2.417-2.844-6.188-4.125-9.854-3.594l-64 10.667c-5.813.969-9.75 6.458-8.771 12.271s6.604 9.823 12.271 8.771l26.971-4.493L67.646 215.94c-4.438 3.885-4.896 10.625-1 15.052a10.607 10.607 0 008.021 3.646z" />
    </svg>
  ),
  navigate: (
    <svg
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 494.148 494.148"
    >
      <path d="M502.978 210.683L20.979 1.243A15 15 0 001.244 20.979l209.44 482a15 15 0 0028.101-1.591l44.912-146.847a106.584 106.584 0 0170.844-70.844l146.846-44.912a15 15 0 001.591-28.102z" />
    </svg>
  ),
  start: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.148 494.148">
      <path d="M405.284 201.188L130.804 13.28C118.128 4.596 105.356 0 94.74 0 74.216 0 61.52 16.472 61.52 44.044v406.124c0 27.54 12.68 43.98 33.156 43.98 10.632 0 23.2-4.6 35.904-13.308l274.608-187.904c17.66-12.104 27.44-28.392 27.44-45.884.004-17.48-9.664-33.764-27.344-45.864z" />
    </svg>
  ),
  disabled: (
    <svg
      height="512"
      viewBox="0 0 512.513 512.513"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M292.844 210.259c.337-1.656-.111.257 10.864-38.698l-.825 37.443c-.199 9.044 7.044 16.505 16.083 16.582l62.13.529h.141c8.895 0 16.146-7.174 16.221-16.086.076-8.96-7.126-16.286-16.086-16.362l-45.685-.389.463-21.004 2.669 11.335 42.636.363a25.8 25.8 0 0116.175 5.85l25.05-18.784c7.169-5.375 8.623-15.545 3.247-22.714-5.375-7.169-15.545-8.623-22.714-3.247l-27.846 20.88-4.997-23.188c-3.22-14.943-17.945-24.446-32.888-21.226l-33.87 7.3c-14.943 3.22-24.446 17.945-21.226 32.888zM296.367 226.61l3.068 14.234a88.79 88.79 0 018.852-6.217 20.775 20.775 0 01-11.92-8.017zM324.183 114.197c19.703-2.805 33.402-21.051 30.597-40.754s-21.051-33.402-40.754-30.597-33.402 21.051-30.597 40.754c2.804 19.704 21.05 33.403 40.754 30.597zM419.441 216.728l-14.257 3.073a25.994 25.994 0 01-9.022 11.273 91.956 91.956 0 018.101 4.653c20.209 13.042 34.128 33.174 39.195 56.685a90.46 90.46 0 011.873 24.35l2.66 4.905a19.385 19.385 0 0010.214 8.912l-15.578 3.357a89.912 89.912 0 01-9.724 23.307l64.517-13.904c5.598-1.206 9.157-6.722 7.951-12.319s-6.718-9.158-12.32-7.951l-14.155 3.051c5.919-5.939 7.525-15.271 3.323-23.019l-41.563-76.624a19.47 19.47 0 00-21.215-9.749zM211.422 139.953l20.898-5.036-8.518-15.474c-2.762-5.016-9.067-6.844-14.082-4.083-5.016 2.761-6.845 9.066-4.083 14.082zM253.798 190.821l-12.691 3.058 38.215 69.424c.157-.248.299-.502.458-.749a90.625 90.625 0 0113.697-16.538l-32.04-58.205a29.63 29.63 0 01-7.639 3.01z" />
      <circle cx="154.37" cy="64.939" r="38.374" />
      <path d="M355.452 293.633c-11.321 0-19.746 10.441-17.365 21.488 2.066 9.586 11.512 15.674 21.086 13.609 9.581-2.065 15.674-11.505 13.609-21.086-1.792-8.322-9.149-14.012-17.33-14.011z" />
      <path d="M383.582 384.821c15.533-5.916 28.744-16.567 37.927-30.795 11.39-17.649 15.226-38.677 10.801-59.211-4.426-20.533-16.583-38.115-34.231-49.505a78.9 78.9 0 00-20.892-9.532l-42.093-.358c-18.91 5.041-35.046 16.763-45.732 33.321-11.39 17.649-15.226 38.677-10.801 59.211 7.925 36.767 40.511 61.992 76.676 62.077l-210.679 38.697c3.569-3.433 5.979-8.103 6.463-13.422l9.189-100.985a21.332 21.332 0 00-3.256-13.4l-30.668-48.11 20.468-61.924-28.594-30.006 47.895 32.383a17.066 17.066 0 0013.557 2.453l71.231-17.164c9.163-2.208 14.801-11.426 12.593-20.589-2.207-9.163-11.427-14.802-20.589-12.594l-63.911 15.4-41.658-28.166 23.618 9.421c-.686-10.21-7.416-19.475-17.73-22.884L99.272 104.63c-13.593-4.493-28.255 2.884-32.748 16.478L24.784 247.39.198 429.987c-1.363 9.993 4.432 19.306 13.45 22.781L0 455.303v30.645h512.487V360.853l-128.905 23.968zM35.571 448.742a21.233 21.233 0 006.901-12.988L66.295 261.11l18.605 6.15 32.158 50.447-8.529 93.729c-.859 9.444 4.577 18.006 12.849 21.545zm331.272-113.954a26.016 26.016 0 01-11.425 2.638c-.249 0-.493-.025-.741-.032l6.804 31.572c-16.597 1.754-32.643-3.812-44.519-14.353l23.931-21.671a26.293 26.293 0 01-8.353-9.219l-23.94 21.679a57.552 57.552 0 01-9.769-21.82 57.731 57.731 0 01-.116-23.917l30.749 9.895a26.035 26.035 0 013.826-11.839l-30.755-9.897a58.7 58.7 0 014.25-7.841c7.252-11.236 17.916-19.441 30.442-23.564l6.801 31.558a26.01 26.01 0 015.922-2.051c2.373-.511 4.246-.613 6.243-.555l-6.806-31.582c2.058-.22 4.115-.33 6.165-.33 11.014 0 21.81 3.158 31.284 9.272a58.64 58.64 0 017.102 5.394l-23.96 21.697a26.293 26.293 0 018.353 9.219l23.963-21.7a57.751 57.751 0 019.746 21.841 57.731 57.731 0 01.116 23.917l-30.749-9.895a26.042 26.042 0 01-3.825 11.839l30.755 9.897a58.7 58.7 0 01-4.25 7.841c-7.252 11.236-17.916 19.44-30.442 23.564z" />
    </svg>
  ),
  ue: (
    <svg xmlns="http://www.w3.org/2000/svg" width="95.626" height="20.295" viewBox="0 0 95.626 20.295">
  <g id="Group_25" data-name="Group 25" transform="translate(-121.395 -41.254)">
    <g id="Group_291" data-name="Group 291" transform="translate(186.578 41.254)">
      <path id="Path_394" data-name="Path 394" d="M558.554,60.356V79.027H529.735V60.356h28.819m.812-.812H528.923v20.3h30.443v-20.3Z" transform="translate(-528.923 -59.544)" fill="#fff"/>
      <path id="Path_395" data-name="Path 395" d="M576.806,69.681l.644-.469.642.469-.245-.758.653-.469h-.8l-.245-.767-.245.767H576.4l.651.469Z" transform="translate(-562.234 -65.258)" fill="#fff"/>
      <path id="Path_396" data-name="Path 396" d="M565.668,72.666l.644-.469.642.469-.245-.758.653-.469h-.8l-.247-.769-.245.771-.8,0,.651.469Z" transform="translate(-554.42 -67.351)" fill="#fff"/>
      <path id="Path_397" data-name="Path 397" d="M558.153,78.846l-.245.77-.8,0,.651.469-.243.758.642-.467.644.467-.245-.758.653-.469h-.8Z" transform="translate(-548.696 -73.087)" fill="#fff"/>
      <path id="Path_398" data-name="Path 398" d="M555.163,91.5l.64.471-.243-.758.653-.469h-.8l-.245-.769-.249.771-.8,0,.651.469-.243.758Z" transform="translate(-546.596 -80.893)" fill="#fff"/>
      <path id="Path_399" data-name="Path 399" d="M558.4,101.9l-.247-.767-.245.767h-.8l.651.471-.243.756.642-.469.644.469-.245-.756.653-.471Z" transform="translate(-548.695 -88.727)" fill="#fff"/>
      <path id="Path_400" data-name="Path 400" d="M566.564,110.073l-.245-.767-.247.769-.8,0,.653.469-.243.758.642-.469.644.469-.245-.758.653-.469Z" transform="translate(-554.424 -94.459)" fill="#fff"/>
      <path id="Path_401" data-name="Path 401" d="M577.695,113.028l-.245-.769-.245.769H576.4l.653.468-.245.759.644-.469.644.469-.247-.759.656-.468Z" transform="translate(-562.234 -96.531)" fill="#fff"/>
      <path id="Path_402" data-name="Path 402" d="M588.829,110.073l-.247-.767-.248.769-.8,0,.65.469-.243.758.642-.469.644.469-.245-.758.656-.469Z" transform="translate(-570.047 -94.459)" fill="#fff"/>
      <path id="Path_403" data-name="Path 403" d="M597,101.9l-.247-.767-.245.767h-.8l.652.471-.247.756.644-.469.644.469-.245-.756.654-.471Z" transform="translate(-575.78 -88.727)" fill="#fff"/>
      <path id="Path_404" data-name="Path 404" d="M600.761,90.708h-.8l-.243-.769-.249.769h-.8l.652.469-.248.758.646-.469.642.469-.245-.758Z" transform="translate(-577.855 -80.871)" fill="#fff"/>
      <path id="Path_405" data-name="Path 405" d="M596.11,80.8l.644-.467.644.467-.245-.758.653-.467H597l-.247-.771-.245.773-.8,0,.652.467Z" transform="translate(-575.781 -73.06)" fill="#fff"/>
      <path id="Path_406" data-name="Path 406" d="M588.61,70.671l-.243.771-.807,0,.653.469-.243.759.644-.471.64.471-.243-.759.651-.469h-.8Z" transform="translate(-570.066 -67.351)" fill="#fff"/>
    </g>
    <g id="Group_293" data-name="Group 293" transform="translate(121.394 50.656)">
      <g id="Group_292" data-name="Group 292" transform="translate(0 0)">
        <path id="Path_407" data-name="Path 407" d="M351.171,95.947V92.6h2.045V93H351.64v1h1.4v.391h-1.4v1.16h1.7v.4Z" transform="translate(-339.021 -92.138)" fill="#fff"/>
        <path id="Path_408" data-name="Path 408" d="M361.868,97.663q-.155.038-.408.082a3.515,3.515,0,0,1-.587.044,1.241,1.241,0,0,1-.488-.085.786.786,0,0,1-.319-.239.97.97,0,0,1-.174-.365,1.9,1.9,0,0,1-.053-.466V95.222h.45v1.315a1.14,1.14,0,0,0,.145.657.565.565,0,0,0,.488.2c.048,0,.1,0,.15,0s.1-.007.145-.012l.123-.015a.373.373,0,0,0,.08-.017V95.222h.45Z" transform="translate(-345.102 -93.98)" fill="#fff"/>
        <path id="Path_409" data-name="Path 409" d="M370.081,95.222c.039,0,.083,0,.133.007s.1.011.147.019l.133.024a.824.824,0,0,1,.09.022l-.078.391a1.5,1.5,0,0,0-.176-.046,1.556,1.556,0,0,0-.317-.027,1.185,1.185,0,0,0-.249.027l-.162.036v2.112h-.449V95.382a3,3,0,0,1,.4-.109A2.53,2.53,0,0,1,370.081,95.222Z" transform="translate(-351.638 -93.98)" fill="#fff"/>
        <path id="Path_410" data-name="Path 410" d="M377.214,96.315a1.592,1.592,0,0,1-.087.541,1.237,1.237,0,0,1-.244.416,1.073,1.073,0,0,1-.374.268,1.238,1.238,0,0,1-.948,0,1.073,1.073,0,0,1-.374-.268,1.237,1.237,0,0,1-.244-.416,1.719,1.719,0,0,1,0-1.08,1.234,1.234,0,0,1,.244-.418,1.073,1.073,0,0,1,.374-.268,1.238,1.238,0,0,1,.948,0,1.073,1.073,0,0,1,.374.268,1.233,1.233,0,0,1,.244.418A1.6,1.6,0,0,1,377.214,96.315Zm-.469,0a1.082,1.082,0,0,0-.191-.675.667.667,0,0,0-1.039,0,1.284,1.284,0,0,0,0,1.349.666.666,0,0,0,1.039,0A1.08,1.08,0,0,0,376.745,96.315Z" transform="translate(-355.638 -93.82)" fill="#fff"/>
        <path id="Path_411" data-name="Path 411" d="M387.016,96.537a1.767,1.767,0,0,1-.075.527,1.194,1.194,0,0,1-.22.416,1,1,0,0,1-.356.271,1.138,1.138,0,0,1-.481.1,1.172,1.172,0,0,1-.384-.058,1.206,1.206,0,0,1-.249-.111v1.005h-.45v-3.33q.159-.038.4-.084a2.954,2.954,0,0,1,.553-.046,1.407,1.407,0,0,1,.522.092,1.124,1.124,0,0,1,.4.261,1.156,1.156,0,0,1,.254.413A1.6,1.6,0,0,1,387.016,96.537Zm-.469,0a.965.965,0,0,0-.218-.677.75.75,0,0,0-.58-.237,2.67,2.67,0,0,0-.317.015,1.255,1.255,0,0,0-.181.034v1.585a.916.916,0,0,0,.237.13.887.887,0,0,0,.339.063.725.725,0,0,0,.331-.07.608.608,0,0,0,.225-.193.822.822,0,0,0,.126-.29A1.6,1.6,0,0,0,386.547,96.537Z" transform="translate(-362.618 -93.98)" fill="#fff"/>
        <path id="Path_412" data-name="Path 412" d="M393.792,96.319a1.61,1.61,0,0,1,.1-.582,1.212,1.212,0,0,1,.256-.414,1.043,1.043,0,0,1,.367-.246,1.151,1.151,0,0,1,.425-.082.969.969,0,0,1,.778.317,1.457,1.457,0,0,1,.271.964v.075a.663.663,0,0,1-.005.084h-1.721a.92.92,0,0,0,.227.595.831.831,0,0,0,.619.2,1.647,1.647,0,0,0,.4-.041,1.431,1.431,0,0,0,.244-.08l.063.377a1.343,1.343,0,0,1-.288.092,2.044,2.044,0,0,1-.466.048,1.482,1.482,0,0,1-.568-.1,1.058,1.058,0,0,1-.394-.273,1.113,1.113,0,0,1-.23-.413A1.749,1.749,0,0,1,393.792,96.319Zm1.725-.246a.755.755,0,0,0-.152-.5.524.524,0,0,0-.433-.2.594.594,0,0,0-.273.06.644.644,0,0,0-.2.157.716.716,0,0,0-.128.223,1.085,1.085,0,0,0-.06.256Z" transform="translate(-368.925 -93.82)" fill="#fff"/>
        <path id="Path_413" data-name="Path 413" d="M401.144,96.361a1.015,1.015,0,0,1-.15-.014.791.791,0,0,1-.155-.039l.058-.367a.881.881,0,0,0,.112.024.745.745,0,0,0,.116.01.374.374,0,0,0,.331-.143.732.732,0,0,0,.1-.418v-2.47H402v2.465a1.019,1.019,0,0,1-.22.718A.837.837,0,0,1,401.144,96.361Zm.633-3.871a.29.29,0,0,1-.206-.08.316.316,0,0,1,0-.43.3.3,0,0,1,.411,0,.316.316,0,0,1,0,.43A.288.288,0,0,1,401.777,92.49Z" transform="translate(-373.87 -91.649)" fill="#fff"/>
        <path id="Path_414" data-name="Path 414" d="M407.385,97.247a.884.884,0,0,0,.408-.073.245.245,0,0,0,.133-.232.309.309,0,0,0-.131-.261,2.083,2.083,0,0,0-.43-.218q-.145-.058-.278-.118a.97.97,0,0,1-.229-.143.621.621,0,0,1-.155-.2.633.633,0,0,1-.058-.285.64.64,0,0,1,.246-.529,1.05,1.05,0,0,1,.672-.2,1.882,1.882,0,0,1,.213.012c.071.008.137.018.2.029s.115.024.162.036.083.024.109.034l-.082.387a1.126,1.126,0,0,0-.227-.08,1.465,1.465,0,0,0-.372-.041.69.69,0,0,0-.329.075.249.249,0,0,0-.14.235.32.32,0,0,0,.032.145.337.337,0,0,0,.1.114.791.791,0,0,0,.162.094q.1.043.232.092.179.068.319.133a1.015,1.015,0,0,1,.239.152.588.588,0,0,1,.152.21.76.76,0,0,1,.053.3.6.6,0,0,1-.259.527,1.286,1.286,0,0,1-.737.179,1.916,1.916,0,0,1-.522-.056q-.188-.056-.256-.084l.082-.387q.077.029.246.087A1.429,1.429,0,0,0,407.385,97.247Z" transform="translate(-377.917 -93.82)" fill="#fff"/>
        <path id="Path_415" data-name="Path 415" d="M415.308,93.621a3.076,3.076,0,0,1,.307.276q.162.165.319.355t.3.387a4.2,4.2,0,0,1,.237.365h-.527a4.109,4.109,0,0,0-.235-.345q-.133-.176-.278-.339a4.026,4.026,0,0,0-.295-.3,2.709,2.709,0,0,0-.285-.227V95h-.45V91.33l.45-.077v2.291l.256-.256c.093-.093.185-.189.275-.285s.176-.189.256-.278.15-.167.208-.235h.527q-.111.121-.241.266t-.271.292q-.14.148-.283.295Z" transform="translate(-383.384 -91.195)" fill="#fff"/>
        <path id="Path_416" data-name="Path 416" d="M422.837,92.49a.29.29,0,0,1-.206-.08.316.316,0,0,1,0-.43.3.3,0,0,1,.411,0,.315.315,0,0,1,0,.43A.288.288,0,0,1,422.837,92.49Zm.227,2.968h-.45V92.944h.45Z" transform="translate(-389.101 -91.649)" fill="#fff"/>
        <path id="Path_417" data-name="Path 417" d="M426.322,96.319a1.6,1.6,0,0,1,.1-.582,1.216,1.216,0,0,1,.256-.414,1.047,1.047,0,0,1,.368-.246,1.148,1.148,0,0,1,.425-.082.968.968,0,0,1,.778.317,1.458,1.458,0,0,1,.271.964v.075a.728.728,0,0,1,0,.084h-1.721a.918.918,0,0,0,.227.595.83.83,0,0,0,.618.2,1.651,1.651,0,0,0,.4-.041,1.42,1.42,0,0,0,.244-.08l.063.377a1.343,1.343,0,0,1-.288.092,2.043,2.043,0,0,1-.466.048,1.483,1.483,0,0,1-.568-.1,1.058,1.058,0,0,1-.394-.273,1.108,1.108,0,0,1-.229-.413A1.742,1.742,0,0,1,426.322,96.319Zm1.725-.246a.752.752,0,0,0-.152-.5.523.523,0,0,0-.432-.2.593.593,0,0,0-.273.06.648.648,0,0,0-.2.157.728.728,0,0,0-.128.223,1.1,1.1,0,0,0-.06.256Z" transform="translate(-391.75 -93.82)" fill="#fff"/>
        <path id="Path_418" data-name="Path 418" d="M439.524,95.947V92.6h2.02V93h-1.551v1.01h1.377v.4h-1.377v1.542Z" transform="translate(-401.013 -92.138)" fill="#fff"/>
        <path id="Path_419" data-name="Path 419" d="M449.573,97.663q-.155.038-.408.082a3.515,3.515,0,0,1-.587.044,1.242,1.242,0,0,1-.488-.085.786.786,0,0,1-.319-.239.97.97,0,0,1-.174-.365,1.9,1.9,0,0,1-.053-.466V95.222h.45v1.315a1.14,1.14,0,0,0,.145.657.565.565,0,0,0,.488.2c.048,0,.1,0,.15,0s.1-.007.145-.012l.124-.015a.37.37,0,0,0,.079-.017V95.222h.45Z" transform="translate(-406.64 -93.98)" fill="#fff"/>
        <path id="Path_420" data-name="Path 420" d="M456.858,95.348q.154-.038.411-.082a3.563,3.563,0,0,1,.59-.043,1.274,1.274,0,0,1,.5.084.78.78,0,0,1,.317.237.918.918,0,0,1,.167.365,2.114,2.114,0,0,1,.048.469v1.411h-.449V96.474a2.148,2.148,0,0,0-.032-.4.666.666,0,0,0-.1-.266.409.409,0,0,0-.193-.147.848.848,0,0,0-.3-.046c-.048,0-.1,0-.15,0s-.1.007-.147.012-.089.01-.126.017l-.08.014v2.122h-.45Z" transform="translate(-413.176 -93.98)" fill="#fff"/>
        <path id="Path_421" data-name="Path 421" d="M467.37,91.136l.449-.078v3.678q-.155.044-.4.087a3.172,3.172,0,0,1-.556.044,1.41,1.41,0,0,1-.522-.092,1.111,1.111,0,0,1-.4-.261,1.145,1.145,0,0,1-.254-.413,1.577,1.577,0,0,1-.089-.548,1.786,1.786,0,0,1,.075-.532,1.191,1.191,0,0,1,.22-.416,1,1,0,0,1,.355-.271,1.14,1.14,0,0,1,.481-.1,1.163,1.163,0,0,1,.384.058,1.2,1.2,0,0,1,.249.111Zm0,1.691a.934.934,0,0,0-.237-.13.894.894,0,0,0-.338-.063.724.724,0,0,0-.331.07.609.609,0,0,0-.225.193.813.813,0,0,0-.126.292,1.617,1.617,0,0,0-.039.363.975.975,0,0,0,.217.679.746.746,0,0,0,.58.239,2.307,2.307,0,0,0,.307-.017,1.48,1.48,0,0,0,.191-.036Z" transform="translate(-419.314 -91.058)" fill="#fff"/>
        <path id="Path_422" data-name="Path 422" d="M477.469,97.663q-.154.038-.408.082a3.515,3.515,0,0,1-.587.044,1.238,1.238,0,0,1-.488-.085.783.783,0,0,1-.319-.239.97.97,0,0,1-.174-.365,1.9,1.9,0,0,1-.053-.466V95.222h.45v1.315a1.139,1.139,0,0,0,.145.657.564.564,0,0,0,.488.2c.048,0,.1,0,.15,0s.1-.007.145-.012l.123-.015a.382.382,0,0,0,.08-.017V95.222h.449Z" transform="translate(-426.213 -93.98)" fill="#fff"/>
        <path id="Path_423" data-name="Path 423" d="M484.884,97.247a.884.884,0,0,0,.408-.073.245.245,0,0,0,.133-.232.309.309,0,0,0-.131-.261,2.083,2.083,0,0,0-.43-.218q-.145-.058-.278-.118a.97.97,0,0,1-.229-.143.622.622,0,0,1-.155-.2.633.633,0,0,1-.058-.285.64.64,0,0,1,.246-.529,1.051,1.051,0,0,1,.672-.2,1.882,1.882,0,0,1,.213.012c.071.008.137.018.2.029s.115.024.162.036.083.024.109.034l-.082.387a1.12,1.12,0,0,0-.227-.08,1.465,1.465,0,0,0-.372-.041.69.69,0,0,0-.329.075.249.249,0,0,0-.14.235.32.32,0,0,0,.032.145.336.336,0,0,0,.1.114.791.791,0,0,0,.162.094q.1.043.232.092.179.068.319.133a1.016,1.016,0,0,1,.239.152.588.588,0,0,1,.152.21.762.762,0,0,1,.053.3.6.6,0,0,1-.259.527,1.286,1.286,0,0,1-.737.179,1.916,1.916,0,0,1-.522-.056q-.188-.056-.256-.084l.082-.387q.077.029.246.087A1.428,1.428,0,0,0,484.884,97.247Z" transform="translate(-432.294 -93.82)" fill="#fff"/>
        <path id="Path_424" data-name="Path 424" d="M493.065,95.738q-.1.111-.268.314t-.358.449q-.191.247-.382.517t-.336.517H493.1v.377h-1.919v-.3q.116-.213.285-.469t.355-.51q.186-.254.363-.479t.307-.379h-1.242V95.4h1.813Z" transform="translate(-437.261 -94.105)" fill="#fff"/>
        <path id="Path_425" data-name="Path 425" d="M498.669,96.319a1.612,1.612,0,0,1,.1-.582,1.213,1.213,0,0,1,.256-.414,1.043,1.043,0,0,1,.367-.246,1.151,1.151,0,0,1,.425-.082.968.968,0,0,1,.778.317,1.456,1.456,0,0,1,.271.964v.075a.657.657,0,0,1-.005.084h-1.721a.92.92,0,0,0,.227.595.831.831,0,0,0,.618.2,1.647,1.647,0,0,0,.4-.041,1.431,1.431,0,0,0,.244-.08l.063.377a1.342,1.342,0,0,1-.288.092,2.044,2.044,0,0,1-.466.048,1.482,1.482,0,0,1-.568-.1,1.058,1.058,0,0,1-.394-.273,1.112,1.112,0,0,1-.23-.413A1.749,1.749,0,0,1,498.669,96.319Zm1.725-.246a.755.755,0,0,0-.152-.5.524.524,0,0,0-.433-.2.593.593,0,0,0-.273.06.643.643,0,0,0-.2.157.715.715,0,0,0-.128.223,1.084,1.084,0,0,0-.06.256Z" transform="translate(-442.512 -93.82)" fill="#fff"/>
        <path id="Path_426" data-name="Path 426" d="M311.48,115.038q.735,0,.735-.5a.506.506,0,0,0-.065-.263.636.636,0,0,0-.177-.188,1.224,1.224,0,0,0-.254-.138q-.142-.058-.3-.116a3.075,3.075,0,0,1-.348-.143,1.214,1.214,0,0,1-.285-.189.8.8,0,0,1-.191-.258.849.849,0,0,1-.07-.362.839.839,0,0,1,.3-.687,1.264,1.264,0,0,1,.827-.246,2.178,2.178,0,0,1,.553.065,1.267,1.267,0,0,1,.365.143l-.15.382a1.326,1.326,0,0,0-.3-.123,1.632,1.632,0,0,0-.466-.06,1.033,1.033,0,0,0-.251.029.65.65,0,0,0-.2.087.431.431,0,0,0-.138.147.421.421,0,0,0-.051.21.446.446,0,0,0,.053.227.529.529,0,0,0,.15.162,1.3,1.3,0,0,0,.225.128c.085.039.18.077.283.116q.218.087.4.174a1.3,1.3,0,0,1,.314.208.83.83,0,0,1,.205.287,1,1,0,0,1,.073.4.79.79,0,0,1-.322.677,1.506,1.506,0,0,1-.906.237,2.336,2.336,0,0,1-.365-.027,2.74,2.74,0,0,1-.3-.063,1.714,1.714,0,0,1-.225-.077c-.063-.027-.112-.051-.147-.07l.14-.387a2.091,2.091,0,0,0,.338.14A1.739,1.739,0,0,0,311.48,115.038Z" transform="translate(-310.446 -105.713)" fill="#fff"/>
        <path id="Path_427" data-name="Path 427" d="M320.1,113.225h.952v.377H320.1v1.16a1.39,1.39,0,0,0,.029.312.469.469,0,0,0,.087.193.313.313,0,0,0,.145.1.639.639,0,0,0,.2.029.951.951,0,0,0,.326-.046l.172-.065.087.372a1.639,1.639,0,0,1-.237.084,1.338,1.338,0,0,1-.387.051,1.173,1.173,0,0,1-.423-.065.618.618,0,0,1-.268-.2.781.781,0,0,1-.143-.321,2.137,2.137,0,0,1-.041-.442v-2.242l.45-.078Z" transform="translate(-316.902 -106.066)" fill="#fff"/>
        <path id="Path_428" data-name="Path 428" d="M327.1,115.072c.039,0,.083,0,.133.007s.1.011.147.019l.133.024a.774.774,0,0,1,.09.022l-.077.391a1.493,1.493,0,0,0-.177-.046,1.553,1.553,0,0,0-.316-.027,1.185,1.185,0,0,0-.249.027l-.162.036v2.112h-.45v-2.407a3.013,3.013,0,0,1,.4-.109A2.533,2.533,0,0,1,327.1,115.072Z" transform="translate(-321.483 -107.907)" fill="#fff"/>
        <path id="Path_429" data-name="Path 429" d="M334.28,117.513q-.155.039-.408.082a3.521,3.521,0,0,1-.587.044,1.24,1.24,0,0,1-.488-.085.784.784,0,0,1-.319-.239.961.961,0,0,1-.174-.365,1.894,1.894,0,0,1-.053-.466v-1.411h.45v1.315a1.139,1.139,0,0,0,.145.657.565.565,0,0,0,.488.2c.048,0,.1,0,.15,0s.1-.007.145-.012l.124-.015a.376.376,0,0,0,.08-.017v-2.122h.45Z" transform="translate(-325.745 -107.907)" fill="#fff"/>
        <path id="Path_430" data-name="Path 430" d="M342.475,113.471a3.03,3.03,0,0,1,.307.275q.162.165.319.355t.3.387a4.218,4.218,0,0,1,.237.365h-.527a4.119,4.119,0,0,0-.235-.345q-.133-.176-.278-.338a3.976,3.976,0,0,0-.295-.3,2.714,2.714,0,0,0-.285-.227v1.208h-.45V111.18l.45-.077v2.291l.256-.256c.093-.093.185-.189.275-.285s.176-.189.256-.278.15-.167.208-.235h.527q-.111.121-.241.266t-.271.292q-.14.148-.283.295Z" transform="translate(-332.281 -105.123)" fill="#fff"/>
        <path id="Path_431" data-name="Path 431" d="M350.293,113.225h.952v.377h-.952v1.16a1.371,1.371,0,0,0,.029.312.465.465,0,0,0,.087.193.312.312,0,0,0,.145.1.641.641,0,0,0,.2.029.949.949,0,0,0,.326-.046l.172-.065.087.372a1.669,1.669,0,0,1-.237.084,1.34,1.34,0,0,1-.387.051,1.171,1.171,0,0,1-.423-.065.619.619,0,0,1-.268-.2.785.785,0,0,1-.143-.321,2.119,2.119,0,0,1-.041-.442v-2.242l.45-.078Z" transform="translate(-338.089 -106.066)" fill="#fff"/>
        <path id="Path_432" data-name="Path 432" d="M358.3,117.513q-.154.039-.408.082a3.511,3.511,0,0,1-.587.044,1.239,1.239,0,0,1-.488-.085.787.787,0,0,1-.319-.239.979.979,0,0,1-.174-.365,1.9,1.9,0,0,1-.053-.466v-1.411h.45v1.315a1.14,1.14,0,0,0,.145.657.565.565,0,0,0,.488.2c.048,0,.1,0,.15,0s.1-.007.145-.012l.123-.015a.37.37,0,0,0,.08-.017v-2.122h.449Z" transform="translate(-342.602 -107.907)" fill="#fff"/>
        <path id="Path_433" data-name="Path 433" d="M366.517,115.072q.058,0,.133.007c.05,0,.1.011.147.019l.133.024a.788.788,0,0,1,.09.022l-.078.391a1.484,1.484,0,0,0-.176-.046,1.554,1.554,0,0,0-.317-.027,1.183,1.183,0,0,0-.249.027l-.162.036v2.112h-.45v-2.407a3.031,3.031,0,0,1,.4-.109A2.532,2.532,0,0,1,366.517,115.072Z" transform="translate(-349.137 -107.907)" fill="#fff"/>
        <path id="Path_434" data-name="Path 434" d="M372.153,114.878a1.308,1.308,0,0,1,.457.07.742.742,0,0,1,.3.2.756.756,0,0,1,.162.3,1.471,1.471,0,0,1,.048.389v1.571l-.162.027q-.1.017-.234.032t-.283.027c-.1.008-.2.012-.3.012a1.645,1.645,0,0,1-.392-.044.88.88,0,0,1-.309-.138.633.633,0,0,1-.2-.249.871.871,0,0,1-.072-.372.714.714,0,0,1,.085-.358.67.67,0,0,1,.229-.242,1.063,1.063,0,0,1,.338-.135,1.831,1.831,0,0,1,.406-.044c.045,0,.092,0,.14.007s.094.011.138.019l.114.022.067.014v-.126a1.013,1.013,0,0,0-.024-.22.5.5,0,0,0-.087-.193.441.441,0,0,0-.172-.135.674.674,0,0,0-.283-.051,2.159,2.159,0,0,0-.389.031,1.4,1.4,0,0,0-.249.065l-.053-.372a1.4,1.4,0,0,1,.29-.075A2.5,2.5,0,0,1,372.153,114.878Zm.039,2.252c.106,0,.2,0,.283-.007a1.227,1.227,0,0,0,.205-.027v-.749a.564.564,0,0,0-.157-.041,1.832,1.832,0,0,0-.479,0,.672.672,0,0,0-.208.061.442.442,0,0,0-.157.126.329.329,0,0,0-.063.21.361.361,0,0,0,.155.336A.805.805,0,0,0,372.192,117.13Z" transform="translate(-353.047 -107.771)" fill="#fff"/>
        <path id="Path_435" data-name="Path 435" d="M380.868,114.739a.859.859,0,0,1-.59-.179.705.705,0,0,1-.174-.527v-3.016l.449-.078v3.021a.717.717,0,0,0,.019.184.252.252,0,0,0,.063.116.275.275,0,0,0,.116.065,1.159,1.159,0,0,0,.178.036Z" transform="translate(-359.322 -105.008)" fill="#fff"/>
        <path id="Path_436" data-name="Path 436" d="M384.478,115.2c.1-.026.24-.053.411-.082a3.56,3.56,0,0,1,.59-.043,1.28,1.28,0,0,1,.5.084.78.78,0,0,1,.317.237.918.918,0,0,1,.167.365,2.122,2.122,0,0,1,.048.469v1.411h-.449v-1.315a2.127,2.127,0,0,0-.032-.4.669.669,0,0,0-.1-.266.412.412,0,0,0-.193-.147.847.847,0,0,0-.3-.046c-.048,0-.1,0-.15,0s-.1.007-.147.012-.089.01-.126.017l-.08.014v2.122h-.45Z" transform="translate(-362.39 -107.907)" fill="#fff"/>
        <path id="Path_437" data-name="Path 437" d="M393.226,116.169a1.6,1.6,0,0,1,.1-.582,1.22,1.22,0,0,1,.256-.414,1.047,1.047,0,0,1,.368-.246,1.15,1.15,0,0,1,.425-.082.968.968,0,0,1,.778.317,1.456,1.456,0,0,1,.271.964v.075a.735.735,0,0,1,0,.084h-1.721a.922.922,0,0,0,.227.595.831.831,0,0,0,.618.2,1.649,1.649,0,0,0,.4-.041,1.425,1.425,0,0,0,.244-.08l.063.377a1.336,1.336,0,0,1-.288.092,2.041,2.041,0,0,1-.466.048,1.482,1.482,0,0,1-.568-.1,1.059,1.059,0,0,1-.394-.273,1.117,1.117,0,0,1-.229-.413A1.754,1.754,0,0,1,393.226,116.169Zm1.725-.246a.754.754,0,0,0-.152-.5.524.524,0,0,0-.433-.2.6.6,0,0,0-.273.06.645.645,0,0,0-.2.157.722.722,0,0,0-.128.222,1.116,1.116,0,0,0-.06.256Z" transform="translate(-368.528 -107.748)" fill="#fff"/>
        <path id="Path_438" data-name="Path 438" d="M406.395,112.341a.288.288,0,0,1-.205-.08.316.316,0,0,1,0-.43.3.3,0,0,1,.411,0,.316.316,0,0,1,0,.43A.29.29,0,0,1,406.395,112.341Zm.227,2.968h-.45v-2.513h.45Z" transform="translate(-377.565 -105.577)" fill="#fff"/>
        <path id="Path_439" data-name="Path 439" d="M414.107,112.447h.469v3.35h-.469Z" transform="translate(-383.179 -106.066)" fill="#fff"/>
        <path id="Path_440" data-name="Path 440" d="M418.286,115.2q.155-.038.411-.082a3.565,3.565,0,0,1,.59-.043,1.28,1.28,0,0,1,.5.084.778.778,0,0,1,.316.237.914.914,0,0,1,.167.365,2.133,2.133,0,0,1,.048.469v1.411h-.45v-1.315a2.153,2.153,0,0,0-.031-.4.675.675,0,0,0-.1-.266.411.411,0,0,0-.193-.147.849.849,0,0,0-.3-.046c-.048,0-.1,0-.15,0s-.1.007-.147.012-.089.01-.126.017l-.08.014v2.122h-.45Z" transform="translate(-386.112 -107.907)" fill="#fff"/>
        <path id="Path_441" data-name="Path 441" d="M428.627,117.763q-.135-.348-.287-.8t-.278-.93q-.126.483-.275.93t-.285.8h-.392q-.222-.5-.435-1.136t-.4-1.377h.483q.048.227.116.493t.143.531q.075.266.157.512t.155.43q.082-.227.162-.481t.152-.513q.072-.258.138-.507t.113-.466h.372q.043.218.106.466t.135.507q.072.259.152.513t.162.481q.073-.183.152-.43t.157-.512q.077-.266.145-.531t.116-.493h.464q-.189.74-.4,1.377t-.435,1.136Z" transform="translate(-391.716 -108.032)" fill="#fff"/>
        <path id="Path_442" data-name="Path 442" d="M439.314,116.169a1.6,1.6,0,0,1,.1-.582,1.22,1.22,0,0,1,.256-.414,1.047,1.047,0,0,1,.368-.246,1.15,1.15,0,0,1,.425-.082.968.968,0,0,1,.778.317,1.457,1.457,0,0,1,.271.964v.075a.735.735,0,0,1,0,.084h-1.721a.918.918,0,0,0,.227.595.831.831,0,0,0,.618.2,1.649,1.649,0,0,0,.4-.041,1.423,1.423,0,0,0,.244-.08l.063.377a1.333,1.333,0,0,1-.288.092,2.042,2.042,0,0,1-.466.048,1.483,1.483,0,0,1-.568-.1,1.058,1.058,0,0,1-.394-.273,1.116,1.116,0,0,1-.229-.413A1.752,1.752,0,0,1,439.314,116.169Zm1.725-.246a.753.753,0,0,0-.152-.5.524.524,0,0,0-.433-.2.6.6,0,0,0-.273.06.645.645,0,0,0-.2.157.722.722,0,0,0-.128.222,1.1,1.1,0,0,0-.06.256Z" transform="translate(-400.866 -107.748)" fill="#fff"/>
        <path id="Path_443" data-name="Path 443" d="M448.889,117.1a.884.884,0,0,0,.408-.072.245.245,0,0,0,.133-.232.309.309,0,0,0-.13-.261,2.069,2.069,0,0,0-.43-.218c-.1-.039-.189-.078-.278-.118a.978.978,0,0,1-.23-.143.622.622,0,0,1-.154-.2.633.633,0,0,1-.058-.285.64.64,0,0,1,.246-.529,1.051,1.051,0,0,1,.672-.2,1.952,1.952,0,0,1,.213.012c.071.008.137.018.2.029s.115.023.162.036.083.024.109.034l-.082.387a1.116,1.116,0,0,0-.227-.08,1.466,1.466,0,0,0-.372-.041.69.69,0,0,0-.329.075.248.248,0,0,0-.14.234.323.323,0,0,0,.032.145.336.336,0,0,0,.1.113.778.778,0,0,0,.162.094q.1.043.232.092.179.068.319.133a1.008,1.008,0,0,1,.239.152.585.585,0,0,1,.152.21.762.762,0,0,1,.053.3.6.6,0,0,1-.259.527,1.286,1.286,0,0,1-.737.179,1.916,1.916,0,0,1-.522-.056,2.691,2.691,0,0,1-.256-.084l.082-.387q.077.029.246.087A1.427,1.427,0,0,0,448.889,117.1Z" transform="translate(-407.038 -107.748)" fill="#fff"/>
        <path id="Path_444" data-name="Path 444" d="M456.255,113.225h.952v.377h-.952v1.16a1.386,1.386,0,0,0,.029.312.473.473,0,0,0,.087.193.313.313,0,0,0,.145.1.64.64,0,0,0,.2.029.95.95,0,0,0,.326-.046l.172-.065.087.372a1.639,1.639,0,0,1-.237.084,1.336,1.336,0,0,1-.386.051,1.171,1.171,0,0,1-.423-.065.619.619,0,0,1-.268-.2.782.782,0,0,1-.143-.321,2.115,2.115,0,0,1-.041-.442v-2.242l.45-.078Z" transform="translate(-412.437 -106.066)" fill="#fff"/>
        <path id="Path_445" data-name="Path 445" d="M461.153,118.232a.832.832,0,0,0,.138.046.745.745,0,0,0,.582-.1,1.02,1.02,0,0,0,.271-.384q-.3-.58-.568-1.23a10.747,10.747,0,0,1-.437-1.317h.483q.053.218.128.469t.167.517q.092.266.2.532t.222.512q.184-.507.319-1.005t.256-1.025h.464q-.174.711-.387,1.365t-.459,1.225a2.593,2.593,0,0,1-.2.375,1.12,1.12,0,0,1-.229.258.842.842,0,0,1-.283.15,1.22,1.22,0,0,1-.355.048.855.855,0,0,1-.111-.007c-.039,0-.077-.011-.114-.019s-.071-.017-.1-.027a.518.518,0,0,1-.065-.024Z" transform="translate(-416.132 -108.032)" fill="#fff"/>
        <path id="Path_446" data-name="Path 446" d="M471.066,117.5a1.368,1.368,0,0,1-.534-.1,1.077,1.077,0,0,1-.387-.271,1.123,1.123,0,0,1-.234-.413,1.714,1.714,0,0,1-.078-.529,1.6,1.6,0,0,1,.085-.532,1.253,1.253,0,0,1,.239-.418,1.073,1.073,0,0,1,.38-.275,1.223,1.223,0,0,1,.5-.1,2.026,2.026,0,0,1,.338.029,1.577,1.577,0,0,1,.324.092l-.1.382a1.078,1.078,0,0,0-.234-.077,1.322,1.322,0,0,0-.283-.029.715.715,0,0,0-.578.237,1.043,1.043,0,0,0-.2.691,1.412,1.412,0,0,0,.046.372.761.761,0,0,0,.145.29.641.641,0,0,0,.254.186.965.965,0,0,0,.377.065,1.42,1.42,0,0,0,.324-.034,1.18,1.18,0,0,0,.227-.072l.063.377a.473.473,0,0,1-.111.046,1.548,1.548,0,0,1-.164.038c-.061.011-.127.021-.2.029A1.8,1.8,0,0,1,471.066,117.5Z" transform="translate(-422.28 -107.759)" fill="#fff"/>
        <path id="Path_447" data-name="Path 447" d="M476.752,118.232a.844.844,0,0,0,.138.046.663.663,0,0,0,.167.022.654.654,0,0,0,.416-.118,1.017,1.017,0,0,0,.271-.384q-.3-.58-.568-1.23a10.639,10.639,0,0,1-.437-1.317h.483q.053.218.128.469t.166.517q.092.266.2.532t.222.512q.183-.507.319-1.005t.256-1.025h.464q-.174.711-.387,1.365t-.459,1.225a2.618,2.618,0,0,1-.2.375,1.127,1.127,0,0,1-.23.258.84.84,0,0,1-.283.15,1.216,1.216,0,0,1-.355.048.847.847,0,0,1-.111-.007c-.039,0-.077-.011-.114-.019s-.071-.017-.1-.027a.51.51,0,0,1-.065-.024Z" transform="translate(-427.077 -108.032)" fill="#fff"/>
        <path id="Path_448" data-name="Path 448" d="M483.812,116.212a1.013,1.013,0,0,1-.15-.014.789.789,0,0,1-.155-.039l.058-.367a.869.869,0,0,0,.111.024.75.75,0,0,0,.116.01.375.375,0,0,0,.331-.143.734.734,0,0,0,.1-.418v-2.47h.449v2.465a1.019,1.019,0,0,1-.22.718A.837.837,0,0,1,483.812,116.212Zm.633-3.871a.288.288,0,0,1-.206-.08.316.316,0,0,1,0-.43.3.3,0,0,1,.411,0,.316.316,0,0,1,0,.43A.289.289,0,0,1,484.445,112.341Z" transform="translate(-431.874 -105.577)" fill="#fff"/>
        <path id="Path_449" data-name="Path 449" d="M489.922,115.2q.155-.038.411-.082a3.563,3.563,0,0,1,.59-.043,1.279,1.279,0,0,1,.5.084.778.778,0,0,1,.317.237.915.915,0,0,1,.167.365,2.117,2.117,0,0,1,.048.469v1.411h-.45v-1.315a2.18,2.18,0,0,0-.031-.4.674.674,0,0,0-.1-.266.411.411,0,0,0-.194-.147.843.843,0,0,0-.3-.046c-.049,0-.1,0-.15,0s-.1.007-.148.012-.089.01-.126.017l-.08.014v2.122h-.45Z" transform="translate(-436.375 -107.907)" fill="#fff"/>
        <path id="Path_450" data-name="Path 450" d="M498.669,116.169a1.612,1.612,0,0,1,.1-.582,1.216,1.216,0,0,1,.256-.414,1.043,1.043,0,0,1,.367-.246,1.151,1.151,0,0,1,.425-.082.968.968,0,0,1,.778.317,1.456,1.456,0,0,1,.271.964v.075a.657.657,0,0,1-.005.084h-1.721a.92.92,0,0,0,.227.595.831.831,0,0,0,.618.2,1.647,1.647,0,0,0,.4-.041,1.429,1.429,0,0,0,.244-.08l.063.377a1.341,1.341,0,0,1-.288.092,2.045,2.045,0,0,1-.466.048,1.482,1.482,0,0,1-.568-.1,1.058,1.058,0,0,1-.394-.273,1.113,1.113,0,0,1-.23-.413A1.753,1.753,0,0,1,498.669,116.169Zm1.725-.246a.755.755,0,0,0-.152-.5.524.524,0,0,0-.433-.2.6.6,0,0,0-.273.06.644.644,0,0,0-.2.157.71.71,0,0,0-.128.222,1.084,1.084,0,0,0-.06.256Z" transform="translate(-442.512 -107.748)" fill="#fff"/>
      </g>
    </g>
    <g id="Group_295" data-name="Group 295" transform="translate(126.847 42.201)">
      <g id="Group_294" data-name="Group 294">
        <path id="Path_451" data-name="Path 451" d="M330.714,68.982a2.391,2.391,0,0,1-.894-.15,1.648,1.648,0,0,1-.619-.421,1.715,1.715,0,0,1-.362-.637,2.621,2.621,0,0,1-.118-.806V63.8h.93V66.88a1.986,1.986,0,0,0,.077.59,1.028,1.028,0,0,0,.22.4.876.876,0,0,0,.337.227,1.226,1.226,0,0,0,.436.073,1.255,1.255,0,0,0,.439-.073.867.867,0,0,0,.34-.227,1.024,1.024,0,0,0,.22-.4,1.987,1.987,0,0,0,.077-.59V63.8h.93v3.171a2.557,2.557,0,0,1-.121.806,1.76,1.76,0,0,1-.366.637,1.641,1.641,0,0,1-.626.421A2.449,2.449,0,0,1,330.714,68.982Z" transform="translate(-328.722 -63.475)" fill="#fff"/>
        <path id="Path_452" data-name="Path 452" d="M345.808,68.249a6.015,6.015,0,0,1,.666-.139,5.759,5.759,0,0,1,.908-.066,2.091,2.091,0,0,1,.784.128,1.221,1.221,0,0,1,.5.359,1.372,1.372,0,0,1,.264.557,3.122,3.122,0,0,1,.077.714v2.153h-.886V69.941a2.91,2.91,0,0,0-.04-.524.927.927,0,0,0-.132-.351.527.527,0,0,0-.249-.2,1.052,1.052,0,0,0-.385-.062,2.961,2.961,0,0,0-.352.022c-.122.015-.212.027-.271.037v3.091h-.886Z" transform="translate(-340.71 -66.455)" fill="#fff"/>
        <path id="Path_453" data-name="Path 453" d="M360.768,63.267a.507.507,0,0,1-.161.4.546.546,0,0,1-.381.146.555.555,0,0,1-.388-.146.508.508,0,0,1-.161-.4.518.518,0,0,1,.161-.4.557.557,0,0,1,.388-.146.547.547,0,0,1,.381.146A.517.517,0,0,1,360.768,63.267Zm-.095,4.951h-.886v-3.83h.886Z" transform="translate(-350.441 -62.717)" fill="#fff"/>
        <path id="Path_454" data-name="Path 454" d="M367.285,67.75a2.172,2.172,0,0,1,.74.11,1.179,1.179,0,0,1,.48.308,1.157,1.157,0,0,1,.256.48,2.359,2.359,0,0,1,.077.619v2.322q-.2.044-.619.106a6.327,6.327,0,0,1-.934.062,2.81,2.81,0,0,1-.63-.066,1.326,1.326,0,0,1-.487-.212,1,1,0,0,1-.315-.381,1.32,1.32,0,0,1-.113-.579,1.12,1.12,0,0,1,.128-.557,1.053,1.053,0,0,1,.348-.37,1.519,1.519,0,0,1,.509-.2,2.871,2.871,0,0,1,.6-.062,2.72,2.72,0,0,1,.308.018,2.888,2.888,0,0,1,.344.062v-.146a1.156,1.156,0,0,0-.036-.293.609.609,0,0,0-.128-.245.6.6,0,0,0-.242-.165,1.046,1.046,0,0,0-.377-.059,3.351,3.351,0,0,0-.564.044,2.47,2.47,0,0,0-.418.1l-.11-.718a3.486,3.486,0,0,1,.491-.117A3.846,3.846,0,0,1,367.285,67.75Zm.073,3.3a3.271,3.271,0,0,0,.622-.044v-.981a1.719,1.719,0,0,0-.212-.044,1.981,1.981,0,0,0-.308-.022,2.055,2.055,0,0,0-.3.022.924.924,0,0,0-.271.081.493.493,0,0,0-.194.165.46.46,0,0,0-.073.267.469.469,0,0,0,.2.436A1.02,1.02,0,0,0,367.359,71.053Z" transform="translate(-354.696 -66.248)" fill="#fff"/>
        <path id="Path_455" data-name="Path 455" d="M385.329,69.217V64.141h3.259v.784h-2.336v1.252h2.08v.769h-2.08v1.487h2.512v.784Z" transform="translate(-368.44 -63.716)" fill="#fff"/>
        <path id="Path_456" data-name="Path 456" d="M402.041,71.757q-.256.066-.667.135a5.387,5.387,0,0,1-.9.07,1.984,1.984,0,0,1-.773-.132,1.235,1.235,0,0,1-.5-.366,1.444,1.444,0,0,1-.268-.56,2.986,2.986,0,0,1-.08-.714V68.044h.886v2.007a1.621,1.621,0,0,0,.179.879.7.7,0,0,0,.627.264q.161,0,.34-.015a1.817,1.817,0,0,0,.267-.037v-3.1h.886Z" transform="translate(-377.931 -66.455)" fill="#fff"/>
        <path id="Path_457" data-name="Path 457" d="M415.349,68.923a2.771,2.771,0,0,0-.3-.077,2.235,2.235,0,0,0-.451-.041,1.828,1.828,0,0,0-.311.029,1.9,1.9,0,0,0-.231.051v3.069h-.886V68.308a4.858,4.858,0,0,1,.641-.179,4,4,0,0,1,.853-.084c.059,0,.127,0,.205.011s.156.017.234.029.154.027.227.044.132.033.176.047Z" transform="translate(-387.972 -66.455)" fill="#fff"/>
        <path id="Path_458" data-name="Path 458" d="M425.876,69.683a2.477,2.477,0,0,1-.132.827,1.833,1.833,0,0,1-.374.637,1.676,1.676,0,0,1-.582.41,1.881,1.881,0,0,1-.75.146,1.854,1.854,0,0,1-.747-.146,1.688,1.688,0,0,1-.579-.41,1.873,1.873,0,0,1-.377-.637,2.409,2.409,0,0,1-.136-.827,2.378,2.378,0,0,1,.136-.824,1.85,1.85,0,0,1,.381-.634,1.681,1.681,0,0,1,.582-.406,1.877,1.877,0,0,1,.74-.143,1.9,1.9,0,0,1,.743.143,1.639,1.639,0,0,1,.582.406,1.89,1.89,0,0,1,.377.634A2.386,2.386,0,0,1,425.876,69.683Zm-.908,0a1.5,1.5,0,0,0-.245-.9.87.87,0,0,0-1.37,0,1.5,1.5,0,0,0-.245.9,1.527,1.527,0,0,0,.245.915.865.865,0,0,0,1.37,0A1.528,1.528,0,0,0,424.968,69.683Z" transform="translate(-394.31 -66.196)" fill="#fff"/>
        <path id="Path_459" data-name="Path 459" d="M440.894,70.044a2.706,2.706,0,0,1-.114.806,1.875,1.875,0,0,1-.326.63,1.445,1.445,0,0,1-.531.41,1.709,1.709,0,0,1-.721.146,1.785,1.785,0,0,1-.5-.066,1.85,1.85,0,0,1-.374-.146V73.31h-.886V68.249q.271-.073.667-.139a5.086,5.086,0,0,1,.835-.066,2.235,2.235,0,0,1,.813.139,1.7,1.7,0,0,1,.612.4,1.755,1.755,0,0,1,.388.63A2.4,2.4,0,0,1,440.894,70.044Zm-.908.015a1.428,1.428,0,0,0-.267-.923,1.044,1.044,0,0,0-.86-.33c-.083,0-.17,0-.26.011a1.436,1.436,0,0,0-.267.048v2.19a1.357,1.357,0,0,0,.311.15,1.215,1.215,0,0,0,.414.07.792.792,0,0,0,.707-.33A1.556,1.556,0,0,0,439.985,70.058Z" transform="translate(-405.006 -66.455)" fill="#fff"/>
        <path id="Path_460" data-name="Path 460" d="M451.215,69.73a2.4,2.4,0,0,1,.15-.886,1.854,1.854,0,0,1,.4-.633,1.652,1.652,0,0,1,.571-.381,1.77,1.77,0,0,1,.659-.128,1.579,1.579,0,0,1,1.234.49,2.124,2.124,0,0,1,.443,1.465c0,.049,0,.1,0,.165s-.006.116-.011.165H452.13a1.01,1.01,0,0,0,.326.714,1.237,1.237,0,0,0,.839.253,2.733,2.733,0,0,0,.589-.059,2.4,2.4,0,0,0,.421-.124l.117.725a1.388,1.388,0,0,1-.2.077,2.813,2.813,0,0,1-.293.073q-.165.033-.355.055a3.382,3.382,0,0,1-.388.022,2.347,2.347,0,0,1-.879-.15,1.632,1.632,0,0,1-.615-.417,1.708,1.708,0,0,1-.359-.63A2.571,2.571,0,0,1,451.215,69.73Zm2.571-.4a1.16,1.16,0,0,0-.051-.348.842.842,0,0,0-.15-.286.716.716,0,0,0-.242-.19.772.772,0,0,0-.341-.07.791.791,0,0,0-.359.077.819.819,0,0,0-.26.2.926.926,0,0,0-.165.286,1.545,1.545,0,0,0-.081.33Z" transform="translate(-414.669 -66.214)" fill="#fff"/>
        <path id="Path_461" data-name="Path 461" d="M462.808,69.58q-.1,0-.267-.019a1.18,1.18,0,0,1-.3-.07l.117-.725a1.285,1.285,0,0,0,.388.051.489.489,0,0,0,.432-.179.95.95,0,0,0,.124-.539V64.387h.886v3.728a1.517,1.517,0,0,1-.355,1.106A1.374,1.374,0,0,1,462.808,69.58Zm1.472-6.313a.508.508,0,0,1-.161.4.547.547,0,0,1-.381.146.556.556,0,0,1-.388-.146.508.508,0,0,1-.161-.4.518.518,0,0,1,.161-.4.558.558,0,0,1,.388-.146.548.548,0,0,1,.381.146A.518.518,0,0,1,464.28,63.267Z" transform="translate(-422.402 -62.717)" fill="#fff"/>
        <path id="Path_462" data-name="Path 462" d="M472.67,70.989a1.166,1.166,0,0,0,.513-.084.3.3,0,0,0,.161-.289.376.376,0,0,0-.172-.315,2.539,2.539,0,0,0-.568-.271,4.494,4.494,0,0,1-.443-.187,1.513,1.513,0,0,1-.348-.231.947.947,0,0,1-.231-.319,1.112,1.112,0,0,1-.084-.458,1,1,0,0,1,.388-.832,1.668,1.668,0,0,1,1.054-.3,3.266,3.266,0,0,1,.644.062,3.132,3.132,0,0,1,.462.121l-.161.718a2.189,2.189,0,0,0-.374-.121,2.256,2.256,0,0,0-.527-.055.918.918,0,0,0-.439.092.3.3,0,0,0-.169.282.408.408,0,0,0,.033.168.361.361,0,0,0,.114.135,1.143,1.143,0,0,0,.212.125,3.311,3.311,0,0,0,.322.128,4.86,4.86,0,0,1,.535.231,1.448,1.448,0,0,1,.363.256.878.878,0,0,1,.209.326,1.3,1.3,0,0,1,.066.439.947.947,0,0,1-.407.832,2.026,2.026,0,0,1-1.161.282,3.169,3.169,0,0,1-.813-.084,4.19,4.19,0,0,1-.432-.135l.154-.74a3.954,3.954,0,0,0,.472.154A2.43,2.43,0,0,0,472.67,70.989Z" transform="translate(-428.844 -66.214)" fill="#fff"/>
        <path id="Path_463" data-name="Path 463" d="M484.357,66.88q.169-.176.359-.377t.374-.4q.183-.2.348-.388t.282-.326h1.047q-.366.41-.773.849t-.817.857a5.124,5.124,0,0,1,.461.443q.242.26.469.553t.425.586a5.924,5.924,0,0,1,.329.542h-1.025q-.132-.227-.3-.476t-.37-.483q-.2-.234-.4-.443a3.434,3.434,0,0,0-.4-.355v1.758h-.886V64.287l.886-.146Z" transform="translate(-437.301 -63.716)" fill="#fff"/>
        <path id="Path_464" data-name="Path 464" d="M497.486,67.75a2.172,2.172,0,0,1,.74.11,1.18,1.18,0,0,1,.48.308,1.153,1.153,0,0,1,.256.48,2.346,2.346,0,0,1,.077.619v2.322q-.2.044-.619.106a6.328,6.328,0,0,1-.934.062,2.81,2.81,0,0,1-.63-.066,1.327,1.327,0,0,1-.487-.212,1,1,0,0,1-.315-.381,1.32,1.32,0,0,1-.114-.579,1.12,1.12,0,0,1,.128-.557,1.057,1.057,0,0,1,.348-.37,1.522,1.522,0,0,1,.509-.2,2.871,2.871,0,0,1,.6-.062,2.719,2.719,0,0,1,.308.018,2.887,2.887,0,0,1,.344.062v-.146a1.157,1.157,0,0,0-.036-.293.612.612,0,0,0-.128-.245.6.6,0,0,0-.242-.165,1.046,1.046,0,0,0-.377-.059,3.35,3.35,0,0,0-.564.044,2.478,2.478,0,0,0-.418.1l-.11-.718a3.485,3.485,0,0,1,.491-.117A3.845,3.845,0,0,1,497.486,67.75Zm.073,3.3a3.272,3.272,0,0,0,.622-.044v-.981a1.724,1.724,0,0,0-.212-.044,1.987,1.987,0,0,0-.308-.022,2.055,2.055,0,0,0-.3.022.923.923,0,0,0-.271.081.492.492,0,0,0-.194.165.458.458,0,0,0-.073.267.469.469,0,0,0,.2.436A1.021,1.021,0,0,0,497.56,71.053Z" transform="translate(-446.051 -66.248)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg>

  )
};

interface IIcon {
  name:
    | "position"
    | "checked"
    | "search"
    | "arrow"
    | "print"
    | "download"
    | "save"
    | "qr"
    | "show"
    | "hide"
    | "pin"
    | "search_bar"
    | "cam_frame"
    | "close_background"
    | "close"
    | "flash_light"
    | "my_position"
    | "menu"
    | "select_campus"
    | "add_to_fav"
    | "copy_url"
    | "distance"
    | "options"
    | "events"
    | "fav_points"
    | "hide_menu"
    | "log_in"
    | "log_out"
    | "finish"
    | "plus"
    | "minus"
    | "door"
    | "elevator"
    | "stairs"
    | "navigate"
    | "start"
    | "disabled"
    | "ue";
}

const Icon: FunctionComponent<IIcon> = ({ name }) => (
  <span className="icon">{ICONS[name]}</span>
);

export default Icon;
