import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  DefaultApiResult,
  UserInterface,
} from "../../redux/reducers";
import { Dispatch } from "redux";
import { fetchCampuses } from "../../redux/campus/actions";
import { fetchMaps } from "../../redux/maps/actions";
import { IMap, ICampus, IBuilding } from "../../interfaces";
import Box from "../../components/Box";
import DataList from "../../components/FormElements/DataList";
import { GeolocationContext } from "../../contexts/GeolocationContext";
import { getDistance } from "../../services/positions";

const Campuses: FunctionComponent = () => {
  const userLocation = useContext(GeolocationContext);
  const isResolved = userLocation?.status === "resolved";
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(undefined);

  const dispatch: Dispatch = useDispatch();

  const campuses: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Campuses
  );

  const user: UserInterface = useSelector<RootState, UserInterface>(
    (state) => state.User
  );

  const maps: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Maps
  );

  const buildings: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Buildings
  );

  const calculateDistance = useCallback(
    (mapId: number) => {
      if (
        buildings?.data?.data?.length > 0 &&
        isResolved &&
        userLocation?.position &&
        mapId
      ) {
        const coords = buildings?.data?.data
          .filter((building: IBuilding) => building?.map_id === mapId)
          ?.map((building: any) => building.coordinates.coordinates.flat(2))
          .flat(1);

        return getDistance(
          coords,
          userLocation,
          "Znajdujesz się na tym kampusie"
        );
      }
    },
    [buildings, isResolved, userLocation]
  );

  // Join campus data with map data for that campus
  useEffect(() => {
    if (maps?.data?.data && campuses?.data?.data) {
      const campusData = campuses?.data?.data;
      const mapData = maps?.data?.data;
      const mutData = campusData.map((campus: ICampus) => {
        return {
          ...campus,
          image: mapData?.find((map: IMap) => map.id === campus?.map_id)?.image,
          json_coordinates: mapData?.find(
            (map: IMap) => map?.id === campus?.map_id
          )?.json_coordinates,
          distance: calculateDistance(campus.map_id),
        };
      });

      if (
        user?.selectedCampus &&
        campuses.byId[user.selectedCampus] &&
        mutData
      ) {
        const campusId = user?.selectedCampus
          ? campuses?.byId[user.selectedCampus].data.id
          : null;

        setSelectedData(
          mutData.find((campus: ICampus) => campus.id === campusId)
        );
        setData(mutData.filter((campus: ICampus) => campus.id !== campusId));
      } else {
        setData(mutData);
      }
    }
  }, [maps, campuses, user, calculateDistance]);

  useEffect(() => {
    !campuses?.data?.data && !campuses?.loading && dispatch(fetchCampuses());
    !maps?.data?.data && !maps?.loading && dispatch(fetchMaps());
  }, [dispatch, campuses, maps]);

  const loading =
    campuses?.loading ||
    (user.selectedCampus && campuses?.byId[user.selectedCampus]?.loading) ||
    maps?.loading ||
    buildings?.loading;

  return (
    <section className="container element element--left element--full">
      <div className="campuses">
        <Box loading={loading} title="Wybierz kampus" link="/campuses">
          <>
            {(data?.length > 0 || selectedData) && (
              <DataList
                selectedCampus={selectedData || undefined}
                data={data}
                loading={campuses.loading || maps.loading}
                container={"campus"}
                link={"campus"}
                msg={"Nie odnaleziono kampusów"}
              />
            )}
          </>
        </Box>
      </div>
    </section>
  );
};

export default Campuses;
