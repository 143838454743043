import React, {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/splash_login/logo_blue.png";
import Input from "../../components/FormElements/Input";
import Button from "../../components/FormElements/Button";

interface ILogin {
  children?: React.ReactElement[]; // or JSX.Element[]
}

const Login: FunctionComponent<ILogin> = ({ children }) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const isDisabled = useMemo(() => login.length < 3 || password.length < 3, [
    login,
    password,
  ]);

  const handleSubmit = useCallback(() => {
    console.log(login, password);
  }, [login, password]);

  return (
    <div className="login">
      <div className="container">
        <div className="login__container">
          <div className="login__logo">
            <img src={logo} alt="Politechnika Śląska" />
          </div>

          <div className="login__content">
            <div className="row">
              <div className="col-24">
                <h1 className="login__title text-center">
                  Wprowadź swój identyfikator sieciowy i hasło
                </h1>
              </div>
              <div className="col-24">
                <Input
                  type="email"
                  changeValue={(e) => setLogin(e)}
                  value={login}
                  placeholder="Login"
                  className="with-icon"
                />
              </div>
              <div className="col-24">
                <Input
                  type="password"
                  changeValue={(e) => setPassword(e)}
                  value={password}
                  placeholder="Hasło"
                  className="with-icon"
                />
              </div>
              {isDisabled && (login.length > 0 || password.length > 0) && (
                <span className="text-center">
                  Oba pola powinny zawierać co najmniej 3 litery.
                </span>
              )}
              <div className="col-24">
                <Button
                  onClick={handleSubmit}
                  disabled={isDisabled}
                  className="button-yellow"
                >
                  Zaloguj
                </Button>
              </div>
            </div>

            <div className="row">
              <div className="col-24">
                <Link
                  aria-label="Pomiń logowanie"
                  className="link text-center"
                  to="/"
                >
                  Pomiń narazie
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
