import React, { FunctionComponent } from "react";

interface IButton {
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactElement[] | string | React.ReactElement;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;
  yellow_btn?: boolean;
}

const Button: FunctionComponent<IButton> = ({
  className,
  loading = false,
  disabled = false,
  children,
  onClick,
  tooltip,
}) => {
  return (
    <button
      disabled={!!disabled}
      aria-label="Left Align"
      className={[
        "button",
        loading && "loading",
        disabled && "disabled",
        className && className,
      ].join(" ")}
      onClick={(e) => onClick && onClick(e)}
      data-tooltip={tooltip ? tooltip : null}
    >
      {children}
    </button>
  );
};

export default Button;
