import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { GeoJSON, Marker } from "react-leaflet";
import { IBuilding, IRoom, IFloor, IPoint } from "../../../interfaces";
import { fetchBuildings } from "../../../redux/buildings/actions";
import { fetchFloors } from "../../../redux/floors/actions";
import { fetchRooms } from "../../../redux/rooms/actions";
import { fetchEntrances } from "../../../redux/entrances/actions";
import { fetchPoints } from "../../../redux/points/actions";
import { fetchRoutes } from "../../../redux/routes/actions";
import { fetchExcludedAreas } from "../../../redux/excludedAreas/actions";
import { fetchSettings } from "../../../redux/settings/actions";
import { fetchEvents } from "../../../redux/events/actions";
import { RootState, DefaultApiResult } from "../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getCenter } from "../../../services/positions";
import L from "leaflet";

interface IGeojsonWrapper {
  buildingId: number | string | "";
  floorId: number | string | "";
  roomId: number | string | "";
  pointId: number | string | "";
  showDetails: boolean;
  loading: boolean;
  start: IPoint;
  startFloor?: number;
  startColor?: String;
  endColor?: String;
  startIcon?: String;
  endIcon?: String;
  selectedColor: String;
  isPath: boolean;
}

const GeojsonWrapper: FunctionComponent<IGeojsonWrapper> = ({
  buildingId,
  floorId,
  roomId,
  pointId,
  showDetails,
  loading,
  start,
  startFloor,
  startColor,
  endColor,
  startIcon,
  endIcon,
  selectedColor,
  isPath,
}) => {
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();

  const allBuildings: DefaultApiResult = useSelector<
    RootState,
    DefaultApiResult
  >((state) => state.Buildings);

  const allFloors: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Floors
  );

  const allRooms: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Rooms
  );

  const onGeojsonClick = (geojson: any, e?: any) => {
    switch (geojson?.type) {
      case "building":
        history.push(`/building/${geojson.id}`);
        break;
      case "room":
        history.push(`/room/${geojson.id}?floor=${geojson.floor_id}`);
        break;
      case "floor":
        const type = pointId ? "point" : "room";
        const id = pointId ? pointId : roomId;
        if (isPath) {
          L.DomEvent.stopPropagation(e);
          history.push(
            `/paths/${type}-${id}/floor-${geojson.id}-${e?.latlng?.lat}-${e?.latlng?.lng}?floor=${floorId}`
          );
        }
        break;
      default:
        break;
    }
  };

  const getCurrentIcon = (data: any) => {
    let nr =
      data?.num?.split("_")?.length > 0
        ? data?.num?.split("_")[2]
        : data.num || "-";

    nr = nr.substring(0, nr.length - 1);
    const name = nr?.length > 4 ? "-" : nr;

    if (start && start?.type === data?.type && data?.id === start?.id) {
      return L.divIcon({
        iconSize: [0, 0],
        iconAnchor: [0, 0],
        popupAnchor: [0, 0],
        html: `<span style="background: ${startColor}" class="geojson__name geojson__name-selected">${startIcon}</span>`,
        bgPos: [0, 0],
      });
    }
    if (data?.type === "room" && data?.id === Number(roomId) && start) {
      return L.divIcon({
        iconSize: [0, 0],
        iconAnchor: [0, 0],
        popupAnchor: [0, 0],
        html: `<span style="background: ${endColor}" class="geojson__name geojson__name-selected">${endIcon}</span>`,
        bgPos: [0, 0],
      });
    } else if (data?.type === "room" && data?.id === Number(roomId)) {
      return L.divIcon({
        iconSize: [0, 0],
        iconAnchor: [0, 0],
        popupAnchor: [0, 0],
        html: `<span style="background: ${selectedColor}" class="geojson__name geojson__name-selected">${name}</span>`,
        bgPos: [0, 0],
      });
    } else {
      return L.divIcon({
        iconSize: [0, 0],
        iconAnchor: [0, 0],
        popupAnchor: [0, 0],
        html: `<span class="geojson__name">${name}</span>`,
        bgPos: [0, 0],
      });
    }
  };

  useEffect(() => {
    dispatch(fetchSettings());
    dispatch(fetchEvents());
    dispatch(fetchEntrances());
    dispatch(fetchBuildings());
    dispatch(fetchFloors());
    dispatch(fetchRooms());
    dispatch(fetchPoints());
    dispatch(fetchRoutes());
    dispatch(fetchExcludedAreas());
  }, [dispatch]);

  const floors = useMemo(
    () =>
      allFloors?.data?.data
        ?.map((floor: IFloor) => {
          return { ...floor, type: "floor" };
        })
        ?.filter(
          (floor: IFloor) =>
            floor.id === Number(floorId) || floor.id === startFloor
        ) || [],
    [allFloors, floorId, startFloor]
  );

  const buildings = useMemo(() => {
    const currentBuildings = allBuildings?.data?.data?.map(
      (building: IBuilding) => {
        return { ...building, type: "building" };
      }
    );
    if (floorId) {
      return (
        currentBuildings?.filter(
          (building: IBuilding) =>
            building.id !== Number(buildingId) &&
            building.id !== start?.building_id
        ) || []
      );
    } else {
      return currentBuildings || [];
    }
  }, [allBuildings, buildingId, start, floorId]);

  const rooms = useMemo(
    () =>
      allRooms?.data?.data
        ?.map((room: IRoom) => {
          return { ...room, type: "room" };
        })
        ?.filter(
          (room: IRoom) =>
            room.floor_id === Number(floorId) || room.floor_id === startFloor
        ) || [],
    [allRooms, floorId, startFloor]
  );

  const currentGeoLayers = useMemo(() => {
    return [...buildings, ...floors, ...rooms];
  }, [buildings, rooms, floors]);

  return (
    <>
      {currentGeoLayers &&
        !loading &&
        currentGeoLayers?.map(
          (geojson: any, index: number) =>
            geojson?.coordinates?.coordinates?.length > 0 && (
              <div className="geojson__wrapper" key={index}>
                <GeoJSON
                  key={Math.random()}
                  onClick={(e: any) =>
                    onGeojsonClick && onGeojsonClick(geojson, e)
                  }
                  data={geojson.coordinates}
                  style={{
                    fillColor:
                      geojson?.type !== "room"
                        ? "#fff"
                        : geojson?.map_color || "#000",

                    opacity: geojson?.type === "room" ? 0.6 : 1,
                    fillOpacity: geojson?.type === "room" ? 0.6 : 1,
                    color: geojson?.map_color || "#000",
                  }}
                ></GeoJSON>
                {geojson?.type === "room" && showDetails && (
                  <Marker
                    onClick={(e: any) =>
                      onGeojsonClick && onGeojsonClick(geojson, e)
                    }
                    key={Math.random()}
                    draggable={false}
                    icon={getCurrentIcon(geojson)}
                    position={{
                      lat: getCenter(geojson?.coordinates?.coordinates?.flat(2))
                        ?.lng,
                      lng: getCenter(geojson?.coordinates?.coordinates?.flat(2))
                        ?.lat,
                    }}
                  ></Marker>
                )}
              </div>
            )
        )}
    </>
  );
};
export default GeojsonWrapper;
