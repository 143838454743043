import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../scss/app.scss";
import Routes from "./routes";
import Header from "./components/Header";
import MapWrapper from "./components/MapElements/MapWrapper";
import useUuid from "./hooks/useUuid";
import ContextsWrapper from "./components/Contexts";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const App: React.FC = () => {
  const uuid = useUuid();

  const { pathname, search } = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.pageview(pathname + search);
    }
  }, [pathname, search]);

  // mobile fullscreen components
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );
  }, []);

  return (
    <main className="App">
      <ToastContainer closeOnClick={false} />
      <ContextsWrapper>
        <Header />
        <MapWrapper />
        <Routes />
      </ContextsWrapper>
    </main>
  );
};

export default App;
