import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import Box from "../../components/Box";
import DataList from "../../components/FormElements/DataList";

const Events: FunctionComponent = () => {
  const events: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Events
  );

  return (
    <section className="container element event element--full ">
      <div className="scrollable">
        <Box title="Wydarzenia" link="/events">
          <DataList
            data={events?.data?.data}
            loading={events?.loading}
            container={"event"}
            link={"event"}
            msg={"Brak wydarzeń w obrębie kampusu"}
          />
        </Box>
      </div>
    </section>
  );
};

export default Events;
