export const fetchBuildings = () => {
  return {
    type: "FETCH_BUILDINGS",
  };
};

export const getBuilding = (id: string | number | null) => {
  return {
    type: "GET_BUILDINGS",
    id,
  };
};
