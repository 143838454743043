import { createContext, SetStateAction, Dispatch } from "react";
import { IPoint } from "./../interfaces";
export interface IPath {
  start?: IPoint | null;
  destination?: IPoint | null;
}

export interface IPathContext {
  pathPoints: IPath | null;
  setPathPoints: Dispatch<SetStateAction<IPath | null>>;
}

export const PathContext = createContext<IPathContext>({
  pathPoints: {
    start: null,
    destination: null,
  },
  setPathPoints: (point) => {},
});
