import React, {
  FunctionComponent,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { GeoJSON } from "react-leaflet";
import { useSelector } from "react-redux";
import { IEntrance, IFloor, IRoute, IPoint } from "../../../interfaces";
import { RootState, DefaultApiResult } from "../../../redux/reducers";
import {
  findClosest,
  excludePaths,
  findPath,
  convertEntrances,
  getStartPointFromPath,
  getDestPointFromPath,
  getPointsDistance,
} from "../../../services/positions";
import L from "leaflet";
import {
  EntrancesContext,
  IEntrancesContext,
} from "../../../contexts/EntrancesContext";
interface IRoutes {
  map: L.Map;
  floor: string | false;
  routes: Array<IRoute>;
  entrances: any;
  destination: IPoint;
  start?: any;
  sameBuilding: boolean;
  type: string;
  color: string;
  excludedAreas: any;
  isExcluded?: boolean;
  handicapped: boolean;
  onShowPopup: (show: boolean) => void;
}

const Routes: FunctionComponent<IRoutes> = ({
  map,
  routes,
  floor,
  entrances,
  destination,
  start,
  sameBuilding,
  type,
  color,
  excludedAreas,
  handicapped,
  onShowPopup,
}) => {
  const { entrancesPoints, setEntrancesPoints } = useContext<IEntrancesContext>(
    EntrancesContext
  );

  const floors: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Floors
  );
  // ENTRANCE CLOSEST TO HALLWAY
  const checkIfHallway = useCallback((entrances) => {
    return entrances.find(
      (entrance: IEntrance) => entrance.type === "inside_hallway"
    );
  }, []);

  const buildingFloors = useMemo(() => {
    if (floors?.data?.data?.length > 0 && destination?.building_id) {
      return floors?.data?.data
        ?.filter(
          (floor: IFloor) => floor?.building_id === destination.building_id
        )
        ?.sort((a: IFloor, b: IFloor) => b.level - a.level);
    }
  }, [floors, destination]);

  const allDestinationEntrances = useMemo(() => {
    const all = entrances?.filter(
      (entrance: IEntrance) =>
        entrance?.room_id && entrance?.room_id === destination?.id
    );
    if (checkIfHallway(all)) {
      return all?.filter(
        (eEntrance: IEntrance) => eEntrance.type === "inside_hallway"
      );
    } else {
      return all;
    }
  }, [entrances, destination, checkIfHallway]);

  const allStartEntrances = useMemo(() => {
    if (start?.building_id && sameBuilding && !start?.lat) {
      const all = entrances?.filter(
        (entrance: IEntrance) =>
          entrance?.room_id && entrance?.room_id === start?.id
      );
      if (checkIfHallway(all)) {
        return all?.filter(
          (eEntrance: IEntrance) => eEntrance.type === "inside_hallway"
        );
      } else {
        return all;
      }
    }
  }, [start, sameBuilding, entrances, checkIfHallway]);

  // CURRENT SELECTED FLOOR
  const currentFloor = useMemo(() => {
    return buildingFloors?.find((e: IFloor) => e?.id === Number(floor));
  }, [floor, buildingFloors]);
  // ROUTES WITH EXCLUDED AREAS
  const excludedRoutes = useMemo(() => {
    if (routes?.length > 0) {
      return routes.map((route: IRoute) => {
        if (
          excludedAreas?.length > 0 &&
          excludedAreas?.find(
            (eArea: IEntrance) => eArea.floor_id === route.floor_id
          )?.coordinates?.length > 0 &&
          handicapped
        ) {
          return excludePaths(
            route,
            excludedAreas
              ?.filter((eArea: IEntrance) => eArea.floor_id === route.floor_id)
              ?.map((e: IEntrance) => e?.coordinates)
          );
        } else {
          return route;
        }
      });
    }
    return routes;
  }, [excludedAreas, routes, handicapped]);

  // GET FLOOR OBJECT BY ID
  const getFloor = useCallback(
    (data) => {
      if (buildingFloors && data) {
        return buildingFloors?.find(
          (floor: IFloor) => floor?.id === data?.floor_id
        );
      }
    },
    [buildingFloors]
  );

  // DESTINATION POINT FLOOR
  const destinationFloor = useMemo(() => getFloor(destination), [
    destination,
    getFloor,
  ]);

  // START POINT FLOOR
  const startFloor = useMemo(() => start && getFloor(start), [start, getFloor]);

  // CHECK IF CLOSEST DESTINATION POINT IS SAME AS CLOSEST START POINT

  const getCurrentFloorPaths = useCallback(
    (route: any, startP: any, destinationP: any) => {
      const convertedStart = convertEntrances(route, startP, map);
      const convertedDest = convertEntrances(route, destinationP, map);
      const dest = convertedDest?.map((point: IEntrance) => {
        return findClosest(
          map,
          route.coordinates.coordinates.flat(1),
          point?.closest?.latlng
        );
      });

      const start = convertedStart?.map((point: IEntrance) => {
        return findClosest(
          map,
          route.coordinates.coordinates.flat(1),
          point?.closest?.latlng
        );
      });

      if (
        start?.length > 0 &&
        dest?.length > 0 &&
        route?.coordinates?.coordinates
      ) {
        const allPaths = dest?.flatMap((dest: any) => {
          return start
            ?.map((start: any) => {
              return {
                path: findPath(route.coordinates.coordinates, start, dest),
                pathDistance: getPointsDistance(start, dest, map),
              };
            })
            ?.filter((e: any) => !!e.path);
        });
        return allPaths;
      }
    },
    [map]
  );

  //EXCLUDED PATHS
  const pathsByFloors = useMemo(() => {
    return buildingFloors
      ?.map((floor: IFloor, index: number) => {
        const currRoute = excludedRoutes?.find(
          (route: IRoute) => route?.floor_id === floor.id
        );

        const currExits = entrances?.filter(
          (entrance: IEntrance) =>
            entrance?.floor_id === floor.id && entrance.type === "outside"
        );

        const prevExits = entrances?.filter(
          (entrance: IEntrance) =>
            buildingFloors[index + 1] &&
            entrance?.floor_id === buildingFloors[index + 1]?.id &&
            entrance.type === "outside"
        );

        const currStairs = entrances?.filter(
          (entrance: IEntrance) =>
            entrance?.floor_id === floor.id &&
            (entrance.type === "stairs" || entrance.type === "elevator")
        );

        const hasExit = currExits?.length > 0;
        const prevExit = prevExits?.length > 0;

        const exitDownstairs = !!entrances
          ?.filter((entrance: IEntrance) => entrance.type === "outside")
          ?.find((exit: IEntrance) => getFloor(exit)?.level < floor?.level);

        const exitUpstairs = !!entrances
          ?.filter((entrance: IEntrance) => entrance.type === "outside")
          ?.find((exit: IEntrance) => getFloor(exit)?.level > floor?.level);

        if (start?.building_id && sameBuilding) {
          if (
            startFloor?.level === destinationFloor?.level &&
            destinationFloor?.level === floor?.level
          ) {
            const startPoints =
              allStartEntrances?.length > 0 ? allStartEntrances : [start];
            const destPoints =
              allDestinationEntrances?.length > 0
                ? allDestinationEntrances
                : [
                    {
                      ...destination,
                      lat: destination?.coordinates_x || null,
                      lng: destination?.coordinates_y || null,
                    },
                  ];

            const pathBetweenPoints = getCurrentFloorPaths(
              currRoute,
              startPoints,
              destPoints
            );

            if (startPoints?.length > 0 && destPoints?.length > 0) {
              if (pathBetweenPoints?.length < 1) {
                let currEntrances;
                if (hasExit) {
                  currEntrances = currExits;
                } else if (exitUpstairs) {
                  currEntrances = currStairs?.filter(
                    (stair: IEntrance) =>
                      stair?.direction === "up" || !stair?.direction
                  );
                } else {
                  currEntrances = currStairs?.filter(
                    (stair: IEntrance) =>
                      stair?.direction === "down" || !stair?.direction
                  );
                }
                if (currEntrances?.length > 0) {
                  return {
                    floor: floor.level,
                    isExit: !!hasExit,
                    noPath: true,
                    paths: [
                      ...getCurrentFloorPaths(
                        currRoute,
                        startPoints,
                        currEntrances
                      ),
                      ...getCurrentFloorPaths(
                        currRoute,
                        destPoints,
                        currEntrances
                      ),
                    ],
                  };
                }
              }
              return {
                floor: floor.level,
                isExit: !!hasExit,
                paths: getCurrentFloorPaths(currRoute, startPoints, destPoints),
              };
            }
          } else if (startFloor?.level < destinationFloor?.level) {
            let startPoints = currStairs?.filter(
              (stair: IEntrance) =>
                stair?.direction === "down" || !stair?.direction
            );
            let destPoints = currStairs?.filter(
              (stair: IEntrance) =>
                stair?.direction === "up" || !stair?.direction
            );
            if (startFloor?.level === floor?.level) {
              startPoints =
                allStartEntrances?.length > 0 ? allStartEntrances : [start];
            } else if (destinationFloor?.level === floor?.level) {
              startPoints = currStairs?.filter(
                (stair: IEntrance) =>
                  stair?.direction === "down" || !stair?.direction
              );
              destPoints = allDestinationEntrances;
            }
            if (startPoints?.length > 0 && destPoints?.length > 0) {
              return {
                floor: floor.level,
                isExit: !!hasExit,
                paths: getCurrentFloorPaths(currRoute, startPoints, destPoints),
              };
            }
          } else if (startFloor?.level > destinationFloor?.level) {
            let startPoints = currStairs?.filter(
              (stair: IEntrance) =>
                stair?.direction === "up" || !stair?.direction
            );
            let destPoints = currStairs?.filter(
              (stair: IEntrance) =>
                stair?.direction === "down" || !stair?.direction
            );
            if (startFloor?.level === floor?.level) {
              startPoints =
                allStartEntrances?.length > 0 ? allStartEntrances : [start];

              destPoints = currStairs?.filter(
                (stair: IEntrance) =>
                  stair?.direction === "down" || !stair?.direction
              );
            } else if (destinationFloor?.level === floor?.level) {
              startPoints = currStairs?.filter(
                (stair: IEntrance) =>
                  stair?.direction === "up" || !stair?.direction
              );

              destPoints = allDestinationEntrances;
            }
            if (startPoints?.length > 0 && destPoints?.length > 0) {
              return {
                floor: floor.level,
                isExit: !!hasExit,
                paths: getCurrentFloorPaths(currRoute, startPoints, destPoints),
              };
            }
          }
        } else {
          if (destinationFloor?.level === floor?.level) {
            if (!!hasExit) {
              const startPoints = !!prevExit
                ? [
                    ...currExits,
                    ...currStairs?.filter(
                      (stair: IEntrance) =>
                        stair?.direction === "down" || !stair?.direction
                    ),
                  ]
                : currExits;

              const destPoints = allDestinationEntrances;

              let currStart = startPoints;

              if (
                !getCurrentFloorPaths(currRoute, startPoints, destPoints) ||
                getCurrentFloorPaths(currRoute, startPoints, destPoints)
                  ?.length < 1
              ) {
                currStart = [
                  ...currExits,
                  ...currStairs?.filter(
                    (stair: IEntrance) =>
                      stair?.direction === "down" || !stair?.direction
                  ),
                ];
              }

              if (currStart?.length > 0 && destPoints?.length > 0) {
                return {
                  floor: floor.level,
                  isExit: !!hasExit,
                  paths: getCurrentFloorPaths(currRoute, currStart, destPoints),
                };
              }
            } else {
              const startPoints = currStairs?.filter(
                (stair: IEntrance) =>
                  stair?.direction === (exitUpstairs ? "up" : "down") ||
                  !stair?.direction
              );
              const destPoints = allDestinationEntrances;
              if (startPoints?.length > 0 && destPoints?.length > 0) {
                return {
                  floor: floor.level,
                  isExit: !!hasExit,
                  paths: getCurrentFloorPaths(
                    currRoute,
                    startPoints,
                    destPoints
                  ),
                  isBasement: !exitDownstairs && !!exitUpstairs,
                };
              }
            }
          } else if (destinationFloor?.level > floor?.level) {
            if (!!hasExit) {
              const startPoints = !!prevExit
                ? [
                    ...currExits,
                    ...currStairs?.filter(
                      (stair: IEntrance) =>
                        stair?.direction === "down" || !stair?.direction
                    ),
                  ]
                : currExits;

              const destPoints = currStairs?.filter(
                (stair: IEntrance) =>
                  stair?.direction === "up" || !stair?.direction
              );
              let currStart = startPoints;

              if (
                !getCurrentFloorPaths(currRoute, startPoints, destPoints) ||
                getCurrentFloorPaths(currRoute, startPoints, destPoints)
                  ?.length < 1
              ) {
                currStart = [
                  ...currExits,
                  ...currStairs?.filter(
                    (stair: IEntrance) =>
                      stair?.direction === "down" || !stair?.direction
                  ),
                ];
              }
              if (currStart?.length > 0 && destPoints?.length > 0) {
                return {
                  floor: floor.level,
                  isExit: !!hasExit,
                  paths: getCurrentFloorPaths(currRoute, currStart, destPoints),
                };
              }
            } else if (exitDownstairs) {
              const startPoints = currStairs?.filter(
                (stair: IEntrance) =>
                  stair?.direction === "down" || !stair?.direction
              );
              const destPoints = currStairs?.filter(
                (stair: IEntrance) =>
                  stair?.direction === "up" || !stair?.direction
              );
              return {
                floor: floor.level,
                isExit: !!hasExit,
                paths: getCurrentFloorPaths(currRoute, startPoints, destPoints),
              };
            }
          } else if (destinationFloor?.level < floor?.level && !!hasExit) {
            const startPoints = currExits;
            const destPoints = currStairs?.filter(
              (stair: IEntrance) =>
                stair?.direction === "down" || !stair?.direction
            );
            if (startPoints?.length > 0 && destPoints?.length > 0) {
              return {
                floor: floor.level,
                isExit: !!hasExit,
                paths: getCurrentFloorPaths(currRoute, startPoints, destPoints),
              };
            }
          }
        }
      })
      .filter((e: any) => !!e)
      .sort((a: any, b: any) => b.floor - a.floor);
  }, [
    buildingFloors,
    destinationFloor,
    excludedRoutes,
    entrances,
    allDestinationEntrances,
    getCurrentFloorPaths,
    getFloor,
    allStartEntrances,
    sameBuilding,
    start,
    startFloor,
    destination,
  ]);

  const filteredPathsByFloors = useMemo(() => {
    if (pathsByFloors?.length > 0) {
      const allFloors = pathsByFloors?.map((floorPaths: any) => {
        const allPaths = floorPaths?.paths?.map((path: any) => {
          return {
            start: getStartPointFromPath(path.path),
            dest: getDestPointFromPath(path.path),
            floor: floorPaths.floor,
            isExit: floorPaths.isExit,
            noPath: floorPaths?.noPath || false,
            pathDistance: path.pathDistance,
            path: path.path,
            isBasement: floorPaths?.isBasement || false,
          };
        });
        return allPaths;
      });

      let correctPaths: any = {};

      for (let currFloor in allFloors) {
        let currFloorData = allFloors[currFloor];
        const lowerFloorData = allFloors[Number(currFloor) + 1];
        for (let currPath in currFloorData) {
          const currPathData = currFloorData[currPath];
          const lowerFloorClose = lowerFloorData?.filter((e: any) => {
            return (
              getPointsDistance(currPathData.start, e.dest, map) < 100 ||
              getPointsDistance(currPathData.dest, e.start, map) < 100
            );
          });

          if (
            (lowerFloorData && lowerFloorClose?.length > 0) ||
            !lowerFloorData
          ) {
            if (correctPaths[currFloor]?.length > 0) {
              correctPaths[currFloor] = [
                ...correctPaths[currFloor],
                currFloorData[currPath],
              ];
            } else {
              correctPaths[currFloor] = [currFloorData[currPath]];
            }
          }
        }
      }
      return Object.values(correctPaths);
    }
  }, [pathsByFloors, map]);

  const excludedPaths = useMemo(() => {
    let currArr: any = [];
    for (let filteredPath in filteredPathsByFloors) {
      const index = Number(filteredPath);
      const data: any = filteredPathsByFloors[index];

      if (data[0]?.noPath) {
        currArr.push(...data);
      } else {
        if (currArr?.length > 0 && currArr[index - 1]?.start) {
          let sorted = data?.sort(
            (a: any, b: any) => a.pathDistance - b.pathDistance
          );
          let path = sorted?.filter((e: any) => {
            return (
              getPointsDistance(currArr[index - 1].start, e.dest, map) < 100 ||
              getPointsDistance(currArr[index - 1].dest, e.start, map) < 100
            );
          });
          currArr.push(path[0]);
        } else {
          let path = data?.sort(
            (a: any, b: any) => a.pathDistance - b.pathDistance
          )[0];
          currArr.push(path);
        }
      }
    }
    return currArr;
  }, [filteredPathsByFloors, map]);

  const currentExludedPath = useMemo(() => {
    if (excludedPaths?.length > 0) {
      const path = excludedPaths?.filter(
        (e: any) => e?.floor === currentFloor?.level
      );
      if (
        path?.find((e: any) => e.noPath) &&
        (!entrancesPoints?.start || !entrancesPoints?.destination)
      ) {
        const exits = excludedPaths?.filter((e: any) => e.isExit);

        if(exits?.length >0 ){
          setEntrancesPoints({
            start: exits[exits.length - 1]?.dest,
            destination: exits[0]?.dest,
          });
        }
      } else {
        const exit = excludedPaths
          ?.filter((e: any) => e?.isExit)
          .sort((a: any, b: any) => a.floor - b.floor)[0]?.start;
        if (type === "start" && !entrancesPoints?.start && exit?.lat) {
          setEntrancesPoints({ ...entrancesPoints, start: exit });
        } else if (
          type === "destination" &&
          !entrancesPoints?.destination &&
          exit?.lat
        ) {
          setEntrancesPoints({ ...entrancesPoints, destination: exit });
        }
      }
      return path;
    }
    return null;
  }, [excludedPaths, currentFloor, setEntrancesPoints, entrancesPoints, type]);

  useEffect(() => {
    if (!currentExludedPath) {
      onShowPopup(true);
    }
  }, [currentExludedPath]);

  useEffect(() => {
    setEntrancesPoints({ start: null, destination: null });
  }, [handicapped]);

  return (
    <>
      {
        <>
          {currentExludedPath &&
            currentExludedPath?.length > 0 &&
            currentExludedPath.map((e: any) => {
              return (
                <GeoJSON
                  key={Math.random()}
                  data={e.path}
                  style={{
                    color: color,
                    opacity: 1,
                    weight: 6,
                  }}
                />
              );
            })}
        </>
      }
    </>
  );
};

export default Routes;
