export const selectCampus = (id: number | string) => {
  return {
    type: "SELECT_CAMPUS",
    id,
  };
};

export const addFavouritePoint = (data: any) => {
  return {
    type: "ADD_FAVOURITE_POINT",
    data,
  };
};

export const removeFavouritePoint = (id: string) => {
  return {
    type: "REMOVE_FAVOURITE_POINT",
    id,
  };
};
