export const setMapPosition = (data?: object) => {
  return {
    type: "SET_MAP_POSITION",
    data,
  };
};

export const setMapBounds = (data: Array<any>) => {
  return {
    type: "SET_MAP_BOUNDS",
    data,
  };
};
