import React, { FunctionComponent } from "react";
import Icon from "../Icons";
interface ICheckBox {
  isChecked?: boolean;
  label?: string;
  onCheck: () => void;
  id?: string;
}

const CheckBox: FunctionComponent<ICheckBox> = ({
  isChecked,
  label,
  onCheck,
  id,
}) => {
  return (
    <div className="form-checkbox">
      <input
        id={id}
        onChange={() => onCheck && onCheck()}
        checked={isChecked}
        type="checkbox"
      />
      <label htmlFor={id}>
        <div className={isChecked ? "active" : ""}>
          {isChecked && <Icon name="checked" />}

          {label}
        </div>
      </label>
    </div>
  );
};

export default CheckBox;
