import React, { FunctionComponent } from "react";
import Search from "../../components/Search";
import Box from "../../components/Box";

const Points: FunctionComponent = () => {
  return (
    <section className="container element element--full element--full-long">
      <Box loading={false} title="Szukaj obiektu" link="/point">
        <Search />
      </Box>
    </section>
  );
};

export default Points;
