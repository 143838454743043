import { createContext, SetStateAction, Dispatch } from "react";
export interface IPreviousUrlContext {
  previousUrl: string;
  setPreviousUrl: Dispatch<SetStateAction<string>>;
}

export const PreviousUrlContext = createContext<IPreviousUrlContext>({
  previousUrl: "",
  setPreviousUrl: (url) => "",
});
