export const fetchCampuses = () => {
  return {
    type: "FETCH_CAMPUSES",
  };
};

export const getCampus = (id: string | number | null) => {
  return {
    type: "GET_CAMPUSES",
    id,
  };
};
