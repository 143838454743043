import React, { FunctionComponent, useCallback, useState, useRef } from "react";
import QrReader from "react-qr-reader";
import Button from "../../components/FormElements/Button";
import { useHistory } from "react-router-dom";
import Icon from "../../components/FormElements/Icons";
import Box from "../../components/Box";

const QrWrapper: FunctionComponent = () => {
  const qrRef: any = useRef(null);
  const history = useHistory();

  const [qrData, setQrData] = useState("");
  const [canScan, setCanScan] = useState(true);

  const handleScan = useCallback(
    (data) => {
      if (data) {
        history.push(data);
      } else if (!data && !canScan) {
        setQrData("Błędny kod QR");
      }
    },
    [history, canScan]
  );

  const handleScanError = useCallback(
    (err) => {
      if (err) {
        canScan
          ? setCanScan(false)
          : setQrData("Coś poszło nie tak spróbuj ponownie.");
      }
    },
    [canScan]
  );

  const onOpenImageDialog = () => {
    qrRef.current.openImageDialog();
  };

  return (
    <section className="container element element--full">
      <div className="qr-wrapper">
        <Box title="Skanuj kod QR">
          <>
            {qrData && (
              <span
                className={
                  "qr-wrapper__value text-center uppercase font-600 error"
                }
              >
                {qrData}
              </span>
            )}

            <div className="qr-wrapper__scanner">
              <Icon name="cam_frame" />
              <QrReader
                ref={qrRef}
                delay={300}
                onError={handleScanError}
                onScan={handleScan}
                facingMode={"environment"}
                legacyMode={!canScan}
              />
            </div>

            {!canScan && (
              <Button onClick={() => onOpenImageDialog()}>Dodaj kod QR</Button>
            )}
          </>
        </Box>
      </div>
    </section>
  );
};

export default QrWrapper;
