import { MapInterface } from "../reducers";

export const INIT_STATE = {
  position: { center: [50.294260724348966, 18.665036559104923], zoom: 13 },
  userPosition: [52.237049, 21.017532],
  maxBounds: [
    [50.38864122745701, 18.582837581634525],
    [50.2222660917139, 19.071911573410038],
  ],
  loading: false,
};

export default function reducer(
  state: MapInterface = INIT_STATE,
  action: { data: any; type: string }
): MapInterface {
  if (state === undefined) {
    return INIT_STATE;
  }
  switch (action.type) {
    case "SET_MAP_POSITION":
      return {
        ...state,
        position: action.data,
      };
    case "SET_MAP_BOUNDS":
      return {
        ...state,
        maxBounds: action.data,
      };
    default:
      return state;
  }
}
