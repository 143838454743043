import React, { FunctionComponent } from "react";
import Button from "./../../components/FormElements/Button";
import Icon from "./../../components/FormElements/Icons";
interface IButton {
  onHidePopup: () => void;
  desc: String;
  title?: String;
  classList?: String;
  onSubmit?: () => void;
}

const Popup: FunctionComponent<IButton> = ({
  onHidePopup,
  title,
  desc,
  classList,
  onSubmit,
}) => {
  return (
    <div className={`popup row ${classList}`}>
      {title && <strong>{title} </strong>}
      {desc}
      <Button className="button-transparent" onClick={() => onHidePopup()}>
        <Icon name="close_background" />
      </Button>
      {onSubmit && (
        <button className="button" onClick={() => onSubmit()}>
          Aktualizuj
        </button>
      )}
    </div>
  );
};

export default Popup;
