import React, { FunctionComponent, useState } from "react";
import { URL } from "../../../services/api";
import { Link, useHistory } from "react-router-dom";
import Icon from "./../Icons";
import { useLocation } from "react-router-dom";
import Button from "./../Button";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { removeFavouritePoint } from "../../../redux/user/actions";
import { IOccupant } from "../../../interfaces";

interface IListItem {
  emblem?: string;
  id?: number;
  image?: string;
  name?: string;
  building_name?: string;
  msg?: string;
  city?: string;
  description?: string;
  distance?: string;
  removable?: boolean;
  date_from?: string;
  date_to?: string;
  num?: string;
  type?: string;
  link?: string;
  floor_id?: number;
  occupants?: Array<IOccupant>;
}

const ListItem: FunctionComponent<{ data: IListItem }> = ({ data }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch: Dispatch = useDispatch();

  const [showOptions, setShowOptions] = useState(false);

  const onToggleOptions = (el: any) => {
    el.preventDefault();
    setShowOptions(!showOptions);
  };

  const onRemovePoint = (el: any, id: string | undefined) => {
    if (id) {
      el.preventDefault();
      dispatch(removeFavouritePoint(id));
      setShowOptions(false);
    }
  };

  const onShowTips = (el: any) => {
    el.preventDefault();
    history.push(`/paths/${data?.type || "point"}-${data?.id}`);
  };

  const formatDate = (from: string | undefined, to: string | undefined) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const dateFrom =
      from && new Date(from).toLocaleDateString("pl-Pl", options);
    const dateTo = to && new Date(to).toLocaleDateString("pl-Pl", options);

    return `${dateFrom} - ${dateTo}`;
  };

  return (
    <li className="list-item col-24">
      <Link
        aria-label="Wyświetl informacje o punkcie"
        to={`/${data?.type ? data?.type : data.link}/${data.id}?flooor=${
          data?.floor_id
        }`}
      >
        <div className="row">
          <div
            className={
              data?.image
                ? `col-14 col-md-16`
                : data.removable
                ? "col-18"
                : "col-24"
            }
          >
            <div className="list-item__content row">
              <div className={pathname === "/points" ? "col-17" : "col-24"}>
                {data?.building_name && (
                  <span className="list-item__name">{data.building_name}</span>
                )}
                <span className="list-item__name">{data.name}</span>
                <span
                  className="list-item__description"
                  dangerouslySetInnerHTML={{
                    __html:
                      data.link === "event"
                        ? formatDate(data?.date_from, data?.date_to)
                        : data?.city || data?.description || "",
                  }}
                ></span>
                {data?.occupants && data?.occupants?.length > 0 && (
                  <span className="list-item__description">
                    {data?.occupants
                      ?.map((occupant: IOccupant) => occupant.name)
                      .join(", ")}
                  </span>
                )}
              </div>
              {
                <div className={pathname === "/points" ? "col-7" : "col-24"}>
                  <div
                    className={[
                      "list-item__distance-wrapper",
                      (data.removable || data.link === "campus") &&
                        "list-item__distance-wrapper--inline",
                    ].join(" ")}
                  >
                    {data?.distance && (
                      <div className="list-item__distance">
                        <Icon name="pin" />
                        <span>{data.distance}</span>
                      </div>
                    )}
                    {data.link === "point" && (
                      <Button
                        onClick={(el) => onShowTips(el)}
                        className="button-link"
                      >
                        Nawiguj
                      </Button>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
          <div className={data.removable ? "col-6" : `col-10 col-md-8`}>
            {data?.image && (
              <img src={`${URL}doc/${data.image}`} alt={`${data.name}`} />
            )}

            {data.removable && (
              <div className="list-item__options-wrapper">
                {data.emblem && (
                  <img src={`${URL}doc/${data.emblem}`} alt={`${data.name}`} />
                )}
                <Button
                  onClick={(el) => onToggleOptions(el)}
                  className="button-transparent"
                >
                  <Icon name="options" />
                </Button>

                {showOptions && (
                  <div className="list-item__option-button">
                    <Button
                      className="button-red"
                      onClick={(el) => onRemovePoint(el, data.num)}
                    >
                      Usuń z listy
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default ListItem;
