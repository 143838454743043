import React from "react";
import { Switch, Route } from "react-router-dom";
import Building from "./../containers/Building";
import Campus from "./../containers/Campus";
import Campuses from "../containers/Campuses";
import Event from "./../containers/Event";
import Events from "../containers/Events";
import Login from "./../containers/Login";
import Paths from "./../containers/Paths";
import Point from "./../containers/Point";
import Points from "./../containers/Points";
import QrScanner from "./../containers/QrScanner";
import FavPoints from "./../containers/FavouritePoints";
import Room from "./../containers/Room";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" />
      <Route path="/building/:buildingId/:floorId?/:roomId?">
        <Building />
      </Route>
      <Route path="/campuses">
        <Campuses />
      </Route>
      <Route path="/campus/:id">
        <Campus />
      </Route>
      <Route path="/event/:id">
        <Event />
      </Route>
      <Route path="/events">
        <Events />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/paths/:destinationPoint/:startingPoint?">
        <Paths />
      </Route>
      <Route path="/point/:id">
        <Point />
      </Route>
      <Route path="/point">
        <Points />
      </Route>
      <Route path="/qrscanner">
        <QrScanner />
      </Route>
      <Route path="/favourite">
        <FavPoints />
      </Route>
      <Route path="/room/:id">
        <Room />
      </Route>
    </Switch>
  );
};

export default Routes;
