import L, { LatLng } from "leaflet";
import { IPoint, IRoute, IEntrance } from "../interfaces";
import { polygon, point, inside } from "@turf/turf";

const geoutil = require("leaflet-geometryutil");

export const getBounds = (coords: any) => {
  if (coords?.length > 0) {
    const polygon: any = L.polygon(coords, {});
    if (polygon?.getBounds()) {
      return polygon.getBounds();
    }
  }
};

export const getCenter = (coords: any) => {
  if (coords?.length > 0) {
    const polygon: any = L.polygon(coords, {});
    if (polygon?.getBounds()) {
      return polygon.getBounds().getCenter();
    }
  }
};

export const checkIfContainsPoint = (coords: any, pos: any) => {
  if (coords?.length > 0 && pos?.length > 0) {
    if (coords[0]?.length > 3) {
      const tPolygon = polygon(coords);
      const tPoint = point(pos);
      return inside(tPoint, tPolygon);
    } else {
      const polygon: any = L.polygon(coords, {});

      if (polygon?.getBounds()) {
        return polygon.getBounds().contains(pos);
      }
      return false;
    }
  }
};

export const getDistance = (coords: any, userLocation: any, msg: string) => {
  const isResolved = userLocation?.status === "resolved";

  if (getCenter(coords) && isResolved) {
    const from: any = getCenter(coords);
    const to: any = {
      lat: userLocation.position[1],
      lng: userLocation.position[0],
    };
    let currentDistance = from.distanceTo(to).toFixed(0);
    let unit = currentDistance > 1000 ? " km" : " m";

    const distance = `${
      currentDistance > 1000
        ? (currentDistance / 1000).toFixed(1)
        : currentDistance
    } ${unit}`;

    const inCampus = checkIfContainsPoint(coords, to);

    return inCampus ? msg : distance;
  } else {
    return "Brak danych";
  }
};

export const findClosest = (
  map: any,
  coords: Array<Array<number>>,
  point: any
) => {
  let closest = null;
  if (coords?.length > 0 && point) {
    closest = geoutil.closest(
      map,
      coords.map((e: any) => {
        return e?.lat ? [e.lat, e.lng] : e;
      }),
      [point?.lat, point?.lng],
      true
    );
  }
  return closest;
};

export const findClosestSnap = (
  map: any,
  coords: Array<Array<number>>,
  point: LatLng
) => {
  const closest = geoutil.closest(
    map,
    coords.map((e: any) => {
      return e?.lat ? [e.lat, e.lng] : e;
    }),
    [point.lat, point.lng],
    "infinity",
    false
  );
  return closest;
};

export const findClosestLayer = (
  map: any,
  layers: Array<Array<number>>,
  point: LatLng
) => {
  let closest = null;
  if (layers?.length > 0) {
    closest = geoutil.closestLayer(map, layers, [point?.lat, point?.lng]);
  }
  return closest;
};

export const convertXY = (x: number, y: number) => {
  if (x && y) {
    return {
      coordinates: [[[[x, y]]]],
    };
  }
};

export const findClosestMarker = (
  map: any,
  points: Array<IPoint>,
  currentPoint: any
) => {
  const closest = findClosestLayer(
    map,
    points.map((point: any) => point?.coordinates?.coordinates.flat(2)),
    currentPoint
  );
  const closestMarker = points.find(
    (point: any) =>
      point?.coordinates?.coordinates?.flat(2)[0][0] === closest?.layer[0][0] &&
      point?.coordinates?.coordinates?.flat(2)[0][1] === closest?.layer[0][1]
  );

  if (
    closestMarker &&
    closestMarker?.id &&
    closestMarker?.type !== "room" &&
    closestMarker?.type !== "point"
  ) {
    return closestMarker;
  }
};

export const excludePaths = (route: IRoute, excludedAreas: any) => {
  let polygons = excludedAreas.map((area: any) =>
    area?.length > 3 ? polygon([[...area, area[0]]]) : null
  );

  let coords = route.coordinates.coordinates;

  polygons.forEach((polygon: any) => {
    if (polygon) {
      coords = coords
        .map((route: Array<Array<number>>) => {
          return route.filter(
            (el: Array<number>) => !inside(point(el), polygon)
          );
        })
        .filter((el: any) => el.length > 0);
    }
  });

  return {
    ...route,
    isExcluded: true,
    coordinates: { ...route.coordinates, coordinates: coords },
  };
};

export const stringToLatLng = (points: string) => {
  return points?.split(",")?.map((point: string) => {
    return [Number(point.split("-")[1]), Number(point.split("-")[0])];
  });
};

export const findPath = (route: any, start: any, desitnation: any) => {
  const PathFinder = require("./../components/MapElements/geojson-path-finder/index");
  const point = require("@turf/helpers").point;

  if (route) {
    const geojson = {
      type: "FeatureCollection",
      features: route.map((coordinates: LatLng) => {
        return {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: coordinates,
          },
        };
      }),
    };
    const pathfinder = new PathFinder(geojson, { precision: 1e-20 });
    const begining = start?.lat && point([start.lat, start.lng]);
    const finish =
      desitnation?.lat && point([desitnation.lat, desitnation.lng]);
    const path =
      begining && finish && pathfinder && pathfinder.findPath(begining, finish);
    let coords: any;
    if (path) {
      coords = {
        type: "MultiLineString",
        coordinates: [
          [
            begining.geometry.coordinates,
            ...path?.path.map((coord: any) => coord),
            finish.geometry.coordinates,
          ],
        ],
      };
    }
    return coords;
  }
};

export const convertEntrances = (
  route: any,
  entrances: Array<IEntrance>,
  map: any
) => {
  if (map && route && entrances?.length > 0) {
    const converted: any = entrances?.map((entrance: IEntrance) => {
      return {
        ...entrance,
        closest: findClosestLayer(
          map,
          route.coordinates.coordinates,
          getCenter(entrance.coordinates.coordinates.flat(2))
        ),
      };
    });
    return converted;
  }
};

export const getStartPointFromPath = (path: any) => {
  return {
    lat: path?.coordinates[0][0][0],
    lng: path?.coordinates[0][0][1],
  };
};

export const getDestPointFromPath = (path: any) => {
  return {
    lat: path?.coordinates[0][path?.coordinates[0]?.length - 1][0],
    lng: path?.coordinates[0][path?.coordinates[0]?.length - 1][1],
  };
};

const cache: any = {};
export const getPointsDistance = (start: LatLng, dest: LatLng, map: any) => {
  const cacheKey = start.lat + start.lng + dest.lat + dest.lng;

  if (!cache[cacheKey]) {
    cache[cacheKey] = geoutil.distance(map, start, dest);
  }

  return cache[cacheKey];

  // return geoutil.distance(map, start, dest);
};
