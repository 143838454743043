import L from "leaflet";
import "leaflet-routing-machine";
import { IPoint } from "../../interfaces";
let route: any = null;
let control: any = null;
export const removePaths = (map: L.Map | null) => {
  if (map && route) {
    route.setWaypoints([]);
    route = null;
  }
};

export const renderPath = (
  map: L.Map | undefined | null,
  destination: IPoint,
  start?: any,
  history?: any,
  routeColor?: string,
  hideMarker: boolean = false,
  isHandicapped?: boolean,
  destinationPos?: { lat: number; lng: number } | null | undefined,
  startPos?: { lat: number; lng: number } | null | undefined
) => {
  const lineOptions = { color: routeColor, opacity: 1, weight: 6 };
  const LF: any = L;

  const currentDest = destinationPos?.lat ? destinationPos : destination;
  const currentStart = startPos?.lat ? startPos : start;

  if (!route) {
    route = LF.Routing.control({
      router: LF.Routing.osrmv1({
        serviceUrl: isHandicapped
          ? "https://osrmd.etd24.pl/route/v1"
          : "https://osrm.etd24.pl/route/v1",
        profile: "bicycle",
        language: "pl",
      }),
      lineOptions: {
        styles: [lineOptions],
        addWaypoints: false,
      },
      pointMarkerStyle: {
        fillColor: "black",
        color: routeColor,
        radius: 5,
      },
      createMarker: function(i: number, waypoint: any, n: number) {
        if (i === 0 && !hideMarker) {
          return L.marker(waypoint.latLng, {
            draggable: true,
            icon: L.divIcon({
              iconSize: [40, 40],
              iconAnchor: [0, 20],
              popupAnchor: [2, -40],
              html: `<span  class="marker__icon marker__icon--path "  ><svg fill="${routeColor}"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C153.755 0 70.573 83.182 70.573 185.426c0 126.888 165.939 313.167 173.004 321.035 6.636 7.391 18.222 7.378 24.846 0 7.065-7.868 173.004-194.147 173.004-321.035C441.425 83.182 358.244 0 256 0zm0 278.719c-51.442 0-93.292-41.851-93.292-93.293S204.559 92.134 256 92.134s93.291 41.851 93.291 93.293-41.85 93.292-93.291 93.292z"/></svg></span>`,
            }),
          }).on("dragend", (e: any) => {
            const point = e.target.getLatLng();
            history.push(`/paths/${destination.url}/${point.lng}-${point.lat}`);
          });
        }
      },
      fitSelectedRoutes: false,
      waypoints: [null, null],
      showAlternatives: false,
    });

    control = route.onAdd(map);
  }

  if (document?.getElementById("paths__tips") && control) {
    document.getElementById("paths__tips")?.appendChild(control);
  }

  if (map && destination?.building_id) {
    map.off("click");
    route.setWaypoints([]);
    if (currentStart?.lat && currentDest?.lng) {
      route.setWaypoints([
        { lat: currentStart?.lng, lng: currentStart?.lat },
        { lat: currentDest?.lng, lng: currentDest?.lat },
      ]);
    } else {
      map.on("click", function(e: any) {
        const url = history.location.pathname.split("/");
        if (url.includes("paths")) {
          if (currentStart?.lat && currentDest?.lng) {
            route.setWaypoints([
              { lat: currentStart?.lng, lng: currentStart?.lat },
              { lat: currentDest?.lng, lng: currentDest?.lat },
            ]);
          }
          history.push(
            `/paths/${destination.url}/${e.latlng.lng}-${e.latlng.lat}`
          );
          map.off("click");
        }
      });
    }
  }
};
