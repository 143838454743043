import { Store } from "redux";

export const URL: string =
  (window && (window as any).api_url) || "https://elok.qunabu.com/api/v1/";
//https://backendelok.polsl.pl/api/v1/
//https://elokalizator-backend.etd24.pl/ 

const queryString = require("query-string");

interface API {
  store?: Store;
  url: string;
  headers: Headers;
  uploadHeaders: object;
  token?: string;
  getpath: (url: string) => string;
  call(
    endpoint: string,
    method: string,
    data?: object,
    formData?: FormData
  ): Promise<any>;
}

const API: API = {
  url: URL ? URL : "",

  /** get json API headers */
  get headers() {
    const headers: Headers = new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "https://elok.qunabu.com",
    });

    if (this.store) {
      const state = this.store.getState();
      if (state.User.token) {
        headers.set("Authorization", `Bearer ${state.User.token}`);
      }
    }

    if (this.token) {
      headers.set("Authorization", `Bearer ${this.token}`);
    }

    return headers;
  },

  /** get upload API headers */
  get uploadHeaders() {
    let BearerToken: string | undefined = this.token;
    if (this.store) {
      const state = this.store.getState();
      if (state.User.token) {
        BearerToken = `Bearer ${state.User.token}`;
      }
    }

    return {
      Authorization: BearerToken ? `${BearerToken}` : "Bearer abcdef64",
    };
  },

  /** get full endpoint path */
  getpath(endpoint: string): string {
    return `${URL}${endpoint}`;
  },

  /** call and API and returns a Promise */
  call(
    endpoint: string,
    method: string = "GET",
    data?: object,
    formData?: FormData
  ) {
    let fetchOpt: any = {
      method: method,
      headers: API.headers,
    };

    if (method !== "GET" && data) {
      fetchOpt.body = JSON.stringify(data);
    }

    if (method === "GET" && data) {
      endpoint += "?" + queryString.stringify(data);
    }

    if (formData) {
      fetchOpt.body = formData;
      fetchOpt.headers["Content-Type"] = "multipart/form-data";
      delete fetchOpt.headers["Content-Type"];
    }

    return fetch(URL + endpoint, fetchOpt);
  },
};

export default API;
