import { createContext, SetStateAction, Dispatch } from "react";
import { IEvent } from "../interfaces";

export interface IEventContext {
  selectedEvent: IEvent | null;
  setSelectedEvent: Dispatch<SetStateAction<IEvent | null>>;
}

export const EventContext = createContext<IEventContext>({
  selectedEvent: null,
  setSelectedEvent: (event) => {},
});
