import React, { FunctionComponent, useState } from "react";
import Icon from "../Icons";
import { useHistory } from "react-router-dom";

interface IInput {
  className?: string;
  type?: string;
  value?: string | number;
  changeValue?(value: React.SetStateAction<string>): void;
  icon?: "search" | "show" | "hide" | "close_background";
  disabled?: boolean;
  placeholder?: string;
  isFocued?(value: React.SetStateAction<boolean>): void;
  autofocus?: boolean;
  url?: string;
}

const Input: FunctionComponent<IInput> = ({
  className,
  type,
  value,
  icon,
  disabled,
  changeValue,
  placeholder,
  isFocued,
  autofocus = false,
  url,
}) => {
  const [isText, setText] = useState(true);
  const history = useHistory();

  const handleFocus = (e: any) => {
    isFocued && isFocued(true);
    e.target.select();
  };

  const onReset = () => {
    url && history.push(url);
    changeValue && changeValue("");
    isFocued && isFocued(false);
  };

  return (
    <div
      className={[
        "input",
        className ? className : "",
        value && "has-value",
      ].join(" ")}
    >
      {type === "password" && (
        <button
          aria-label="Left Align"
          className="icon-btn"
          onClick={() => setText(!isText)}
        >
          <Icon name={isText ? "show" : "hide"} />
        </button>
      )}

      {icon && (
        <button
          aria-label="Left Align"
          className="icon-btn"
          onClick={() => onReset()}
        >
          <Icon name={icon} />
        </button>
      )}
      <input
        aria-label={placeholder}
        type={type !== "password" ? type : isText ? type : "text"}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={(e) => changeValue && changeValue(e.target.value)}
        autoComplete="off"
        onFocus={(e) => handleFocus(e)}
        autoFocus={autofocus ? true : false}
      />
    </div>
  );
};

export default Input;
