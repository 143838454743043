export const fetchEntrances = () => {
  return {
    type: "FETCH_ENTRANCES",
  };
};

export const getEntrance = (id: string | number | null) => {
  return {
    type: "GET_ENTRANCES",
    id,
  };
};

export const convertEntrances = (map: any, route: any, entrances: any) => {
  return {
    type: "CONVERT_ENTRANCES",
    map,
    route,
    entrances,
  };
};
