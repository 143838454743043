import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import { getRoom } from "../../redux/rooms/actions";
import { getFloor } from "../../redux/floors/actions";
import { getBuilding } from "../../redux/buildings/actions";
import Box from "../../components/Box";
import PointDescription from "./../../components/PointDescription";

const Room: FunctionComponent = () => {
  const dispatch: Dispatch = useDispatch();
  const { id } = useParams();

  const rooms: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Rooms
  );

  const allFloors: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Floors
  );

  const buildings: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Buildings
  );

  const room = useMemo(() => rooms?.byId[Number(id)], [id, rooms]);

  useEffect(() => {
    !room && dispatch(getRoom(Number(id)));
  }, [dispatch, id, room]);

  useEffect(() => {
    if (room?.data?.building_id && room?.data?.floor_id) {
      !buildings?.byId[room.data.building_id] &&
        dispatch(getBuilding(room.data.building_id));
      !allFloors?.byId[room?.data?.floor_id] &&
        dispatch(getFloor(room.data.floor_id));
    }
  }, [dispatch, room, allFloors, buildings]);

  return (
    <section className="container element">
      <div className="point scrollable">
        <Box
          loading={room?.loading || allFloors?.loading}
          title={"Wróć"}
          childElement
        >
          {room?.data && <PointDescription data={room.data} type={"room"} />}
        </Box>
      </div>
    </section>
  );
};

export default Room;
