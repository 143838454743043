import "leaflet-easyprint";
import html2canvas from "html2canvas";
export const printMap = (map: any, download: boolean) => {
  if (map) {
    if (download) {
      let tips: any = document.querySelector(
        ".paths .leaflet-routing-container .leaflet-routing-alt"
      );
      if (tips) {
        tips.style.maxHeight = "100%";
      }
      var svgElements = document.body.querySelectorAll("svg");
      svgElements.forEach(function(item: any) {
        item.setAttribute("width", item.getBoundingClientRect().width);
        item.style.width = null;
      });

      html2canvas(map.getContainer().parentNode.parentNode, {
        useCORS: true,
        allowTaint: false,
        width: window.innerWidth,
        height: window.innerHeight,
      }).then((canvas) => {
        var a = document.createElement("a");
        a.href = canvas.toDataURL("image/png");
        a.download = "myfile.png";
        a.click();
        if (tips) {
          tips.style.maxHeight = "150px";
        }
      });
    } else {
      window.print();
    }
  }
};
