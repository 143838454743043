import { createContext, SetStateAction, Dispatch } from "react";
import { IEntrance } from "../interfaces";

export interface IEntrances {
  start?: { lat: number; lng: number } | null;
  destination?: { lat: number; lng: number } | null;
}

export interface IEntrancesContext {
  entrancesPoints: IEntrances | null;
  setEntrancesPoints: Dispatch<SetStateAction<IEntrances | null>>;
}

export const EntrancesContext = createContext<IEntrancesContext>({
  entrancesPoints: {
    start: null,
    destination: null,
  },
  setEntrancesPoints: (point) => {},
});
