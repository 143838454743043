export const fetchRooms = () => {
  return {
    type: "FETCH_ROOMS",
  };
};

export const getRoom = (id: string | number | null) => {
  return {
    type: "GET_ROOMS",
    id,
  };
};
