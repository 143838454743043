const useUuid = () => {
  if (!localStorage.getItem("uuid")) {
    const newUuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function(c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : r & 0x3 || 0x8;
        return v.toString(16);
      }
    );
    localStorage.setItem("uuid", newUuid);
    return newUuid;
  } else {
    return localStorage.getItem("uuid");
  }
};

export default useUuid;
