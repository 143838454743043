export const fetchRoutes = () => {
  return {
    type: "FETCH_ROUTES",
  };
};

export const getRoute = (id: string | number | null) => {
  return {
    type: "GET_ROUTES",
    id,
  };
};
