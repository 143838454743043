import React, { FunctionComponent, useEffect, useMemo } from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { getBuilding } from "../../redux/buildings/actions";
import { getFloor } from "../../redux/floors/actions";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import { useParams } from "react-router";
import { IFloor } from "../../interfaces";
import Box from "../../components/Box";
import PointDescription from "./../../components/PointDescription";

const Building: FunctionComponent = () => {
  const { buildingId, floorId, roomId } = useParams();
  const dispatch: Dispatch = useDispatch();

  const buildings: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Buildings
  );

  const allFloors: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Floors
  );

  const building = useMemo(
    () => buildingId && buildings?.byId[Number(buildingId)],
    [buildings, buildingId]
  );

  useEffect(() => {
    !building && dispatch(getBuilding(Number(buildingId)));
  }, [buildingId, dispatch, building]);

  const floors = useMemo(
    () =>
      allFloors?.data?.data
        ?.filter((floor: IFloor) => floor?.building_id === building?.data?.id)
        ?.sort((a: IFloor, b: IFloor) => a.level - b.level),
    [allFloors, building]
  );

  const contentLoading = useMemo(() => building?.loading || floors?.loading, [
    building,
    floors,
  ]);

  useEffect(() => {
    if (floorId && buildingId && !allFloors?.byId[floorId]) {
      dispatch(getFloor(floorId));
    }
  }, [floorId, dispatch, buildingId, allFloors]);

  return (
    <section className="container element">
      <div className="point scrollable">
        <Box
          loading={contentLoading}
          title={building?.data?.name}
          link={`/building/${buildingId}`}
        >
          {building?.data && (
            <PointDescription
              data={{
                ...building.data,
                floor_id: Number(floorId),
                room_id: Number(roomId),
                building_id: Number(buildingId),
              }}
              type={"building"}
            />
          )}
        </Box>
      </div>
    </section>
  );
};

export default Building;
