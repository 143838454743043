import React, { FunctionComponent, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import Icon from "../FormElements/Icons";
import {
  PreviousUrlContext,
  IPreviousUrlContext,
} from "../../contexts/PreviousUrl";
interface IBox {
  children?: React.ReactElement[] | React.ReactElement;
  childElement?: boolean;
  title: string;
  loading?: boolean;
  link?: string;
  onClick?: () => void;
}

const Box: FunctionComponent<IBox> = ({
  children,
  title,
  loading,
  childElement,
  link,
  onClick,
}) => {
  const { previousUrl, setPreviousUrl } = useContext<IPreviousUrlContext>(
    PreviousUrlContext
  );

  useEffect(() => {
    if (link) {
      previousUrl !== link && setPreviousUrl(link);
    }
  }, [previousUrl, setPreviousUrl, link]);

  return (
    <div className="box">
      {childElement ? (
        <div className="box__header box__header-back row font-700">
          {onClick ? (
            <button onClick={() => onClick()} className="button button-close">
              <Icon name="hide_menu" />
              <span className="box__title">{title}</span>
            </button>
          ) : (
            <Link
              to={link ? link : previousUrl}
              className="button button-close"
            >
              <Icon name="hide_menu" />
              <span className="box__title">{title}</span>
            </Link>
          )}
        </div>
      ) : (
        <div className="box__header font-700">
          <div>
            <span className="box__title">{title}</span>
            <Link
              aria-label="Zamknij komponent"
              className="button button-close"
              to={"/"}
            />
          </div>
        </div>
      )}

      <div className="box__content">
        {loading ? (
          <div className="loader">
            <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default Box;
