export const fetchFloors = () => {
  return {
    type: "FETCH_FLOORS",
  };
};

export const getFloor = (id: string | number | null) => {
  return {
    type: "GET_FLOORS",
    id,
  };
};
